import { IDossierMedical, IPatient, IRelatedPatients } from "../models/backend";
import {
  IPatientRepository,
  PatientRepository,
} from "../repositories/patientRepository";
import { FixMeLater } from "../tsmigration";

interface IPatientService {
  getPatientAsync(patientId: string): Promise<FixMeLater>;
  savePatientAsync(patient: IPatient): Promise<boolean>;
  deletePatientAsync(patientId: string): Promise<boolean>;
  getAllPatientAsync(): Promise<IPatient[]>;
  getMedicalRecords(patientId: string): Promise<IDossierMedical[]>;
  lockPatientAccountAsync(
    patientId: string,
    lockAccount: boolean
  ): Promise<boolean>;

  addDependantToPatient(
    patientId: string,
    dependant: IPatient
  ): Promise<boolean>;
  removeDependant(parentId: string, dependantId: string): Promise<boolean>;
  getRelatedPatients(parentId: string): Promise<IRelatedPatients[]>;
}

class PatientService implements IPatientService {
  private _patientRepo: IPatientRepository;

  /**
   *Repository that will handle the request for the patients
   */
  constructor() {
    this._patientRepo = new PatientRepository();
  }
  async getRelatedPatients(parentId: string): Promise<IRelatedPatients[]> {
    return await this._patientRepo.getRelatedPatients(parentId);
  }
  async addDependantToPatient(
    patientId: string,
    dependant: IPatient
  ): Promise<boolean> {
    dependant.profileCompleted = true;
    return await this._patientRepo.addDependantToPatient(patientId, dependant);
  }

  async removeDependant(
    parentId: string,
    dependantId: string
  ): Promise<boolean> {
    return await this._patientRepo.removeDependant(parentId, dependantId);
  }

  async getMedicalRecords(patientId: string): Promise<IDossierMedical[]> {
    return await this._patientRepo.getMedicalRecords(patientId);
  }

  async lockPatientAccountAsync(
    patientId: string,
    lockAccount: boolean
  ): Promise<boolean> {
    return await this._patientRepo.lockPatientAccountAsync(
      patientId,
      lockAccount
    );
  }

  async getAllPatientAsync(): Promise<IPatient[]> {
    return await this._patientRepo.getAllPatientAsync();
  }

  async savePatientAsync(patient: IPatient): Promise<boolean> {
    return await this._patientRepo.savePatientAsync(patient);
  }

  async deletePatientAsync(patientId: string): Promise<boolean> {
    return await this._patientRepo.deletePatientAsync(patientId);
  }

  async getPatientAsync(patientId: string) {
    return await this._patientRepo.getPatientAsync(patientId);
  }
}

export { PatientService, IPatientService };
