import { Link } from "react-router-dom";
import { IPatient } from "../models/backend";
import { default_profile } from "../common/imagepath";
import { Button } from "react-bootstrap";

const PatientCard = (props: {
  patient: IPatient;
  showSelectButton?: boolean;
  onSelectPatient?: (patient: IPatient) => void;
}) => {
  return (
    <div className="col-md-6 col-lg-4 col-xl-3" key={props.patient.id}>
      <div className="card widget-profile pat-widget-profile">
        <div className="card-body">
          <div className="pro-widget-content">
            <div className="profile-info-widget">
              <Link
                to="/patient-profile"
                className="booking-doc-img"
                state={props.patient}
              >
                <img
                  src={
                    props.patient.logoPath
                      ? props.patient.logoPath
                      : default_profile
                  }
                  alt="Logo patient"
                />
              </Link>
              <div className="profile-det-info">
                <h3>
                  <Link to="/patient-profile" state={props.patient}>
                    {`${props.patient.firstName} ${props.patient.lastName}`}
                  </Link>
                </h3>

                <div className="patient-details">
                  <h5>
                    <b>ID Patient :</b> {props.patient.id}
                  </h5>
                  <h5 className="mb-0">
                    <i className="fas fa-map-marker-alt"></i>{" "}
                    {props.patient.patientAddress?.addressLine1
                      ? `${props.patient.patientAddress?.addressLine1}, ${props.patient.patientAddress?.city}, ${props.patient.patientAddress?.country}`
                      : "Adresse non fournie"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="patient-info">
            <ul>
              <li>
                Telephone <span>{props.patient.phoneNumber}</span>
              </li>
              {props.showSelectButton && props.onSelectPatient && (
                <li className="text-center">
                  <Button
                    variant="info"
                    onClick={() => props.onSelectPatient(props.patient)}
                  >
                    Sélectionner
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientCard;
