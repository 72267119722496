import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import YearDifference from "../common/shared-components/YearDifference";
import { PatientContext } from "./PatientContext";
import { useAuth } from "react-oidc-context";
import { IPatient, IRelatedPatients } from "../models/backend";
import { default_profile } from "../common/imagepath";
import PatientSelectionListComponent from "../common/shared-components/PatientSelectionListComponent";
import {
  getAllPatientsInTheAccount,
  isObjectEmptyOrUndefined,
} from "../utils/objUtils";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { PatientService } from "../services/patientService";

export const DashboardSidebar = () => {
  const patientService = new PatientService();
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const [currentUser, setCurrentUser] = useState<IPatient>({} as IPatient);
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleDropdown = () => setIsOpen(!isOpen);

  const {
    patient,
    getPatientData,
    setPatientData,
    setSelectedAccount,
    patientsInAccount,
    selectedAccount,
    setPatientsInAccount,
  } = useContext(PatientContext);
  const auth = useAuth();

  const handlePatientProfileChange = async (
    selectedPatient: IRelatedPatients
  ) => {
    localStorage.removeItem("patientData");
    const patientData = await getPatientData(selectedPatient.id);
    setSelectedAccount(selectedPatient);
    localStorage.setItem("selectedAccount", JSON.stringify(selectedPatient));

    setCurrentUser(patientData);
    await setPatientData(patientData);
    navigate("/dashboard-patient");
  };

  useEffect(() => {
    const fetchPatientData = async () => {
      const id = auth.user?.profile["email"] || "";
      const patientData = await getPatientData(id);
      const mainPatientId = localStorage.getItem("mainPatientId") || id;
      const relatedPatientList = await patientService.getRelatedPatients(
        mainPatientId
      );

      // adjust the following
      setCurrentUser(patientData);
      setPatientsInAccount(relatedPatientList);
    };
    fetchPatientData();
  }, [getPatientData]);

  return (
    <div className="profile-sidebar">
      {/* {!isObjectEmcurrentUser]);Undefined(currentUser??) && ( */}
      <>
        <div className="widget-profile pro-widget-content">
          <PatientSelectionListComponent
            patientList={patientsInAccount}
            onChange={handlePatientProfileChange}
            initialValue={currentUser.id}
          ></PatientSelectionListComponent>
          <div className="profile-info-widget">
            <Link to="#0" className="booking-doc-img">
              <img
                src={
                  currentUser?.logoPath
                    ? currentUser?.logoPath
                    : default_profile
                }
                alt="User"
              />
            </Link>
            <div className="profile-det-info">
              <h3>
                {currentUser?.firstName} {currentUser?.lastName}
                {/* {isOpen ? <FaCaretUp /> : <FaCaretDown />} */}
              </h3>
              {/* {isOpen && (
                <PatientSelectionListComponent
                  patientList={patientsInAccount}
                  onChange={handlePatientProfileChange}
                  initialValue={currentUser.id}
                ></PatientSelectionListComponent>
              )} */}

              <div className="patient-details">
                <h5>
                  {currentUser.dayOfBirth && (
                    <>
                      <i className="fas fa-birthday-cake"></i>{" "}
                      {/* {currentUser.dayOfBirth.toLocaleDateString("fr-FR")},{" "} */}
                      <YearDifference
                        initialDate={currentUser?.dayOfBirth}
                      ></YearDifference>
                    </>
                  )}
                </h5>
                <h5 className="mb-0">
                  {!isObjectEmptyOrUndefined(currentUser?.patientAddress) && (
                    <>
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {currentUser?.patientAddress?.addressLine1},{" "}
                      {currentUser?.patientAddress?.city}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-widget">
          <nav className="dashboard-menu">
            <ul>
              <li className={pathname.includes("/dashboard") ? "active" : ""}>
                <Link to="/dashboard-patient">
                  <i className="fas fa-columns"></i>
                  <span>Rendez-vous</span>
                </Link>
              </li>
              <li className={pathname.includes("dependants") ? "active" : ""}>
                <Link to="/patient-dependants">
                  <i className="fas fa-users"></i>
                  <span>Personnes à charge</span>
                </Link>
              </li>
              <li
                className={pathname.includes("dossiermedical") ? "active" : ""}
              >
                <Link to="/patient-dossiermedical">
                  <i className="fas fa-file-medical-alt"></i>
                  <span>Dossier medical</span>
                </Link>
              </li>
              <li className={pathname.includes("/infos") ? "active" : ""}>
                <Link to="/infos-patient">
                  <i className="fas fa-user-cog"></i>
                  <span>Informations du profil</span>
                </Link>
              </li>

              <li className={pathname.includes("changepass") ? "active" : ""}>
                <Link to="/patient-changepass">
                  <i className="fas fa-lock"></i>
                  <span>Changer mot de passe</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    // remove the informations save in the local storage
                    localStorage.removeItem("doctorData");
                    localStorage.removeItem("patientData");

                    // disconnect the user from the server
                    auth.removeUser();
                    localStorage.clear();

                    auth.signoutRedirect();
                  }}
                >
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </>
      {/* )} */}
    </div>
  );
};
export default DashboardSidebar;
