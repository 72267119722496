import React from "react";
// import ReactDOM from "react-dom";
import AppRouter from "./approuter";
// import ReactDOM from "react-dom/client";
import { render } from "react-dom";

import { AuthProvider } from "react-oidc-context";
import { User, WebStorageStateStore } from "oidc-client-ts";

// import "./assets/css/bootstrap.min.css";

// boostrap
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap.min.css";

// boostrap
// import "bootstrap/dist/css/bootstrap.min.css";
//fontawesome
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";
//carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/owl.carousel.min.css";

// import("./common/assets/css/all.css");
import "./assets/css/all.css";
import "./assets/css/all.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/custom.css";

import "./assets/DiiwdeStyling.css";

// if (window.location.pathname.includes("patient")) {
//   require("./patient/assets/css/all.min.css");
//   require("./patient/assets/css/all.css");
//   require("./patient/assets/css/fontawesome.min.css");
//   require("./patient/assets/css/custom.css");
// } else {
//   require("./assets/css/all.css");
//   require("./assets/css/all.min.css");
//   require("./assets/css/fontawesome.min.css");
//   require("./assets/css/custom.css");
// }

// const root = ReactDOM.createRoot(document.getElementById("root"));

const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  // authority: "https://localhost:5001",
  authority: "https://appallodocidentity.azurewebsites.net",
  client_id: "react-client",
  // redirect_uri: "http://localhost:3000",
  redirect_uri: "https://allodocapp.com",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  scope: "openid profile doc_scope verification",
};

render(
  <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
    <AppRouter />
  </AuthProvider>,
  rootElement
);
