import Header from "../header";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PatientContext } from "../../patient/PatientContext";
import { useAuth } from "react-oidc-context";

const Login = () => {
  const history = useNavigate();
  const { getPatientData } = useContext(PatientContext);
  const auth = useAuth();

  const handleGoToPatient = async () => {
    const id = "";
    getPatientData(id);
    history("/dashboard-patient");
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleGoToDoctor = async () => {
    // history.push("/medecin/dashboard");
    history("/dashboard-docteur");
  };
  return (
    <>
      <Header profileCompleted={false} />
      <section className="specialities-section-one">
        <div className="container">
          <div className="row">
            <div className="col-md-6 aos" data-aos="fade-up">
              <div className="section-header-one section-header-slider">
                <h2 className="section-title">Connexion</h2>
              </div>
            </div>
            <div className="col-md-6 aos" data-aos="fade-up">
              <div className="owl-nav slide-nav-1 text-end nav-control" />
            </div>
          </div>
          <div className="item">
            <button
              onClick={handleGoToPatient}
              className="btn btn-light"
              style={{
                display: "block",
                width: "100%",
                padding: "10px 0",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              Patient
            </button>
          </div>
          <br></br>
          <div className="item">
            <button
              onClick={handleGoToDoctor}
              className="btn btn-light"
              style={{
                display: "block",
                width: "100%",
                padding: "10px 0",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              Docteur
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
