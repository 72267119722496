const SortArrow = ({ direction }) => {
  if (!direction) return null;
  return (
    <span>
      {direction === "ascending" ? (
        <i className="fas fa-arrow-up" />
      ) : (
        <i className="fas fa-arrow-down" />
      )}
    </span>
  );
};

export default SortArrow;
