import {
  IDoctor,
  IMedicalRecordModel,
  IPatient,
  ICalendarEvent,
} from "../models/backend";
import {
  IDoctorRepository,
  DoctorRepository,
} from "../repositories/doctorRepository";
import { FixMeLater } from "../tsmigration";

interface IDoctorService {
  getDoctorAsync(doctorId: string): Promise<FixMeLater>;
  getDoctorByNameAsync(name: string): Promise<FixMeLater>;
  saveDoctorAsync(doctor: IDoctor): Promise<boolean>;
  getAllDoctorAsync(): Promise<IDoctor[]>;
  getAllPatientsAsync(doctorId: string): Promise<IPatient[]>;
  lockDoctorAccountAsync(
    doctorId: string,
    lockAccount: boolean
  ): Promise<boolean>;
  saveMedicalRecordFileAsync(file: FormData): Promise<string>;
  saveMedicalRecordAsync(record: IMedicalRecordModel): Promise<boolean>;

  getDoctorBusySpots(doctorId: string): Promise<ICalendarEvent[]>;
}

class DoctorService implements IDoctorService {
  private _doctorRepo: IDoctorRepository;

  /**
   *Repository that will handle the request for the doctors
   */
  constructor() {
    this._doctorRepo = new DoctorRepository();
  }
  async getDoctorBusySpots(doctorId: string): Promise<ICalendarEvent[]> {
    return await this._doctorRepo.getDoctorBusySpots(doctorId);
    // return spots.busySlots;
  }

  async saveMedicalRecordAsync(record: IMedicalRecordModel): Promise<boolean> {
    return await this._doctorRepo.saveMedicalRecordAsync(record);
  }

  async saveMedicalRecordFileAsync(file: FormData): Promise<string> {
    return await this._doctorRepo.saveMedicalRecordFileAsync(file);
  }

  async getAllPatientsAsync(doctorId: string): Promise<IPatient[]> {
    return await this._doctorRepo.getAllPatientsAsync(doctorId);
  }

  async getDoctorByNameAsync(name: string): Promise<FixMeLater> {
    return await this._doctorRepo.getDoctorByNameAsync(name);
  }

  async lockDoctorAccountAsync(
    doctorId: string,
    lockAccount: boolean
  ): Promise<boolean> {
    return await this._doctorRepo.lockDoctorAccountAsync(doctorId, lockAccount);
  }

  async getAllDoctorAsync(): Promise<IDoctor[]> {
    return await this._doctorRepo.getAllDoctorAsync();
  }

  async saveDoctorAsync(doctor: IDoctor): Promise<boolean> {
    delete doctor.appointments;
    delete doctor.patients;
    return await this._doctorRepo.saveDoctorAsync(doctor);
  }

  async getDoctorAsync(doctorId: string) {
    return await this._doctorRepo.getDoctorAsync(doctorId);
  }
}

export { DoctorService, IDoctorService };
