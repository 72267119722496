import AppContainer from "./appcontainer";
import { PatientUserProvider } from "./patient/PatientContext";
import { DoctorUserProvider } from "./doctor/DoctorContext";
import NotificationProvider from "./common/NotificationContext";

const AppRouter = () => {
  return (
    <PatientUserProvider>
      <DoctorUserProvider>
        <NotificationProvider>
          <AppContainer />
        </NotificationProvider>
      </DoctorUserProvider>
    </PatientUserProvider>
  );
};

export default AppRouter;
