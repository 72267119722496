import { Link } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/home/footer";
import StickyBox from "react-sticky-box";
import { DoctorSidebar } from "./DoctorSidebar";
import { IDayAvailability, IDoctor } from "../models/backend";
import { DoctorContext } from "./DoctorContext";
import { useState, useContext, useEffect, ChangeEvent } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { isObjectEmptyOrUndefined } from "../utils/objUtils";
import DoctorScheduleView from "./DoctorScheduleView";
import { useAuth } from "react-oidc-context";
import TimeInput from "../common/shared-components/TimeInput";
import { FixMeLater } from "../tsmigration";
import { DoctorService } from "../services/doctorService";
import { useNotification } from "../common/NotificationContext";
import { NOTIFICATION_TYPE } from "../models/notifications";

const dayMapping = {
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
  0: "Dimanche",
};

const AvailabilityCalendar = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const goToEditMode = () => {
    setIsEditMode(true);
  };
  const leaveEditMode = () => {
    setIsEditMode(false);
  };
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>({} as IDoctor);
  const [availabilities, setAvailabilities] = useState<IDayAvailability[]>(
    currentDoctor.availabilities || []
  );

  const [newAvailability, setNewAvailability] = useState(
    {} as IDayAvailability
  );

  const [showNewAvailabilityForm, setShowNewAvailabilityForm] = useState(false);

  const [error, setError] = useState<string>("");

  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const auth = useAuth();

  const unusedDays = Object.keys(dayMapping).filter(
    (dayNum) => !availabilities.some((avail) => avail.day.toString() === dayNum)
  );
  const { showNotification } = useNotification();

  const doctorService = new DoctorService();

  useEffect(() => {
    const fetchDoctorData = async () => {
      setIsLoading(true); // Begin loading

      const id = auth.user?.profile["email"];
      const doctorData = await getDoctorData(id);
      setCurrentDoctor(doctorData);
      setAvailabilities(doctorData.availabilities || []);
      setIsLoading(false);
    };
    fetchDoctorData();
  }, [getDoctorData, auth.user]);

  const handleTimeChange = (
    day: number,
    key: keyof IDayAvailability,
    value: string
  ) => {
    if (value) {
      const updatedAvailabilities = availabilities.map((availability) =>
        availability.day === day
          ? { ...availability, [key]: value }
          : availability
      );
      setAvailabilities(updatedAvailabilities);
    }
  };
  const newAvailabilityIsValid = (availability: IDayAvailability) => {
    const valid =
      availability.day !== undefined &&
      availability.startTime < availability.endTime &&
      availability.startTime !== "" &&
      availability.endTime !== "";
    return valid;
  };
  const addNewAvailability = () => {
    if (newAvailability && newAvailabilityIsValid(newAvailability)) {
      const newAvailabilityList = [...availabilities, newAvailability];
      newAvailabilityList.sort((a, b) => a.day - b.day);
      setAvailabilities(newAvailabilityList);
      setNewAvailability(null); // Reset or update new availability
    } else {
      setError("Invalid availability data. Please check the inputs.");
    }
  };

  const removeNewAvailability = () => {
    setNewAvailability(null);
  };

  const addNewAvailabilityPlaceholder = () => {
    if (unusedDays.length > 0) {
      // const firstAvailableDay = unusedDays[0];
      const availabilityToAdd: IDayAvailability = {
        // day: dayMapping[firstAvailableDay],
        day: undefined,
        startTime: "08:00",
        endTime: "17:00",
      };
      setNewAvailability(availabilityToAdd);
      setShowNewAvailabilityForm(true);
    }
  };

  const handleNewDayChange = (e) => {
    setNewAvailability({ ...newAvailability, day: e });
  };

  const handleNewAvailabilityChange = (
    key: keyof IDayAvailability,
    // event: React.ChangeEvent<HTMLInputElement>
    value: string | number
  ) => {
    // const value = event.target.value;

    if (newAvailability) {
      setNewAvailability((prev) => ({ ...prev!, [key]: value }));
    }
  };

  const isFormValid = (): boolean => {
    return availabilities.every(
      ({ startTime, endTime }) => startTime < endTime
    );
  };

  const removeAvailability = (index) => {
    const newAvailailabilities = availabilities.filter(
      (availability) => availability.day !== index
    );
    setAvailabilities(newAvailailabilities);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid()) {
      const newDoctorState: IDoctor = {
        ...currentDoctor,
        availabilities: availabilities,
      };
      setCurrentDoctor({ ...newDoctorState });
      try {
        const doctorUpdated = await doctorService.saveDoctorAsync(
          newDoctorState
        );
        if (doctorUpdated) {
          showNotification(
            "Disponibilités du docteur mises a jour avec succes",
            NOTIFICATION_TYPE.INFO
          );
          await setDoctorData(newDoctorState);
        }
      } catch (error) {
        console.error(error);
        showNotification(
          "Erreur survenue. Merci de reessayer",
          NOTIFICATION_TYPE.ERROR
        );
      }
      leaveEditMode();
    } else {
      setError("Merci de corriger les erreurs.");
    }
  };

  return (
    <>
      <Header />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Calendrier et Disponibilités</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Calendrier et Disponibilités
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        "Loading..."
      ) : (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DoctorSidebar />
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Disponibilités</h4>
                        {/* {!isObjectEmptyOrUndefined(availabilities) && ( */}
                        <>
                          <Container className="mt-5">
                            {isEditMode ? (
                              <>
                                <Form onSubmit={handleSubmit}>
                                  <Row className="mb-2 font-weight-bold">
                                    <Col md={2}>Jour</Col>
                                    <Col md={3}>Début</Col>
                                    <Col md={3}>Fin</Col>
                                    <Col md={2}>Retirer</Col>
                                  </Row>
                                  <hr
                                    style={{
                                      backgroundColor: "black",
                                      height: "5px",
                                      border: "none",
                                    }}
                                  />
                                  <div
                                    style={{
                                      borderTop: "2px solid #fff ",
                                      marginLeft: 20,
                                      marginRight: 20,
                                    }}
                                  ></div>

                                  {availabilities.map(
                                    ({ day, startTime, endTime }) => (
                                      <Row className="mb-3" key={day}>
                                        <Col md={2}>
                                          <Form.Label>
                                            {dayMapping[day]}
                                          </Form.Label>
                                        </Col>
                                        <Col md={3}>
                                          <Form.Control
                                            type="time"
                                            value={startTime}
                                            onChange={(e) =>
                                              handleTimeChange(
                                                day,
                                                "startTime",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Début"
                                          />
                                          {/* <TimeInput
                                              value={startTime}
                                              onChange={(e: FixMeLater) =>
                                                handleTimeChange(
                                                  day,
                                                  "startTime",
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Début"
                                            /> */}
                                        </Col>
                                        <Col md={3}>
                                          <Form.Control
                                            type="time"
                                            value={endTime}
                                            onChange={(e) =>
                                              handleTimeChange(
                                                day,
                                                "endTime",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Début"
                                          />
                                          {/* <TimeInput
                                              value={endTime}
                                              onChange={(e) =>
                                                handleTimeChange(
                                                  day,
                                                  "endTime",
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Fin"
                                            /> */}
                                        </Col>
                                        <Col md={2}>
                                          <Button
                                            variant="danger"
                                            onClick={() =>
                                              removeAvailability(day)
                                            }
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                  <hr
                                    style={{
                                      backgroundColor: "black",
                                      height: "5px",
                                      border: "none",
                                    }}
                                  />
                                  {
                                    <Row className="mb-3">
                                      <Col className="text-center">
                                        <Button
                                          // onClick={() =>
                                          //   setShowNewAvailabilityForm(true)
                                          // }
                                          onClick={
                                            addNewAvailabilityPlaceholder
                                          }
                                          variant="info"
                                          disabled={unusedDays.length === 0}
                                        >
                                          Ajouter une disponibilité
                                        </Button>
                                      </Col>
                                    </Row>
                                  }
                                  {!isObjectEmptyOrUndefined(
                                    newAvailability
                                  ) && (
                                    <Row>
                                      <Col md={2}>
                                        <Form.Control
                                          as="select"
                                          value={
                                            newAvailability.day
                                              ? newAvailability.day.toString()
                                              : undefined
                                          }
                                          onChange={(e) =>
                                            handleNewDayChange(
                                              parseInt(e.target.value, 10)
                                            )
                                          }
                                        >
                                          <option value="Sélectionner">
                                            Sélectionner le jour
                                          </option>
                                          {unusedDays.map((day) => (
                                            <option key={day} value={day}>
                                              {dayMapping[day]}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      </Col>
                                      <Col md={3}>
                                        <Form.Control
                                          type="time"
                                          value={newAvailability.startTime}
                                          onChange={(e) =>
                                            handleNewAvailabilityChange(
                                              "startTime",
                                              e.target.value
                                            )
                                          }
                                          placeholder="Début"
                                        />
                                        {/* <TimeInput
                                            value={newAvailability.startTime}
                                            onChange={(e: FixMeLater) =>
                                              handleNewAvailabilityChange(
                                                "startTime",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Début"
                                          /> */}
                                      </Col>
                                      <Col md={3}>
                                        <Form.Control
                                          type="time"
                                          value={newAvailability.endTime}
                                          onChange={(e) =>
                                            handleNewAvailabilityChange(
                                              "endTime",
                                              e.target.value
                                            )
                                          }
                                          placeholder="Début"
                                        />
                                        {/* <TimeInput
                                            value={newAvailability.endTime}
                                            onChange={(e: FixMeLater) =>
                                              handleNewAvailabilityChange(
                                                "endTime",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Fin"
                                          /> */}
                                      </Col>
                                      <Col md={2}>
                                        <div className="text-center">
                                          <Button
                                            onClick={addNewAvailability}
                                            variant="info"
                                          >
                                            <i
                                              className="fa fa-add"
                                              aria-hidden="true"
                                            ></i>
                                          </Button>
                                          <Button
                                            variant="danger"
                                            onClick={() =>
                                              removeNewAvailability()
                                            }
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}

                                  <div className="text-center">
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      disabled={!isFormValid()}
                                    >
                                      Enregistrer
                                    </Button>
                                    <Button
                                      variant="secondary"
                                      onClick={leaveEditMode}
                                    >
                                      Annuler
                                    </Button>
                                  </div>
                                </Form>
                              </>
                            ) : (
                              <>
                                <div className="text-end">
                                  <Button
                                    variant="info"
                                    onClick={() => goToEditMode()}
                                  >
                                    Sélectionner Disponibilités
                                  </Button>
                                </div>
                                <br></br>
                                <br></br>
                                <DoctorScheduleView
                                  currentDoctor={currentDoctor}
                                ></DoctorScheduleView>
                              </>
                            )}
                          </Container>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};
export default AvailabilityCalendar;
