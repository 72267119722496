import { useEffect, useState } from "react";
import { Input } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import SidebarNav from "./sidebar";
import { Link } from "react-router-dom";
import Header from "./header";
import { PatientService } from "../../services/patientService";
import { IPatient, IUserProfile } from "../../models/backend";
import { DateTime } from "luxon";
import { Modal } from "react-bootstrap";
import Profile from "./profile";
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// TODO: saved the changes in the backend
const Patients = () => {
  const [patients, setPatients] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [profile, setProfile] = useState<IUserProfile>();
  const patientService = new PatientService();
  const [searchPatients, setSearchPatients] = useState<IPatient[]>([]);
  const { Search } = Input;

  useEffect(() => {
    patientService.getAllPatientAsync().then((p) => {
      setPatients(p ? p : []);
      setSearchPatients(p ? p : []);
    });
  }, []);

  return (
    <>
      <Header onMenuClick={() => {}} />
      <SidebarNav />
      <Modal
        className="modal"
        show={showEdit}
        style={{ marginTop: "80px" }}
        size="lg"
        onHide={() => {
          setShowEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title">Profil patient</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Profile profile={profile} />
        </Modal.Body>
      </Modal>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Liste des patients</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Liste des patients</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-4">
              <Search
                placeholder="Search patient by name"
                allowClear
                enterButton="Search"
                size="large"
                style={{ marginBottom: "25px" }}
                onSearch={(value) => {
                  if (value) {
                    setSearchPatients(
                      patients.filter(
                        (p: IPatient) =>
                          p.firstName
                            .toLowerCase()
                            .includes(value.toLowerCase()) ||
                          p.lastName.toLowerCase().includes(value.toLowerCase())
                      )
                    );
                  } else {
                    setSearchPatients(patients);
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nom patient</TableCell>
                            <TableCell align="right">Date de Nais.</TableCell>
                            <TableCell align="right">Adresse</TableCell>
                            <TableCell align="right">Telephone</TableCell>
                            <TableCell align="right">Profil complete</TableCell>
                            <TableCell align="right">Compte bloque</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {searchPatients.map((pat) => {
                            return (
                              <TableRow key={pat.id}>
                                <TableCell component="th" scope="row">
                                  <a
                                    onClick={() => {
                                      setProfile({
                                        id: pat.id,
                                        firstName: pat.firstName,
                                        lastName: pat.lastName,
                                        phoneNumber: pat.phoneNumber,
                                        address: pat.patientAddress,
                                        logoPath: pat.logoPath,
                                      });
                                      setShowEdit(true);
                                    }}
                                  >
                                    {`${pat.firstName} ${pat.lastName}`}
                                  </a>
                                </TableCell>
                                <TableCell align="right">
                                  {DateTime.fromJSDate(
                                    new Date(pat.dayOfBirth)
                                  ).toLocaleString(
                                    DateTime.DATETIME_MED_WITH_WEEKDAY
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {pat.patientAddress?.addressLine1
                                    ? `${pat.patientAddress?.addressLine1} ${pat.patientAddress?.city} ${pat.patientAddress?.country}`
                                    : ""}
                                </TableCell>
                                <TableCell align="right">
                                  {pat.phoneNumber}
                                </TableCell>
                                <TableCell align="right">
                                  {pat?.profileCompleted ? "Oui" : "Non"}
                                </TableCell>
                                <TableCell align="right">
                                  <Switch
                                    checked={pat.accountBloqued}
                                    color="primary"
                                    onChange={() => {
                                      patientService
                                        .lockPatientAccountAsync(
                                          pat.id,
                                          !pat.accountBloqued
                                        )
                                        .then(() => {
                                          searchPatients.find(
                                            (p) => p.id == pat.id
                                          ).accountBloqued =
                                            !pat.accountBloqued;

                                          setSearchPatients(searchPatients);
                                        });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Patients;
