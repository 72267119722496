import { IAppointment, IDoctor, IPatient } from "../models/backend";

export function createEmptyAppointment(
  aptDate?: Date,
  patient?: IPatient
): IAppointment {
  return {
    id: "",
    date: aptDate || new Date(),
    patient: patient || ({} as IPatient),
    doctor: {} as IDoctor,
    doctorNote: "",
    patientNote: "",
  };
}
