/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import logo from "../assets/images/allodoc.png";
import logosvg from "../assets/images/allodoc.png";
// import Dropdown from "react-bootstrap/Dropdown";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

import AOS from "aos";
import "./assets/css/aos.css";
import FeatherIcon from "feather-icons-react";
import { logo_white } from "./imagepath";
import { PulseLoader } from "react-spinners";
import { IDoctor, IPatient } from "../models/backend";
import { DoctorContext } from "../doctor/DoctorContext";
import { PatientContext } from "../patient/PatientContext";
import { default_profile } from "./imagepath";

const Header = (props) => {
  const auth = useAuth();
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const config = "/react/template";
  //mobile menu
  const [isSideMenu, setSideMenu] = useState("");
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);
  const [profilCompleted, setProfilCompleted] = useState(false);
  const [logoPath, setLogoPath] = useState("");
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>();

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };

  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);

  useEffect(() => {
    const id = auth.user?.profile["email"];
    if (id) {
      if (auth.user?.profile["role"] == "docteur") {
        getDoctorData(id).then((connectedDoctor) => {
          setProfilCompleted(
            connectedDoctor.profileCompleted || props?.profileCompleted
          );

          setCurrentDoctor(connectedDoctor);
          setLogoPath(connectedDoctor.logoPath);
        });
      } else {
        getPatientData(id).then((connectedPatient) => {
          const mainPatientId = localStorage.getItem("mainPatientId");
          // We consider dependant patients profile to b completed
          const isProfileCompleted =
            id === mainPatientId
              ? connectedPatient.profileCompleted || props?.profileCompleted
              : true;
          setProfilCompleted(isProfileCompleted);

          setLogoPath(connectedPatient.logoPath);
        });
      }
    }
  }, [auth]);

  let pathnames = window.location.pathname;

  // const [active, setActive] = useState(false);
  const url = pathnames.split("/").slice(0, -1).join("/");

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  //nav transparent

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 95) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {!pathnames.includes("home1") && (
        <header
          className={`header ${
            pathnames.includes("home")
              ? "header-trans header-two"
              : "header-fixed header-one"
          } `}
        >
          <div className="container">
            <nav
              className={`navbar navbar-expand-lg header-nav ${
                pathnames.includes("home1") ? "nav-transparent" : ""
              }`}
            >
              <div className="navbar-header">
                <Link
                  to="#0"
                  id="mobile_btn"
                  onClick={() => onHandleMobileMenu()}
                >
                  <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  {pathnames.includes("/cardiohome") ? (
                    <img src={logo_white} className="img-fluid" alt="Logo" />
                  ) : (
                    <img src={logosvg} className="img-fluid" alt="Logo" />
                  )}
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    to="#0"
                    id="menu_close"
                    className="menu-close"
                    onClick={() => onhandleCloseMenu()}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>

                <ul
                  className={`main-nav ${
                    pathnames.includes("home4") ? "white-font" : ""
                  }`}
                >
                  {
                    <li
                      className={`${
                        pathnames.includes("index")
                          ? "active"
                          : "" || pathnames.includes("home")
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/">Accueil</Link>
                    </li>
                  }

                  {auth.user?.profile["role"] == "docteur" &&
                  auth.isAuthenticated ? (
                    <li
                      className={`${
                        pathnames.includes("dashboard-docteur") ? "active" : ""
                      }`}
                    >
                      {currentDoctor?.isAuthorized ? (
                        <Link to="/dashboard-docteur">Dashboard</Link>
                      ) : (
                        <Link to="#">Dashboard</Link>
                      )}
                    </li>
                  ) : (
                    <li
                      className={`${
                        pathnames.includes("doctorsList") ? "active" : ""
                      }`}
                    >
                      <Link to="/doctorsList">Rendez-vous</Link>
                    </li>
                  )}
                  {
                    <li
                      className={`${
                        pathnames.includes("contactus") ? "active" : ""
                      }`}
                    >
                      <Link to="/contactus">Contactez-nous</Link>
                    </li>
                  }
                  {!auth.isAuthenticated ? (
                    <>
                      <li className="register-btn">
                        <Link
                          to="#"
                          className="btn btn-primary log-btn"
                          onClick={() => {
                            void auth.signinRedirect();
                          }}
                        >
                          <i>
                            <FeatherIcon icon="lock" />
                          </i>
                          Se connecter
                        </Link>
                      </li>{" "}
                    </>
                  ) : (
                    <li
                      className={`has-submenu ${
                        url.includes("/doctor") ? "active" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className={isSideMenu == "doctors" ? "subdrop " : ""}
                        onClick={() =>
                          toggleSidebar(
                            isSideMenu == "doctors" ? "submenu" : "doctors"
                          )
                        }
                      >
                        <span className="user-img">
                          <img
                            className="rounded-circle"
                            src={logoPath ? logoPath : default_profile}
                            style={{ width: "31px", height: "31px" }}
                            alt="Photo profil"
                          />
                        </span>
                        <i className="fas fa-chevron-down" />
                      </Link>
                      {isSideMenu == "doctors" ? (
                        <ul
                          className={`${
                            isSideMenu == "doctors"
                              ? "submenu d-block"
                              : "submenu"
                          }`}
                          style={{ right: 0, left: "auto" }}
                        >
                          <li>
                            <Link to="#" onClick={() => alert("open profile")}>
                              <div className="user-header">
                                <div className="avatar avatar-sm">
                                  <img
                                    src={logoPath ? logoPath : default_profile}
                                    alt="Photo profil"
                                    className="avatar-img rounded-circle"
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                  <span className="badge">1</span>
                                </div>
                                <div className="user-text">
                                  <h6>{`${auth.user?.profile["given_name"]} ${auth?.user?.profile["family_name"]}`}</h6>
                                  <p className="text-muted mb-0">
                                    {auth.user?.profile["role"]}
                                    {auth.user?.profile["role"] == "docteur" &&
                                      !currentDoctor?.isAuthorized && (
                                        <p>En attente d'approbation</p>
                                      )}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          {auth.user?.profile["role"] == "admin" ? (
                            <>
                              {" "}
                              <li
                                className={
                                  pathnames.includes("settings") ? "active" : ""
                                }
                              >
                                <Link to="/admin">Admin management</Link>
                              </li>{" "}
                            </>
                          ) : profilCompleted ? (
                            auth.user?.profile["role"] == "patient" && (
                              <li
                                className={
                                  pathnames.includes("doctor-dashboard")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link to="/dashboard-patient">Profile</Link>
                              </li>
                            )
                          ) : (
                            <li
                              className={
                                pathnames.includes("settings") ? "active" : ""
                              }
                            >
                              <Link
                                to={
                                  auth.user?.profile["role"] == "docteur"
                                    ? "/register-step-1"
                                    : "/patientregisterstep-1"
                                }
                              >
                                Completez votre profil{" "}
                                <span className="badge badge-primary">
                                  Info
                                </span>
                              </Link>
                            </li>
                          )}

                          <li>
                            <Link
                              to="#"
                              onClick={() => {
                                // remove the informations save in the local storage
                                localStorage.removeItem("doctorData");
                                localStorage.removeItem("patientData");

                                // disconnect the user from the server
                                auth.removeUser();
                                localStorage.clear();

                                auth.signoutRedirect();
                              }}
                            >
                              Se déconnecter
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
