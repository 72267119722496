import { Link, useLocation } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/home/footer";
import StickyBox from "react-sticky-box";
import DashboardSidebar from "./sidebar";
import {
  checkIfDoctorHasSpeciality,
  displayDoctorSpecialities,
  isAppointmentInMoreThan48hours,
  isAppointmentTimeInThePast,
} from "../utils/objUtils";
import { AppointmentDetails } from "./AppointmentDetails";
import { useContext, useEffect, useMemo, useState } from "react";
import { PatientContext } from "./PatientContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { IAppointment, IPatient } from "../models/backend";
import { PatientService } from "../services/patientService";
import { default_profile } from "../common/imagepath";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { useNotification } from "../common/NotificationContext";
import { AppointmentService } from "../services/appointmentService";
import ConfirmationDialog from "../common/shared-components/ConfirmationDialog";
import { FixMeLater } from "../tsmigration";
import DisplayAppointmentStatusBadge from "../common/shared-components/DisplayAppointmentStatusBadge";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { MdPersonSearch } from "react-icons/md";
import { deriveAppointmentStatus } from "../utils/filterAndSearchUtils";
import SortArrow from "../common/shared-components/SortArrow";

const PatientDashboard = () => {
  const patientService = new PatientService();
  const appointmentService = new AppointmentService();
  const { showNotification } = useNotification();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [currentPatient, setCurrentPatient] = useState<IPatient>(
    state as IPatient
  );
  const [isLoading, setIsLoading] = useState(true);

  const profileCompleted = state?.profileCompleted as boolean;
  const [patientSaved, setPatientSaved] = useState(false);

  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);
  const auth = useAuth();

  const loadPatientData = async (id: string) => {
    const patientData = await getPatientData(id);
    if (patientData) {
      setCurrentPatient(patientData);
    }
  };

  useEffect(() => {
    const fetchPatientData = async () => {
      setIsLoading(true);

      const id = auth.user?.profile["email"];
      if (currentPatient && profileCompleted && !patientSaved) {
        patientService
          .savePatientAsync(currentPatient)
          .then(async (result) => {
            setPatientSaved(result);
            console.log("Patient mis a jour dans le serveur.");
            await setPatientData(currentPatient);
            setIsLoading(false);
          })
          .catch((err) => {
            patientService;
            console.log(err);
            setIsLoading(false);
          });
      } else {
        loadPatientData(id);
        setIsLoading(false);
      }
    };
    fetchPatientData();
  }, [getPatientData, auth.user, JSON.stringify(patient)]);

  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(
    {} as IAppointment
  );
  // const [showEditAppointmentDialog, setShowEditAppointmentDialog] =
  //   useState(false);
  // const [appointmentToEdit, setAppointmentToEdit] = useState(
  //   {} as IAppointment
  // );
  const handleCloseDetailsDialog = () => setShowDetailsDialog(false);
  const handleShowDetailsDialog = () => setShowDetailsDialog(true);

  const handleOpenAppointmentDetails = (appointmentId: string) => {
    const currentAppointment: IAppointment = currentPatient.appointments.find(
      (item) => item.id === appointmentId
    );
    setSelectedAppointment(currentAppointment);
    handleShowDetailsDialog();
  };

  // edit appointment

  // const handleCloseEditAppointmentDialog = () =>
  //   setShowEditAppointmentDialog(false);
  // const handleShowditAppointmentDialog = () =>
  //   setShowEditAppointmentDialog(true);

  // const handleOpenEditAppointmentDialog = (appointmentId: string) => {
  //   const currentAppointment: IAppointment = currentPatient.appointments.find(
  //     (item) => item.id === appointmentId
  //   );
  //   setAppointmentToEdit(currentAppointment);
  //   handleShowditAppointmentDialog();
  // };
  //   const handleEditAppointmentDialogSubmit = async (
  //     newAppointment: IAppointment
  //   ) => {
  //     console.log("Former Appointment", appointmentToEdit);
  //     console.log("New Appointment", newAppointment);
  //     try {
  //       const appointmentUpdated = await appointmentService.addAppointment(
  //         newAppointment
  //       );

  //       const updatedPatient = {
  //         ...currentPatient,
  //         appointments: [...currentPatient.appointments, newAppointment],
  //       };
  //       await setPatientData(updatedPatient);
  //       if (appointmentUpdated) {
  //         showNotification(
  //           "Rendez-vous mis a jour avec succès",
  //           NOTIFICATION_TYPE.INFO
  //         );
  //         navigate("/dashboard-patient");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       showNotification(
  //         "Erreur survenue pendant la mise à jour du Rendez-vous",
  //         NOTIFICATION_TYPE.ERROR
  //       );
  //     }
  //   };

  const goToBookAppointment = () => {
    navigate("/recherche-docteur");
  };

  // delete appointment
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] =
    useState<IAppointment | null>(null);

  const handleCancelAppointment = (appointment: IAppointment) => {
    setAppointmentToDelete(appointment);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (appointmentToDelete) {
        // Impossible to delete if in less than 2 days
        if (
          !isAppointmentInMoreThan48hours(new Date(appointmentToDelete.date))
        ) {
          showNotification(
            "Impossible d'annuler un RV à moins de 48h",
            NOTIFICATION_TYPE.ERROR
          );
          setDeleteDialogOpen(false);
          setAppointmentToDelete(null);
          return;
        }
        // Impossible to delete a past appointment
        if (!isAppointmentTimeInThePast(appointmentToDelete.date)) {
          showNotification(
            "Impossible d'annuler un RV dont l'heure est passée",
            NOTIFICATION_TYPE.ERROR
          );
          setDeleteDialogOpen(false);
          setAppointmentToDelete(null);
          return;
        }
        const appointmentId: string = appointmentToDelete.id as string;
        console.log(`Appointment with id ${appointmentId} has been deleted`);
        const deleted = await appointmentService.deleteAppointment(
          appointmentId
        );
        if (deleted) {
          const newAppointmentList = currentPatient.appointments.filter(
            (appointment) => appointment.id === appointmentId
          );
          const updatedPatient = {
            ...currentPatient,
            appointments: [...newAppointmentList],
          };
          await setPatientData(updatedPatient);

          showNotification(
            "Rendez vous supprimé avec succès",
            NOTIFICATION_TYPE.INFO
          );
        } else {
          showNotification(
            "Erreur lors de la suppression du rendez vous. Réessayez",
            NOTIFICATION_TYPE.ERROR
          );
        }
        setDeleteDialogOpen(false);
        setAppointmentToDelete(null);
      }
    } catch (error: FixMeLater) {
      console.error(error);
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        "Erreur lors de la suppression du rendez vous ";

      showNotification(errorMessage, NOTIFICATION_TYPE.ERROR);
      setDeleteDialogOpen(false);
      setAppointmentToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setAppointmentToDelete(null);
  };

  // Helper to handle nested object access from dot notation string
  const getValueFromDotNotation = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);

  const sortedAndFilteredAppointments = useMemo(() => {
    let processedAppointments = currentPatient?.appointments || [];

    if (searchTerm) {
      processedAppointments = processedAppointments.filter((appointment) => {
        const doctorName =
          `${appointment.doctor.firstName} ${appointment.doctor.lastName}`.toLowerCase();
        const status = deriveAppointmentStatus(appointment).toLowerCase();
        const date = new Date(appointment.date).toLocaleDateString("fr-FR");
        const specialitiesMatch = checkIfDoctorHasSpeciality(
          appointment.doctor,
          searchTerm
        );

        return (
          doctorName.includes(searchTerm.toLowerCase()) ||
          date.includes(searchTerm) ||
          status.includes(searchTerm.toLowerCase()) ||
          specialitiesMatch
        );
      });
    }

    if (sortConfig) {
      processedAppointments.sort((a, b) => {
        const aValue =
          sortConfig.key === "appointmentState"
            ? deriveAppointmentStatus(a)
            : getValueFromDotNotation(a, sortConfig.key);
        const bValue =
          sortConfig.key === "appointmentState"
            ? deriveAppointmentStatus(b)
            : getValueFromDotNotation(b, sortConfig.key);

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return processedAppointments;
  }, [currentPatient?.appointments, searchTerm, sortConfig]);

  const requestSort = (key: string) => {
    setSortConfig((prevConfig) => {
      if (
        prevConfig !== null &&
        prevConfig.key === key &&
        prevConfig.direction === "ascending"
      ) {
        return { key, direction: "descending" };
      } else {
        return { key, direction: "ascending" };
      }
    });
  };

  return (
    <>
      <Header profileCompleted={currentPatient?.profileCompleted} />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Rendez-vous</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Rendez-vous
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Breadcrumb -->     */}
      <div className="content">
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DashboardSidebar />
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row" style={{ marginBottom: "25px" }}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Rechercher RV (par docteur, Date, statut, Spécialité)
                    </InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type="text"
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      value={searchTerm}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={() => {}} edge="end">
                            <MdPersonSearch size={20} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div className="card">
                  <div className="card-body pt-0">
                    <div className="tab-content pt-0">
                      <div
                        id="pat_appointments"
                        className="tab-pane fade show active"
                      >
                        <div className="card card-table mb-0">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-sm-6">
                                <h3 className="card-title">Rendez-vous</h3>
                              </div>
                              <div className="col-sm-6">
                                <div className="text-end">
                                  <a
                                    onClick={goToBookAppointment}
                                    className="btn btn-primary btn-sm"
                                    tabIndex={0}
                                  >
                                    Nouveau Rendez vous
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-hover table-center mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      onClick={() =>
                                        requestSort("doctor.firstName")
                                      }
                                    >
                                      Docteur{" "}
                                      {sortConfig?.key ===
                                        "doctor.firstName" && (
                                        <SortArrow
                                          direction={sortConfig?.direction}
                                        />
                                      )}
                                    </th>
                                    <th onClick={() => requestSort("date")}>
                                      Date Rendez-Vous
                                      {sortConfig?.key === "date" && (
                                        <SortArrow
                                          direction={sortConfig?.direction}
                                        />
                                      )}
                                    </th>
                                    <th
                                      onClick={() =>
                                        requestSort("appointmentState")
                                      }
                                    >
                                      Statut
                                      {sortConfig?.key ===
                                        "appointmentState" && (
                                        <SortArrow
                                          direction={sortConfig?.direction}
                                        />
                                      )}
                                    </th>
                                    <th className="text-center">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sortedAndFilteredAppointments.map(
                                    (appointment) => (
                                      <tr key={appointment.id}>
                                        <td>
                                          <h2 className="table-avatar">
                                            <Link
                                              to="#"
                                              className="avatar avatar-sm me-2"
                                            >
                                              <img
                                                className="avatar-img rounded-circle"
                                                src={
                                                  appointment.doctor?.logoPath
                                                    ? appointment.doctor
                                                        ?.logoPath
                                                    : default_profile
                                                }
                                                alt="User"
                                              />
                                            </Link>
                                            <Link to="#">
                                              Dr. {appointment.doctor.firstName}{" "}
                                              {appointment.doctor.lastName}{" "}
                                              <span>
                                                {displayDoctorSpecialities(
                                                  appointment?.doctor
                                                )}
                                              </span>
                                            </Link>
                                          </h2>
                                        </td>
                                        <td>
                                          {appointment.date && (
                                            <>
                                              {new Date(
                                                appointment.date
                                              ).toLocaleDateString("fr-FR")}

                                              <span className="d-block text-info">
                                                {new Date(
                                                  appointment.date
                                                ).getUTCHours()}
                                                {":"}
                                                {(
                                                  "0" +
                                                  new Date(
                                                    appointment.date
                                                  ).getMinutes()
                                                ).slice(-2)}
                                              </span>
                                            </>
                                          )}
                                        </td>
                                        <td>
                                          <DisplayAppointmentStatusBadge
                                            appointment={appointment}
                                          ></DisplayAppointmentStatusBadge>
                                        </td>
                                        <td className="text-end">
                                          <div className="table-action text-center">
                                            <Link
                                              to="#"
                                              className="btn btn-sm bg-info-light"
                                              onClick={() =>
                                                handleOpenAppointmentDetails(
                                                  appointment.id
                                                )
                                              }
                                            >
                                              <i className="far fa-eye"></i>{" "}
                                              Details
                                            </Link>
                                            &nbsp;
                                            {!appointment.isHonored &&
                                              !isAppointmentTimeInThePast(
                                                appointment.date
                                              ) && (
                                                <Link
                                                  to="#"
                                                  onClick={() =>
                                                    handleCancelAppointment(
                                                      appointment
                                                    )
                                                  }
                                                  className="btn btn-sm bg-danger-light"
                                                >
                                                  <i className="far fa-times"></i>{" "}
                                                  Annuler RV
                                                </Link>
                                              )}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppointmentDetails
            show={showDetailsDialog}
            handleClose={handleCloseDetailsDialog}
            appointmentDetails={selectedAppointment}
          ></AppointmentDetails>
          {appointmentToDelete && (
            <ConfirmationDialog
              open={deleteDialogOpen}
              title="Suppression du rendez-vous"
              message="Êtes-vous sur de vouloir supprimer ce Rendez vous?"
              onConfirm={handleDeleteConfirm}
              onCancel={handleDeleteCancel}
            />
          )}
          {/* {appointmentToEdit && (
            <>
              <PatientBookEditAppointmentModal
                show={showEditAppointmentDialog}
                handleClose={handleCloseEditAppointmentDialog}
                appointment={appointmentToEdit}
                dialogTitle={"Prise de Rendez vous"}
                onSubmit={(newAppointment: IAppointment) =>
                  handleEditAppointmentDialogSubmit(newAppointment)
                }
                selectedDoctor={appointmentToEdit.doctor}
              ></PatientBookEditAppointmentModal>
            </>
          )} */}
        </>
      </div>
      <Footer />
    </>
  );
};
export default PatientDashboard;
