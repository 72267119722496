import React, { useEffect } from "react";
import { faq_img, smiling_icon } from "../imagepath";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { Accordion } from "react-bootstrap";

function Faq() {
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="section-header-one text-center aos"
              data-aos="fade-up"
            >
              <h5>Obtenez votre réponse</h5>
              <h2 className="section-title">Questions fréquemment posées</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 aos" data-aos="fade-up">
            <div className="faq-img">
              <img src={faq_img} className="img-fluid" alt="img" />
              {/* <div className="faq-patients-count">
                <div className="faq-smile-img">
                  <img src={smiling_icon} alt="icon" />
                </div>
                <div className="faq-patients-content">
                  <h4>
                    <span className="count-digit">
                      <CountUp start={1} end={95} />
                    </span>
                    k+
                  </h4>
                  <p>Patients Satisfaits</p>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="faq-info aos" data-aos="fade-up">
              <div className="accordion" id="faq-details">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Comment créer un compte ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Pour créer un compte, cliquez sur "se connecter" en haut
                      de la page, puis sur “s’inscrire” et ensuite suivez les
                      instructions pour entrer vos informations personnelles et
                      créer un mot de passe.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Comment rechercher un médecin ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Utilisez la barre de recherche pour entrer le nom du
                      médecin, la spécialité, ou la localisation. Vous pouvez
                      affiner les résultats avec des filtres.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Comment prendre un rendez-vous ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Après avoir trouvé votre médecin, sélectionnez un créneau
                      horaire disponible et suivez les instructions pour
                      confirmer le rendez-vous.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Puis-je prendre rendez-vous pour un membre de ma famille ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Oui, vous pouvez ajouter des membres de votre famille à
                      votre compte et prendre rendez-vous en leur nom.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Puis-je accéder à mon dossier médical en ligne ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Oui, après chaque consultation, votre dossier médical
                      (ordonnances, analyses, recommandations, résultats) sera
                      mis à jour par votre médecin et accessible en ligne.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Est-ce que mes informations personnelles sont sécurisées ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Oui, nous utilisons des protocoles de sécurité avancés
                      pour protéger vos données personnelles et médicales.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Vais-je recevoir un rappel pour mon rendez-vous ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Oui, vous recevrez des rappels automatiques par e-mail
                      et/ou SMS avant votre rendez-vous.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Comment contacter le service client ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Pour toute question ou assistance, contactez notre service
                      client via le formulaire de contact sur le site ou
                      appelez-nous au numéro indiqué.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
