import React, { useState } from "react";
import { default_profile } from "./imagepath";
import { Modal } from "react-bootstrap";
import { IUserProfile } from "../../models/backend";

const Profile = (props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [profile, setProfile] = useState<IUserProfile>(props.profile);
  const [telephone, setTelephone] = useState<string>(profile?.phoneNumber);
  const [ligne1, setLigne1] = useState<string>(profile?.address?.addressLine1);
  const [ligne2, setLigne2] = useState<string>(profile?.address?.addressLine2);
  const [country, setCountry] = useState<string>(profile?.address?.country);
  const [city, setCity] = useState<string>(profile?.address?.city);
  const [postalCode, setPostalCode] = useState<string>(
    profile?.address?.postalCode
  );

  return (
    <>
      <div>
        <div>
          <div className="col-md-12">
            <div className="profile-header">
              <div className="row align-items-center">
                <div className="col-auto profile-image">
                  <a href="#">
                    <img
                      className="rounded-circle"
                      alt="User Image"
                      src={profile.logoPath ?? default_profile}
                    />
                  </a>
                </div>
                <div className="col ml-md-n2 profile-user-info">
                  <h4 className="user-name mb-0">{`${profile.firstName} ${profile.lastName}`}</h4>
                  <h6 className="text-muted">{profile.id}</h6>
                  <div className="user-Location">
                    <i className="fa fa-map-marker" />{" "}
                    {`${profile.address?.country}, ${profile.address?.city}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-content profile-tab-cont">
              <div className="tab-pane fade show active" id="per_details_tab">
                {/* Personal Details */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title d-flex justify-content-between">
                          <span>Informations personnelles</span>
                          <a
                            className="edit-link"
                            data-bs-toggle="modal"
                            onClick={() => {
                              setShowEdit(true);
                            }}
                          >
                            <i className="fa fa-edit me-1" />
                            Modifier
                          </a>
                        </h5>
                        <div className="row">
                          <p className="col-sm-2 text-muted text-sm-end mb-0 mb-sm-3">
                            Nom
                          </p>
                          <p className="col-sm-10">{`${profile.firstName} ${profile.lastName}`}</p>
                        </div>
                        <div className="row">
                          <p className="col-sm-2 text-muted text-sm-end mb-0 mb-sm-3">
                            Email
                          </p>
                          <p className="col-sm-10">{profile.id}</p>
                        </div>
                        <div className="row">
                          <p className="col-sm-2 text-muted text-sm-end mb-0 mb-sm-3">
                            Telephone
                          </p>
                          <p className="col-sm-10">{profile.phoneNumber}</p>
                        </div>
                        <div className="row">
                          <p className="col-sm-2 text-muted text-sm-end mb-0">
                            Adresse
                          </p>
                          <p className="col-sm-10 mb-0">
                            {profile.address?.addressLine1},
                            <br />
                            {profile.address?.city},
                            <br />
                            {profile.address?.country}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Edit Details Modal */}
                    <Modal
                      className="modal"
                      show={showEdit}
                      onHide={() => {
                        setShowEdit(false);
                      }}
                      style={{ marginTop: "40px" }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <h4 className="modal-title">Modifier informations</h4>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row form-row">
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Prenom</label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile?.firstName}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Nom</label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile?.lastName}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                value={profile?.id}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Telephone</label>
                              <input
                                type="text"
                                className="form-control"
                                value={telephone}
                                onChange={(e) => {
                                  profile.phoneNumber = e.target.value;
                                  setTelephone(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="form-title">
                              <span>Adresse</span>
                            </h5>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <label>Ligne 1</label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile?.address?.addressLine1}
                                onChange={(e) => {
                                  profile.address.addressLine1 = e.target.value;
                                  setLigne1(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <label>Ligne 2</label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile?.address?.addressLine2}
                                onChange={(e) => {
                                  profile.address.addressLine2 = e.target.value;
                                  setLigne2(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Ville / Region</label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile?.address?.city}
                                onChange={(e) => {
                                  profile.address.city = e.target.value;
                                  setCity(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Code postal</label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile?.address?.postalCode}
                                onChange={(e) => {
                                  profile.address.postalCode = e.target.value;
                                  setPostalCode(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Pays</label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile?.address?.country}
                                onChange={(e) => {
                                  profile.address.country = e.target.value;
                                  setCountry(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button type="submit" className="btn btn-primary w-100">
                          Save Changes
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
                {/* /Personal Details */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Profile;
