import { Modal, Button } from "react-bootstrap";

const ConfirmationDialog = ({ open, title, message, onConfirm, onCancel }) => {
  return (
    <Modal show={open} onHide={onCancel} aria-labelledby="modal-title" centered>
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onConfirm}>
          Confirmer
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Annuler
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
