import { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { IAppointment } from "../models/backend";
import {
  displayDateInFrenchFormat,
  displayDoctorSpecialities,
  formatAddress,
  isObjectEmptyOrUndefined,
} from "../utils/objUtils";
import DisplayAppointmentStatusBadge from "../common/shared-components/DisplayAppointmentStatusBadge";
import { FixMeLater } from "../tsmigration";
import { FiMapPin } from "react-icons/fi";
import { AppointmentService } from "../services/appointmentService";
import { useNotification } from "../common/NotificationContext";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { PatientContext } from "./PatientContext";

export const AppointmentDetails = ({
  show,
  handleClose,
  appointmentDetails,
}: {
  show: boolean;
  handleClose: () => void;
  appointmentDetails: IAppointment;
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const appointmentService = new AppointmentService();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { patient, setPatientData, getPatientData } =
    useContext(PatientContext);
  const goToEditMode = () => {
    setAppointmentData({ ...appointmentDetails });
    setIsEditMode(true);
  };
  const leaveEditMode = () => {
    setIsEditMode(false);
  };
  const [appointmentData, setAppointmentData] = useState<IAppointment>({
    ...appointmentDetails,
  });

  const handleChange = (e: FixMeLater) => {
    const { name, value } = e.target;
    setAppointmentData({
      ...appointmentData,
      [name]: value,
    });
  };

  const handleEditAppointmentDialogSubmit = async (event: FixMeLater) => {
    event.preventDefault();
    try {
      const appointmentUpdated = await appointmentService.editAppointment(
        appointmentData
      );
      const newAppointmentList = [];

      const updatedPatient = {
        ...appointmentDetails.patient,
        appointments: [...newAppointmentList],
      };
      localStorage.removeItem("patientData");
      await setPatientData(updatedPatient);
      if (appointmentUpdated) {
        showNotification(
          "Rendez-vous mis a jour avec succès",
          NOTIFICATION_TYPE.INFO
        );
        leaveEditMode();
        handleClose();
        navigate("/dashboard-patient");
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "Erreur survenue pendant la mise à jour du Rendez-vous",
        NOTIFICATION_TYPE.ERROR
      );
    }
  };

  useEffect(() => {}, [appointmentDetails]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          leaveEditMode();
        }}
        backdrop="static"
        className="modal"
        style={{ marginTop: "80px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">
              {isEditMode ? "Modifier Rendez vous" : "Détails du Rendez vous"}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <form>
              <div className="modal-body">
                {isEditMode ? (
                  <>
                    <div className="form-group">
                      <label
                        htmlFor="title"
                        className="col-sm-2 col-form-label"
                      >
                        Notes du patient:
                      </label>
                      <textarea
                        className="form-control"
                        name="patientNote"
                        value={appointmentData.patientNote}
                        onChange={handleChange}
                        required
                        placeholder="Notes et observations"
                        rows={5}
                        style={{ overflowY: "hidden" }}
                        onInput={(e: FixMeLater) => {
                          e.target.style.height = "auto";
                          e.target.style.height = `${e.target.scrollHeight}px`;
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <ul className="info-details">
                      <li>
                        <div className="details-header">
                          <div className="row">
                            {appointmentDetails.date && (
                              <div className="col-md-9">
                                <span className="title">Date</span>
                                <span className="text">
                                  {displayDateInFrenchFormat(
                                    new Date(appointmentDetails.date)
                                  )}
                                </span>
                              </div>
                            )}
                            <div className="col-md-3">
                              <div className="text-end">
                                <DisplayAppointmentStatusBadge
                                  appointment={appointmentDetails}
                                ></DisplayAppointmentStatusBadge>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <span className="title">Patient:</span>
                        <span className="text">
                          {appointmentDetails?.patient?.firstName}{" "}
                          {appointmentDetails?.patient?.lastName}
                        </span>
                      </li>
                      <li>
                        <span className="title">Médecin traitant:</span>
                        <span className="text">
                          Dr. {appointmentDetails?.doctor?.firstName}{" "}
                          {appointmentDetails?.doctor?.lastName}
                        </span>
                        <span className="text">
                          {displayDoctorSpecialities(
                            appointmentDetails?.doctor
                          )}
                        </span>
                      </li>
                      {!isObjectEmptyOrUndefined(
                        appointmentDetails?.doctor?.doctorAddress
                      ) && (
                        <li>
                          <span className="title">Lieu Rendez-vous:</span>
                          <span className="text">
                            <FiMapPin />{" "}
                            {formatAddress(
                              appointmentDetails?.doctor?.doctorAddress
                            )}
                          </span>
                        </li>
                      )}
                      <li>
                        <span className="title">Prix Consultation</span>
                        <span className="text">
                          {`${appointmentDetails?.doctor?.consultationFee} Francs CFA` ||
                            "N/A"}
                        </span>
                      </li>
                      <li>
                        <span className="title">Notes Docteur</span>
                        <span className="text">
                          {appointmentDetails.doctorNote || "N/A"}
                        </span>
                      </li>
                      <li>
                        <span className="title">Notes Patient</span>
                        <span className="text">
                          {appointmentDetails.patientNote || "N/A"}
                        </span>
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div className="modal-footer text-center">
                {isEditMode ? (
                  <>
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleEditAppointmentDialogSubmit}
                    >
                      Enregistrer
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={leaveEditMode}
                    >
                      Annuler
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={goToEditMode}
                      hidden={appointmentDetails.isHonored}
                      disabled={appointmentDetails.isHonored}
                    >
                      Modifier
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleClose}
                    >
                      Fermer
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      ;
    </>
  );
};
