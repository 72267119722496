import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { default_profile } from "../common/imagepath";
import { IDoctor } from "../models/backend";
import { DoctorContext } from "./DoctorContext";
import { isObjectEmptyOrUndefined } from "../utils/objUtils";
import { useAuth } from "react-oidc-context";

export const DoctorSidebar = () => {
  const pathname = window.location.pathname;
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>(null);
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const auth = useAuth();

  useEffect(() => {
    const id = auth.user?.profile["email"];
    getDoctorData(id).then((doctorData) => {
      if (doctorData && !currentDoctor) {
        setCurrentDoctor(doctorData);
      }
    });
  }, [currentDoctor]);
  return (
    <div className="profile-sidebar">
      {!isObjectEmptyOrUndefined(currentDoctor) && (
        <>
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#0" className="booking-doc-img">
                <img
                  src={
                    currentDoctor?.logoPath
                      ? currentDoctor?.logoPath
                      : default_profile
                  }
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>
                  Dr. {currentDoctor.firstName} {currentDoctor.lastName}
                </h3>
                <div className="patient-details">
                  <h5 className="mb-0">
                    {currentDoctor?.specialities
                      ?.map((x) => x.title)
                      ?.join(" - ")}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li className={pathname.includes("/dashboard") ? "active" : ""}>
                  <Link to="/dashboard-docteur">
                    <i className="fas fa-columns"></i>
                    <span>Rendez-vous</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname.includes("/calendrier-docteur") ? "active" : ""
                  }
                >
                  <Link to="/calendrier-docteur">
                    <i className="fas fa-file-invoice-dollar"></i>
                    <span>Disponibilités</span>
                  </Link>
                </li>
                <li
                  className={pathname.includes("/mes-patients") ? "active" : ""}
                >
                  <Link to="/mes-patients">
                    <i className="fas fa-user-injured"></i>
                    <span>Mes patients</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname.includes("/infos-docteur") ? "active" : ""
                  }
                >
                  <Link to="/infos-docteur">
                    <i className="fas fa-user-cog"></i>
                    <span>Informations du profil</span>
                  </Link>
                </li>
                <li className={pathname.includes("changepass") ? "active" : ""}>
                  <Link to="/medecin-changepass">
                    <i className="fas fa-lock"></i>
                    <span>Changer mot de passe</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      // remove the informations save in the local storage
                      localStorage.removeItem("doctorData");
                      localStorage.removeItem("patientData");

                      // disconnect the user from the server
                      auth.removeUser();
                      localStorage.clear();

                      auth.signoutRedirect();
                    }}
                  >
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Déconnexion</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};
export default DoctorSidebar;
