import { IPatient, IRelatedPatients } from "../../models/backend";

const PatientSelectionListComponent = (props: {
  patientList: IRelatedPatients[];
  onChange: (selectedValue: IRelatedPatients) => void;
  initialValue?: string;
}) => {
  const findPatientById = (patientId: string) => {
    if (!props.patientList || props.patientList.length === 0) {
      return null;
    }
    const currentPatient = props.patientList.find((patient) => {
      return patient.id == patientId;
    });
    return currentPatient;
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const selectedPatient: IRelatedPatients = findPatientById(selectedValue);
    props.onChange(selectedPatient);
  };
  return (
    <select
      className="form-control"
      onChange={handleSelectChange}
      value={props.initialValue}
    >
      {props.patientList.map((patient, index) => (
        <option key={index} value={patient.id}>
          {patient.name} ({patient.id})
        </option>
      ))}
    </select>
  );
};

export default PatientSelectionListComponent;
