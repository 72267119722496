import { useState } from "react";
import { Modal } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import {
  IDoctor,
  IMedicalRecordModel,
  IPatient,
  IRecordType,
} from "../models/backend";
import { PulseLoader } from "react-spinners";
import { DoctorService } from "../services/doctorService";
import { NOTIFICATION_TYPE } from "../models/notifications";
import NotificationComponent from "../common/NotificationComponent";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const CreateEditMedicalRecordModal = ({
  showDialog,
  recordTypes,
  handleClose,
  patient,
  doctor,
  onSubmit,
}: {
  showDialog: boolean;
  recordTypes: IRecordType[];
  handleClose: () => void;
  patient: IPatient;
  doctor: IDoctor;
  onSubmit: () => void;
}) => {
  const doctorService = new DoctorService();

  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [file, setFile] = useState<File>(null);
  const [savingFile, setSavingFile] = useState(false);
  const [savingInfo, setSavingInfo] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showFormError, setShowFormError] = useState(false);

  const resetStates = () => {
    setTitle("");
    setNote("");
    setFile(null);
    setSavingFile(false);
    setSavingInfo(false);
  };

  const formIsNotValid = () => {
    return title == "" || note == "";
  };

  const saveMedicalRecordInformations = async (filePath?: string) => {
    // update the path of the file
    setSavingFile(false);
    setSavingInfo(true);
    // save the record information
    doctorService
      .saveMedicalRecordAsync({
        clinic: doctor?.doctorClinic?.name,
        doctorId: doctor?.id,
        fileName: file?.name,
        filePath: filePath,
        patientId: patient?.id,
        recordNote: note,
        title: title,
      } as IMedicalRecordModel)
      .then((result) => {
        setSavingInfo(false);

        // display the notification
        if (result) {
          handleClose();
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);
        } else {
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }

        // fetch the new added medical record
        onSubmit();
      });
  };

  const saveMedicalRecord = () => {
    // if the form is not valid then display an error
    if (formIsNotValid()) {
      setShowFormError(true);
      setTimeout(() => {
        setShowFormError(false);
      }, 3000);

      return;
    }

    // save the file
    if (file) {
      setSavingFile(true);
      let formFile = new FormData();
      formFile.append(file?.name, file);
      doctorService
        .saveMedicalRecordFileAsync(formFile)
        .then((result) => {
          saveMedicalRecordInformations(result);
        })
        .finally(() => {
          resetStates();
        });
    } else {
      saveMedicalRecordInformations().finally(() => {
        resetStates();
      });
    }
  };

  return (
    <>
      {showSuccess && (
        <NotificationComponent
          message="Les informations ont ete enregistree avec succes."
          notificationType={NOTIFICATION_TYPE.SUCCESS}
        />
      )}
      {showError && (
        <NotificationComponent
          message="Des erreurs sont servenues lors de l'enregistrement."
          notificationType={NOTIFICATION_TYPE.ERROR}
        />
      )}
      {showFormError && (
        <NotificationComponent
          message="Merci de remplir les champs du formulaire."
          notificationType={NOTIFICATION_TYPE.ERROR}
        />
      )}

      <Modal
        size="lg"
        show={showDialog}
        onHide={() => {
          resetStates();
          handleClose();
        }}
        style={{ marginTop: "75px" }}
        className="custom-medicalrecord-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">Add Medical Records</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <form id="medical_records_form">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>
                        Type<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select form-control"
                        id="typeRecord"
                        name="typeRecord"
                        tabIndex={-1}
                        aria-hidden="true"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      >
                        <option value="">Selectionnez un type</option>
                        {recordTypes.map((rt) => {
                          return (
                            <option value={rt?.title} key={rt?.id}>
                              {rt?.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Patient</label>
                      <input
                        type="text"
                        name="patient"
                        className="form-control"
                        value={`${patient?.firstName} ${patient?.lastName}`}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Nom de la clinique</label>
                      <input
                        type="text"
                        name="hospital"
                        className="form-control"
                        value={doctor?.doctorClinic?.name}
                        disabled
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Upload</label>
                      <div className="upload-medical-records">
                        <input
                          className="form-control"
                          type="file"
                          name="user_file"
                          id="user_files_mr"
                          onChange={(e) => {
                            let file = e.target.files[0] || null;
                            setFile(file);
                          }}
                        />
                        <div className="upload-content dropzone">
                          <div className="text-center">
                            {file ? (
                              <h5>{file.name}</h5>
                            ) : (
                              <>
                                <h5>Drag &amp; Drop</h5>
                              </>
                            )}
                            <h6>
                              or <span className="text-danger">Browse</span>
                            </h6>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>
                        Symptomes / Notes<span className="text-danger">*</span>
                      </label>
                      <textarea
                        data-role="tagsinput"
                        className="input-tags form-control"
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                        name="services"
                        id="services"
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <div className="submit-section text-center">
                    {savingFile || savingInfo ? (
                      <PulseLoader color="#0e82fd" />
                    ) : (
                      <button
                        type="button"
                        id="medical_btn"
                        className="btn btn-primary submit-btn"
                        onClick={saveMedicalRecord}
                      >
                        Enregistrer
                      </button>
                    )}

                    {savingFile && <div>Enregistrement du fichier...</div>}

                    {savingInfo && (
                      <div>Enregistrement des informations...</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
