const SelectionListComponent = (props: {
  availableOptions: string[];
  onChange: (selectedValue: string) => void;
  initialValue?: string;
}) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    props.onChange(selectedValue);
  };
  return (
    <select
      className="form-control"
      onChange={handleSelectChange}
      value={props.initialValue}
    >
      {props.availableOptions.map((x, index) => (
        <option key={index} value={x}>
          {x}
        </option>
      ))}
    </select>
  );
};

export default SelectionListComponent;
