import { Link } from "react-router-dom";
import Header from "../common/header";
import { useState, useContext, useEffect } from "react";
import { IDoctor, IPatient } from "../models/backend";
import { PatientContext } from "./PatientContext";
import StickyBox from "react-sticky-box";
import DashboardSidebar from "./sidebar";
import Footer from "../common/home/footer";
import { useLocation } from "react-router-dom";
import { default_profile } from "../common/imagepath";
import PatientScheduleView from "./PatientScheduleView";
import { useAuth } from "react-oidc-context";

const PatientBookAppointment = () => {
  const auth = useAuth();

  const location = useLocation();
  const selectedDoctor: IDoctor = location.state.doctor;
  const [activePage, setActivePage] = useState(1);

  const getPatientDoctors = (patient: IPatient) => {
    return patient.doctors;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [currentPatient, setCurrentPatient] = useState<IPatient>(
    {} as IPatient
  );

  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);

  useEffect(() => {
    const fetchPatientData = async () => {
      setIsLoading(true);
      const id = auth.user?.profile["email"] || "";
      const patientData = await getPatientData(id);
      setCurrentPatient(patientData);
      setIsLoading(false);
    };
    fetchPatientData();
  }, [getPatientData]);

  return (
    <>
      <Header />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Prise de Rendez vous</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Prise Rendez vous
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        "Loading..."
      ) : (
        <>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <DashboardSidebar />
                  </StickyBox>
                </div>{" "}
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row">
                    <div key={selectedDoctor.id} className="card doctor-card">
                      <div className="card-body">
                        <div className="doctor-widget-one">
                          <div className="doc-info-left">
                            <div className="doctor-img">
                              <Link to="/patient/doctor-list">
                                <img
                                  src={
                                    selectedDoctor?.logoPath
                                      ? selectedDoctor?.logoPath
                                      : default_profile
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="doc-info-cont">
                              <h4 className="doc-name">
                                <Link to="/patient/doctor-profile">
                                  Dr. {selectedDoctor.firstName}{" "}
                                  {selectedDoctor.lastName}
                                </Link>
                              </h4>
                              <p className="doc-speciality">
                                {selectedDoctor.specialities
                                  ?.map((s) => s.title)
                                  .join(",")}
                              </p>
                              <div className="clinic-details">
                                <p className="doc-location">
                                  {selectedDoctor.doctorAddress.addressLine1}{" "}
                                  <br></br>
                                  {selectedDoctor.doctorAddress.city} <br></br>
                                </p>
                                <p className="doc-location">
                                  <span>{selectedDoctor.consultationFee}</span>{" "}
                                  Francs / consultation
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body pt-0">
                      <PatientScheduleView
                        currentDoctor={selectedDoctor}
                        currentPatient={currentPatient}
                      ></PatientScheduleView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default PatientBookAppointment;
