/* eslint-disable react/no-unknown-property */
import React, { useState, useMemo, useEffect } from "react";
// import loginBanner from '../../assets/images/login-banner.png';
import Logo from "../../assets/images/allodoc.png";
// import camera from '../../assets/images/icons/camera.svg';
import male from "../../assets/images/icons/male.png";
import female from "../../assets/images/icons/female.png";
import countryList from "react-select-country-list";
import { AuthState, useAuth } from "react-oidc-context";

import { Link, useLocation } from "react-router-dom";
import { IPatient } from "../../models/backend";
import uuid from "react-uuid";

const Patientregistersteptwo = (props) => {
  const options = countryList().getData();
  const auth = useAuth();
  const { state } = useLocation();
  const [country, setCountry] = useState("");
  const [dob, setDOB] = useState("");
  const [addLigne1, setAddLigne1] = useState("");
  const [addLigne2, setAddLigne2] = useState("");
  const [region, setRegion] = useState("");
  const [etat, setEtat] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [genre, setGenre] = useState("");
  const [erreur, setErreur] = useState(false);
  const [msgErreur, setMessageErreur] = useState([]);
  const [defaultValueSet, setDefaultValueFlag] = useState(false);

  const validateForm = () => {
    let messageErreur = [];
    if (addLigne1 == "") {
      messageErreur.push("Au moins la ligne 1 de l'adresse");
    }

    if (region == "") {
      messageErreur.push("Region");
    }

    if (genre == "") {
      messageErreur.push("Genre");
    }

    if (dob == "") {
      messageErreur.push("Date de naissance");
    }

    if (country == "") {
      messageErreur.push("Le pays");
    }

    if (messageErreur.length > 0) {
      setErreur(true);
      setMessageErreur(messageErreur);
    } else {
      setErreur(false);
    }
  };

  const setDefaultValues = (patient: IPatient) => {
    setCountry(patient?.patientAddress?.country);
    setDOB(patient?.dayOfBirth?.toDateString());
    setAddLigne1(patient?.patientAddress?.addressLine1);
    setAddLigne2(patient?.patientAddress?.addressLine2);
    setRegion(patient?.patientAddress?.region);
    setEtat(patient?.patientAddress?.state);
    setCodePostal(patient?.patientAddress?.postalCode);
    setGenre(patient?.gender);
    setDefaultValueFlag(true);
  };

  useEffect(() => {
    if (props?.location?.state && !defaultValueSet) {
      setDefaultValues(props?.location?.state as IPatient);
    }
    validateForm();
  }, [addLigne1, dob, region, genre, country]);

  return (
    <>
      <>
        {/* Page Content */}
        <div className="content login-page pt-0">
          <div className="container-fluid">
            {/* Register Content */}
            <div className="account-content">
              <div className="row align-items-center">
                <div className="login-right">
                  <div className="inner-right-login">
                    <div className="login-header">
                      <div className="logo-icon">
                        <img src={Logo} alt="" />
                      </div>
                      <div className="step-list">
                        <ul>
                          <li>
                            <Link to="#" className="active-done">
                              1
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="active">
                              2
                            </Link>
                          </li>
                          <li>
                            <Link to="#">3</Link>
                          </li>
                        </ul>
                      </div>
                      <form id="personal_details" encType="multipart/form-data">
                        <div className="text-start mt-2">
                          <h4 className="mt-3">
                            Select Your Gender{" "}
                            <span className="text-danger">*</span>
                          </h4>
                        </div>
                        <div className="select-gender-col">
                          <div className="row">
                            <div className="col-6 pe-0">
                              <input
                                type="radio"
                                id="test1"
                                name="gender"
                                defaultValue="Homme"
                                checked={genre == "Homme"}
                                onChange={(e) => {
                                  setGenre(e.target.value);
                                }}
                              />
                              <label htmlFor="test1">
                                <span className="gender-icon">
                                  <img src={male} alt="" />
                                </span>
                                <span>Homme</span>
                              </label>
                            </div>
                            <div className="col-6 ps-2">
                              <input
                                type="radio"
                                id="test2"
                                name="gender"
                                defaultValue="Femme"
                                checked={genre == "Femme"}
                                onChange={(e) => {
                                  setGenre(e.target.value);
                                }}
                              />
                              <label htmlFor="test2">
                                <span className="gender-icon">
                                  <img src={female} alt="" />
                                </span>
                                <span>Femme</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="step-process-col mt-4">
                          <div className="form-group">
                            <label>
                              Date de naissance{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="schedule_date"
                              id="schedule_date"
                              value={dob}
                              min="1900-01-01"
                              onChange={(e) => {
                                setDOB(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Adresse <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="addressligne2"
                              value={addLigne1}
                              placeholder="ligne 1"
                              className="form-control"
                              id="addressligne1"
                              onChange={(e) => {
                                setAddLigne1(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              name="addressligne2"
                              value={addLigne2}
                              placeholder="ligne 2"
                              className="form-control"
                              id="addressligne2"
                              onChange={(e) => {
                                setAddLigne2(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Ville / Region{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="region"
                              value={region}
                              placeholder=""
                              className="form-control"
                              id="region"
                              onChange={(e) => {
                                setRegion(e.target.value);
                              }}
                            />
                          </div>
                          {/* <div className="form-group">
                            <label>Etat</label>
                            <input
                              type="text"
                              name="etat"
                              value={etat}
                              placeholder=""
                              className="form-control"
                              id="etat"
                              onChange={(e) => {
                                setEtat(e.target.value);
                              }}
                            />
                          </div> */}
                          <div className="form-group">
                            <label>Code postal</label>
                            <input
                              type="text"
                              name="codepostal"
                              value={codePostal}
                              placeholder=""
                              className="form-control"
                              id="codepostal"
                              onChange={(e) => {
                                setCodePostal(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Pays <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select form-control"
                              id="pays"
                              name="pays"
                              tabIndex={-1}
                              aria-hidden="true"
                              value={country}
                              onChange={(e) => {
                                setCountry(e.target.value);
                              }}
                            >
                              <option value="">Selectionnez votre pays</option>
                              {options.map((opt) => {
                                return (
                                  <option value={opt?.value} key={opt?.value}>
                                    {opt?.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {erreur && (
                          <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>Erreur!</strong> Merci de remplir tous les
                            champs du formulaire avant de passer a la page
                            suivante
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                            <ul>
                              {msgErreur.map((m) => {
                                return <li key={m}>{m}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                        <div className="mt-5">
                          {/* {https://stackoverflow.com/a/69115990} */}
                          <Link
                            state={
                              {
                                id: auth?.user?.profile["preferred_username"],
                                firstName: auth?.user?.profile["given_name"],
                                lastName: auth?.user?.profile["family_name"],
                                phoneNumber:
                                  auth?.user?.profile["phone_number"],
                                gender: genre,
                                dayOfBirth: new Date(dob),
                                logoPath: state?.logoPath,
                                patientAddress: {
                                  id: uuid(),
                                  addressLine1: addLigne1,
                                  addressLine2: addLigne2,
                                  city: region,
                                  region: region,
                                  postalCode: codePostal,
                                  country: country,
                                  state: etat,
                                },
                              } as IPatient
                            }
                            to={{
                              pathname: "/patientregisterstep-3",
                            }}
                            className={`btn ${
                              erreur ? "disabled" : ""
                            } btn-primary w-100 btn-lg login-btn step2_submit`}
                          >
                            continue{" "}
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="login-bottom-copyright">
                    <span>© 2024 Allo-Doc. Tous droits réservés.</span>
                  </div>
                </div>
              </div>
            </div>
            {/* /Register Content */}
          </div>
        </div>
        {/* /Page Content */}
      </>
    </>
  );
};

export default Patientregistersteptwo;
