import {
  APPOINTMENT_STATUS,
  IAppointment,
  IDoctorBody,
  IPatientBody,
} from "../models/backend";
import { FixMeLater } from "../tsmigration";
import { BACKEND_URL } from "../utils/constants";
import HttpClient from "../utils/httpClient";
import { TokenHelpers } from "../utils/tokenHelpers";

interface IAppointmentRepository {
  getAllAppointments(): Promise<FixMeLater[]>;
  addAppointment(appointment: IAppointment): Promise<boolean>;
  editAppointment(appointment: IAppointment): Promise<boolean>;
  deleteAppointment(appointmentId: string): Promise<boolean>;
  getAllAppointmentsByDoctor(doctorId: string): Promise<IAppointment[]>;
  getAllAppointmentsByPatient(patientId: string): Promise<IAppointment[]>;
}

class AppointmentRepository implements IAppointmentRepository {
  private _httpClient: HttpClient;
  private _tokenHelpers: TokenHelpers;

  /**
   *Repository that will handle the request for the doctors
   */
  constructor() {
    this._tokenHelpers = new TokenHelpers();
    this._httpClient = new HttpClient(
      BACKEND_URL,
      this._tokenHelpers.getToken()
    );
  }
  async getAllAppointments(): Promise<FixMeLater[]> {
    const requestUrl = `/api/Appointment/GetByDoctorId/`;
    return await this._httpClient.get<FixMeLater[]>(requestUrl);
  }
  async addAppointment(appointment: IAppointment): Promise<boolean> {
    const requestUrl = `/api/Appointment/UpsertAppointment`;
    const patientBody: IPatientBody = {
      id: appointment.patient.id,
      firstName: appointment.patient.firstName,
      lastName: appointment.patient.lastName,
      // email: appointment.patient.email,
    };

    const doctorBody: IDoctorBody = {
      id: appointment.doctor.id,
      firstName: appointment.doctor.firstName,
      lastName: appointment.doctor.lastName,
      // email: appointment.doctor.email,
    };

    const appointmentBody = {
      id: appointment.id,
      date: appointment.date,
      patient: { ...patientBody },
      doctor: { ...doctorBody },
      patientNote: appointment.patientNote,
      doctorNote: appointment.doctorNote,
      isHonored: appointment.isHonored,
      missedAppointmentReason: appointment.missedAppointmentReason,
      appointmentState: APPOINTMENT_STATUS.UPCOMING,
    };
    return await this._httpClient.post<FixMeLater, boolean>(
      requestUrl,
      {},
      appointmentBody
    );
  }
  async editAppointment(appointment: IAppointment): Promise<boolean> {
    const requestUrl = `/api/Appointment/UpsertAppointment`;
    const patientBody: IPatientBody = {
      id: appointment.patient.id,
      firstName: appointment.patient.firstName,
      lastName: appointment.patient.lastName,
      // email: appointment.patient.email,
    };

    const doctorBody: IDoctorBody = {
      id: appointment.doctor.id,
      firstName: appointment.doctor.firstName,
      lastName: appointment.doctor.lastName,
      // email: appointment.doctor.email,
    };

    const appointmentBody = {
      id: appointment.id,
      date: appointment.date,
      patient: { ...patientBody },
      doctor: { ...doctorBody },
      patientNote: appointment.patientNote,
      doctorNote: appointment.doctorNote,
      isHonored: appointment.isHonored,
      missedAppointmentReason: appointment.missedAppointmentReason,
      appointmentState: appointment.appointmentState,
    };
    return await this._httpClient.post<FixMeLater, boolean>(
      requestUrl,
      {},
      appointmentBody
    );
  }
  async deleteAppointment(appointmentId: string): Promise<boolean> {
    const requestUrl = `/api/Appointment/DeleteAppointment/${appointmentId}`;
    return await this._httpClient.remove<FixMeLater, boolean>(requestUrl);
  }
  async getAllAppointmentsByDoctor(doctorId: string): Promise<IAppointment[]> {
    const requestUrl = `/api/Appointment/GetByDoctorId/${doctorId}`;
    return await this._httpClient.get<IAppointment[]>(requestUrl);
  }

  async getAllAppointmentsByPatient(
    patientId: string
  ): Promise<IAppointment[]> {
    const requestUrl = `/api/Appointment/GetByPatientId/${patientId}`;
    return await this._httpClient.get<IAppointment[]>(requestUrl);
  }
}

export { AppointmentRepository, IAppointmentRepository };
