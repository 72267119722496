import React, { useState } from "react";
import {
  banner_img1,
  banner_img2,
  banner_img3,
  down_arrow_img,
  generalbanner_img,
  header_icon,
} from "../imagepath";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useAuth } from "react-oidc-context";
function Homebanner(props) {
  const [searchName, setSearchName] = useState("");
  const auth = useAuth();

  return (
    <>
      <>
        <section className="banner-section">
          <div className="container">
            <div className="row align-items-center">
              {auth.user?.profile["role"] == "docteur" ? (
                <div className="col-lg-6">
                  <div className="banner-content aos" data-aos="fade-up">
                    <h1>
                      Gérez Facilement{" "}
                      <span>Votre Calendrier et les Dossiers Médicaux</span> de
                      vos patients en toute sécurité
                    </h1>
                    <img
                      src={header_icon}
                      className="header-icon"
                      alt="header-icon"
                    />
                    <p>
                      AlloDoc est une plateforme de mise en rapport entre
                      medicins et patients ainsi que la gestion de dossiers
                      medicaux,
                    </p>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6">
                  <div className="banner-content aos" data-aos="fade-up">
                    <h1>
                      Trouver <span>les</span> meilleurs medecins.
                    </h1>
                    <img
                      src={header_icon}
                      className="header-icon"
                      alt="header-icon"
                    />
                    <p>
                      AlloDoc est une plateforme de mise en rapport entre
                      medicins et patients ainsi que la gestion de dossiers
                      medicaux,
                    </p>
                    {!(auth?.isAuthenticated || auth?.isLoading) && (
                      <Link
                        to="#"
                        className="btn"
                        onClick={() => {
                          void auth.signinRedirect();
                        }}
                      >
                        Se connecter
                      </Link>
                    )}
                    <p>
                      Recherchez et trouvez un medecin pour un rendez-vous des
                      maintenant.
                    </p>
                    {/* <div className="banner-arrow-img">
                  <img src={down_arrow_img} className="img-fluid" alt="" />
                </div> */}
                  </div>
                  <div className="search-box-one aos" data-aos="fade-up">
                    <div className="search-input search-line">
                      <i>
                        <FeatherIcon icon="search" style={{ width: "16px" }} />
                      </i>
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Rechercher un medecin inscrit dans notre plateforme."
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-search-btn">
                      <Link
                        to="doctorsList"
                        state={{
                          search: searchName,
                          profileCompleted: props?.profileCompleted,
                        }}
                        className="btn"
                        type="submit"
                      >
                        Rechercher
                      </Link>
                    </div>
                  </div>
                  {/* <i>
                <i className="feather-map-pin">
                  <i className="feather-crosshair">
                    <i className="feather-calendar">
                      
                    </i>
                  </i>
                </i>
              </i> */}
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default Homebanner;
