import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DoctorSidebar } from "./DoctorSidebar";
import StickyBox from "react-sticky-box";
import Header from "../common/header";
import Footer from "../common/home/footer";
import { IDoctor, IPatient } from "../models/backend";
import { DoctorContext } from "./DoctorContext";
import { useAuth } from "react-oidc-context";

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Input,
} from "@material-ui/core";
import { MdPersonSearch } from "react-icons/md";
import PatientCard from "../patient/PatientCard";
import { PatientService } from "../services/patientService";
import { Button } from "react-bootstrap";

const PatientSearchComponent = (props) => {
  const auth = useAuth();
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>({} as IDoctor);
  const [totalPatientList, setTotalPatientList] = useState<IPatient[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const patientService = new PatientService();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const navigate = useNavigate();

  //   const handlePatientSelect = (patient: IPatient) => {
  //     // setSelectedPatient(patient);
  //     // setModalShow(true); // Reopen modal after patient selection
  //     // Assuming you have a way to send data back to the appointment page
  //     navigate("/calendrier-docteur", { state: { selectedPatient: patient } });
  //   };

  useEffect(() => {
    const fetchPatientData = async () => {
      const id = auth.user?.profile["email"];
      const doctorData = await getDoctorData(id);
      setCurrentDoctor(doctorData);
      const patientList: IPatient[] = await patientService.getAllPatientAsync();
      setTotalPatientList(patientList);
    };
    fetchPatientData();
  }, [getDoctorData]);

  const filteredPatients = useMemo(() => {
    let patientList = totalPatientList || [];

    if (searchInput) {
      patientList = patientList.filter((patient: IPatient) => {
        const patientName =
          `${patient.firstName} ${patient.lastName}`.toLowerCase();

        return patientName.includes(searchInput.toLowerCase());
      });
    }

    return patientList;
  }, [totalPatientList, searchInput]);

  return (
    <div>
      <Header />
      <>
        <div className="breadcrumb-bar-two">
          <div className="container">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">Mes Patients</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Mes Patients
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DoctorSidebar />
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row" style={{ marginBottom: "25px" }}>
                <FormControl>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Rechercher patient
                  </InputLabel>
                  <Input
                    id="outlined-adornment-password"
                    type="text"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    value={searchInput}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => {}} edge="end">
                          <MdPersonSearch size={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="row row-grid">
                {filteredPatients?.map((patient) => {
                  return (
                    <>
                      <PatientCard patient={patient}></PatientCard>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer {...props} />
    </div>
  );
};

export default PatientSearchComponent;
