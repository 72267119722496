import { Table } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { itemRender, onShowSizeChange } from "../paginationfunction";
import { IDoctor } from "../../../models/backend";
import { SyncLoader } from "react-spinners";

const DoctorListDasboard = (props) => {
  const isLoading = props.isLoading;
  const data = props?.doctors.map((d: IDoctor) => {
    return {
      id: d.id,
      DoctorName: `${d.firstName} ${d.lastName}`,
      Speciality: d?.specialities?.map((s) => s.title).join(","),
      Phone: d.phoneNumber,
    };
  });

  const columns = [
    {
      title: "Nom docteur",
      dataIndex: "DoctorName",
      filterSearch: true,
      onFilter: (value, record) => record.DoctorName.includes(value as string),
      render: (text, record) => (
        <>
          <label>{text}</label>
        </>
      ),
      sorter: (a, b) => a.DoctorName.length - b.DoctorName.length,
    },
    {
      title: "Specialites",
      dataIndex: "Speciality",
      sorter: (a, b) => a.Speciality.length - b.Speciality.length,
    },

    {
      title: "Telephone",
      dataIndex: "Phone",
      sorter: (a, b) => a.Earned.length - b.Earned.length,
    },
  ];
  return (
    <>
      <div className="col-md-6 d-flex">
        <div className="card card-table flex-fill">
          <div className="card-header">
            <h4 className="card-title">
              Liste docteurs
              <span style={{ marginLeft: "25px" }}>
                {isLoading && <SyncLoader color="#1b5a90" size={10} />}
              </span>
            </h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <Table
                pagination={{
                  total: data.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DoctorListDasboard;
