import { Link } from "react-router-dom";
import { IAppointment, IDoctor, IRecordType } from "../models/backend";
import { useContext, useEffect, useState } from "react";
import { DoctorContext } from "./DoctorContext";
import { CreateEditMedicalRecordModal } from "./CreateEditMedicalRecordModal";
import { DoctorAppointmentDetails } from "./DoctorAppointmentDetails";
import { ManagementService } from "../services/managementService";
import { useAuth } from "react-oidc-context";
import { default_profile } from "../common/imagepath";
import DisplayAppointmentTimeAndDate from "../common/shared-components/DisplayAppointmentTimeAndDateInTable";
import DisplayAppointmentStatusBadge from "../common/shared-components/DisplayAppointmentStatusBadge";
import { OnSortChange, SortConfig } from "../utils/filterAndSearchUtils";
import SortArrow from "../common/shared-components/SortArrow";

const TodayAppointmentTab = (props: {
  todayAppointmentList: IAppointment[];
  sortConfig: SortConfig;
  onSortChange: OnSortChange;
}) => {
  const auth = useAuth();
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const [selectedAppointment, setSelectedAppointment] = useState(
    {} as IAppointment
  );
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>({} as IDoctor);
  const [recordTypes, setRecordTypes] = useState<IRecordType[]>([]);
  const managementService = new ManagementService();

  const handleSortChange = (key) => {
    const direction =
      props.sortConfig &&
      props.sortConfig.key === key &&
      props.sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    props.onSortChange({ key, direction });
  };

  useEffect(() => {
    const fetchDoctorData = async () => {
      const id = auth.user?.profile["email"];
      const doctorData = await getDoctorData(id);
      setCurrentDoctor(doctorData);
    };
    fetchDoctorData();
    managementService.getAllRecordTypesAsync().then((rt) => {
      setRecordTypes(rt);
    });
  }, [getDoctorData]);

  // Add / edit Medical Record
  const [showAddMedRecordDialog, setShowAddMedRecordDialog] = useState(false);
  const [showEditMedRecordDialog, setShowEditMedRecordDialog] = useState(false);

  const handleCloseAddMedRecordDialog = () => setShowAddMedRecordDialog(false);
  const handleShowAddMedRecordDialog = () => setShowAddMedRecordDialog(true);

  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const handleCloseDetailsDialog = () => setShowDetailsDialog(false);
  const handleShowDetailsDialog = () => setShowDetailsDialog(true);
  const handleOpenAppointmentDetails = (appointmentId: string) => {
    const currentAppointment: IAppointment = props.todayAppointmentList.find(
      (item) => item.id === appointmentId
    );
    setSelectedAppointment(currentAppointment);
    handleShowDetailsDialog();
  };

  const handleAddMedRecordDialogSubmit = async () => {
    const newDoctor: IDoctor = {
      ...currentDoctor,
    };

    setCurrentDoctor(newDoctor);
    await setDoctorData(currentDoctor);

    console.log(newDoctor);
  };
  const handleSetAppointmentRecord = (appointmentId: string) => {
    const currentAppointment: IAppointment = props.todayAppointmentList.find(
      (item) => item.id === appointmentId
    );
    setSelectedAppointment(currentAppointment);
    handleShowAddMedRecordDialog();
  };

  return (
    <>
      {/* Today Appointment Tab */}
      <div className="card card-table mb-0">
        <div className="card-body">
          <div className="table-responsive">
            {props.todayAppointmentList.length > 0 ? (
              <>
                <table className="table table-hover table-center mb-0">
                  <thead style={{ borderBottom: "none" }}>
                    <tr>
                      <th onClick={() => handleSortChange("patient.firstName")}>
                        Nom du patient
                        {props.sortConfig?.key === "patient.firstName" && (
                          <SortArrow direction={props.sortConfig.direction} />
                        )}
                      </th>
                      <th onClick={() => handleSortChange("date")}>
                        Date{" "}
                        {props.sortConfig?.key === "date" && (
                          <SortArrow direction={props.sortConfig.direction} />
                        )}
                      </th>
                      <th onClick={() => handleSortChange("appointmentState")}>
                        Statut RV
                        {props.sortConfig?.key === "appointmentState" && (
                          <SortArrow direction={props.sortConfig?.direction} />
                        )}
                      </th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: "none" }}>
                    <>
                      {props.todayAppointmentList.map((appointment) => (
                        <tr key={appointment.id}>
                          <td>
                            <h2 className="table-avatar">
                              <Link
                                to="/patient-profile"
                                className="avatar avatar-sm me-2"
                                state={appointment.patient}
                              >
                                <img
                                  className="avatar-img rounded-circle"
                                  src={
                                    appointment.patient?.logoPath
                                      ? appointment.patient?.logoPath
                                      : default_profile
                                  }
                                  alt="User Image"
                                />
                              </Link>
                              <Link
                                to="/patient-profile"
                                state={appointment.patient}
                              >
                                {appointment.patient.firstName}{" "}
                                {appointment.patient.lastName}
                                <span>{appointment.patient.id}</span>
                              </Link>
                            </h2>
                          </td>
                          <td>
                            <DisplayAppointmentTimeAndDate
                              appointment={appointment}
                            ></DisplayAppointmentTimeAndDate>
                          </td>
                          <td>
                            <DisplayAppointmentStatusBadge
                              appointment={appointment}
                            ></DisplayAppointmentStatusBadge>
                          </td>
                          <td className="text-end">
                            <div className="table-action text-center">
                              <Link
                                to="#"
                                className="btn btn-sm bg-info-light"
                                onClick={() =>
                                  handleOpenAppointmentDetails(appointment.id)
                                }
                                style={{ marginRight: "5px" }}
                              >
                                <i className="far fa-eye" /> Voir
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-sm bg-success-light"
                                onClick={() =>
                                  handleSetAppointmentRecord(appointment.id)
                                }
                                style={{ marginRight: "5px" }}
                              >
                                <i className="fas fa-check" /> Nouveau Dossier
                              </Link>
                              &nbsp;
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </table>
              </>
            ) : (
              <h3 className="mb-4 text-center"> Aucun Rendez vous</h3>
            )}
          </div>
        </div>
      </div>
      <CreateEditMedicalRecordModal
        showDialog={showAddMedRecordDialog}
        handleClose={handleCloseAddMedRecordDialog}
        patient={selectedAppointment.patient}
        onSubmit={handleAddMedRecordDialogSubmit}
        doctor={currentDoctor}
        recordTypes={recordTypes}
      ></CreateEditMedicalRecordModal>

      <DoctorAppointmentDetails
        show={showDetailsDialog}
        handleClose={handleCloseDetailsDialog}
        appointmentDetails={selectedAppointment}
      ></DoctorAppointmentDetails>
    </>
  );
};

export default TodayAppointmentTab;
