import { Link } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/home/footer";
import StickyBox from "react-sticky-box";
import { DoctorSidebar } from "./DoctorSidebar";
import { IDoctor, ISpeciality } from "../models/backend";
import { DoctorContext } from "./DoctorContext";
import { useState, useContext, useEffect } from "react";
import SpecialitySelector from "./SpecialitySelector";
import { FixMeLater } from "../tsmigration";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { useAuth } from "react-oidc-context";
import { DoctorService } from "../services/doctorService";
import { useNotification } from "../common/NotificationContext";
import { ManagementService } from "../services/managementService";
import { PulseLoader } from "react-spinners";

const DoctorProfileSettings = () => {
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>({} as IDoctor);
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const [specialities, setSpecialities] = useState<ISpeciality[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const auth = useAuth();
  const { showNotification } = useNotification();
  const [logoProfile, setLogo] = useState<string>();
  const [pictureSaving, setPictureSaving] = useState(false);

  const doctorService = new DoctorService();
  const managementService = new ManagementService();

  const handleChange = (e: FixMeLater) => {
    const { name, value } = e.target;
    setCurrentDoctor({
      ...currentDoctor,
      [name]: value,
    });
  };

  const updateSpecialities = (newSpecialities: ISpeciality[]) => {
    setCurrentDoctor((prevDoctor) => ({
      ...prevDoctor,
      specialities: newSpecialities,
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchDoctorData = async () => {
      setIsLoading(true);

      const id = auth.user?.profile["email"] || "";
      const doctorData = await getDoctorData(id);
      setCurrentDoctor(doctorData);
      setIsLoading(false);
    };
    fetchDoctorData();
  }, [getDoctorData]);

  useEffect(() => {
    managementService.getAllSpecialitiesAsync().then((result) => {
      setSpecialities(result);
    });
  });

  const saveProfileSettings = async () => {
    console.log("Save doctor", currentDoctor);
    try {
      const doctorUpdated = await doctorService.saveDoctorAsync(currentDoctor);
      if (doctorUpdated) {
        showNotification(
          "Informations du docteur mises a jour avec succes",
          NOTIFICATION_TYPE.INFO
        );
        await setDoctorData(currentDoctor);
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "Erreur survenue. Merci de reessayer",
        NOTIFICATION_TYPE.ERROR
      );
    }
  };

  return (
    <>
      <Header profileCompleted={currentDoctor?.profileCompleted} />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Informations du profil</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Breadcrumb -->     */}
      {isLoading ? (
        "Loading..."
      ) : (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DoctorSidebar />
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <h4>Photo de profil</h4>
                    <div className="profile-pic-col">
                      <div className="profile-pic-upload">
                        <div className="cam-col">
                          <img
                            src={
                              logoProfile ? logoProfile : currentDoctor.logoPath
                            }
                            id="prof-avatar"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        {pictureSaving ? (
                          <PulseLoader color="#0e82fd" />
                        ) : (
                          <span>
                            Cliquez pour mettre a jour la photo de profil
                          </span>
                        )}

                        <input
                          type="file"
                          id="profile_image"
                          name="profile_image"
                          onChange={(e) => {
                            let file = e.target.files[0] || null;
                            let formFile = new FormData();
                            formFile.append(file?.name, file);
                            setPictureSaving(true);
                            managementService
                              .saveProfileLogoAsync(
                                auth?.user?.profile["email"],
                                auth?.user?.profile["role"] as string,
                                formFile
                              )
                              .then((result) => {
                                // update the logo on the page
                                setLogo(result);
                              })
                              .finally(() => {
                                setPictureSaving(false);
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Informations personnelles</h4>
                    <div className="row form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={currentDoctor.id}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Prénom <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={currentDoctor.firstName}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Nom <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={currentDoctor.lastName}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Téléphone</label>
                          <input
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            value={currentDoctor.phoneNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Biographie sommaire</h4>
                    <div className="form-group mb-0">
                      <label>Biographie</label>
                      <textarea
                        className="form-control"
                        rows={5}
                        // name="email"
                        // value={currentDoctor}
                        // onChange={handleChange}
                      ></textarea>
                    </div>
                    <br></br>
                    <div className="form-group mb-0">
                      <label>Spécialités</label>
                      <SpecialitySelector
                        acceptedSpecialities={specialities}
                        initialSelectedSpecialities={currentDoctor.specialities}
                        onUpdateSpecialities={updateSpecialities}
                      ></SpecialitySelector>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Informations sur la clinique</h4>
                    <div className="row form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nom de la clinique</label>
                          <input
                            type="text"
                            className="form-control"
                            name="clinicName"
                            value={currentDoctor.doctorClinic?.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Adresse de la clinique</label>
                          <input
                            type="text"
                            className="form-control"
                            name="clinicAddress"
                            value={
                              currentDoctor.doctorClinic?.clinicAddress
                                ?.addressLine1
                            }
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Ville</label>
                          <input
                            type="text"
                            className="form-control"
                            name="clinicCity"
                            value={
                              currentDoctor.doctorClinic?.clinicAddress?.city
                            }
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tarif Consultation</label>
                          <input
                            type="text"
                            className="form-control"
                            name="consultationFee"
                            value={currentDoctor.consultationFee}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Durée rendez-vous</label>

                          <select
                            aria-label="Durér Rendez vous"
                            className="form-control"
                            value={currentDoctor.appointmentDuration}
                            name="appointmentDuration"
                            onChange={handleChange}
                          >
                            <option value="30">30 minutes</option>
                            <option value="45">45 minutes</option>
                            <option value="60">1 heure</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-section submit-btn-bottom">
                  <button
                    type="submit"
                    className="btn btn-info submit-btn"
                    onClick={async () => await saveProfileSettings()}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};
export default DoctorProfileSettings;
