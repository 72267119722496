import { Link, useLocation } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/home/footer";
import StickyBox from "react-sticky-box";
import { DoctorSidebar } from "./DoctorSidebar";
import { AppointmentTimeLine, IAppointment, IDoctor } from "../models/backend";
import { DoctorContext } from "./DoctorContext";
import { useState, useContext, useEffect, useMemo } from "react";
import UpcomingAppointmentsTab from "./UpcomingAppointment";
import TodayAppointmentTab from "./TodayAppointments";
import ProgressBar from "react-customizable-progressbar";
import { Icon01, Icon02, Icon03 } from "./img";
import { Box, Tabs, Tab } from "@material-ui/core";
import {
  getAppontmentTimeLine,
  isObjectEmptyOrUndefined,
} from "../utils/objUtils";
import { useAuth } from "react-oidc-context";
import { DoctorService } from "../services/doctorService";
import { CustomTabPanel, a11yProps } from "../common/customTabPanel";
import { DateTime } from "luxon";
import { deriveAppointmentStatus } from "../utils/filterAndSearchUtils";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { MdPersonSearch } from "react-icons/md";

const DoctorDashboard = () => {
  const [activeKey, setActiveKey] = useState("upcoming");
  const [appointmentState, setAppointmentState] = useState<AppointmentTimeLine>(
    {} as AppointmentTimeLine
  );
  const auth = useAuth();
  const { state } = useLocation();
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const doctorService = new DoctorService();
  const profileCompleted = state?.profileCompleted as boolean;
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>(state as IDoctor);
  const [doctorAppointments, setDoctorAppointments] = useState<IAppointment[]>(
    []
  );
  const [doctorSaved, setDoctorSaved] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const loadDoctorData = async (id: string) => {
    const doctorData = await getDoctorData(id);
    if (doctorData) {
      setCurrentDoctor(doctorData);
      setDoctorAppointments(doctorData.appointments);
      const appointmentTimeLine = getAppontmentTimeLine(
        doctorData.appointments
      );
      setAppointmentState(appointmentTimeLine);
    }
  };

  useEffect(() => {
    const fetchDoctorData = async () => {
      const id = auth.user?.profile["email"];
      if (currentDoctor && profileCompleted && !doctorSaved) {
        doctorService
          .saveDoctorAsync(currentDoctor)
          .then((result) => {
            setDoctorSaved(result);
            setDoctorData(currentDoctor);
            console.log("Docteur mis a jour dans le serveur.");
          })
          .catch((err) => console.log(err));
      } else {
        await loadDoctorData(id);
      }
    };
    fetchDoctorData();
  }, [doctorSaved, doctor]);

  const isToday = (date: Date) => {
    const today = new Date();

    return (
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear() &&
      date.getDate() == today.getDate()
    );
  };

  const getValueFromDotNotation = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);

  const handleSortChange = (newSortConfig) => {
    setSortConfig(newSortConfig);
  };

  const sortedAndFilteredAppointments = useMemo(() => {
    let processedAppointments = doctorAppointments || [];

    if (searchTerm) {
      processedAppointments = processedAppointments.filter((appointment) => {
        const patientName =
          `${appointment.patient.firstName} ${appointment.patient.lastName}`.toLowerCase();
        const status = deriveAppointmentStatus(appointment).toLowerCase();
        const date = new Date(appointment.date).toLocaleDateString("fr-FR");

        return (
          patientName.includes(searchTerm.toLowerCase()) ||
          date.includes(searchTerm) ||
          status.includes(searchTerm.toLowerCase())
        );
      });
    }

    if (sortConfig) {
      processedAppointments.sort((a, b) => {
        const aValue =
          sortConfig.key === "appointmentState"
            ? deriveAppointmentStatus(a)
            : getValueFromDotNotation(a, sortConfig.key);
        const bValue =
          sortConfig.key === "appointmentState"
            ? deriveAppointmentStatus(b)
            : getValueFromDotNotation(b, sortConfig.key);

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    const appointmentTimeLine = getAppontmentTimeLine(processedAppointments);
    setAppointmentState(appointmentTimeLine);

    return processedAppointments;
  }, [doctorAppointments, searchTerm, sortConfig]);

  return (
    <>
      <Header profileCompleted={currentDoctor?.profileCompleted} />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Dashboard Medecin</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Rendez-vous
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Breadcrumb -->     */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DoctorSidebar />
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="card dash-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">
                            <ProgressBar
                              // width={8}
                              radius={40}
                              progress={75}
                              rotate={-183}
                              strokeWidth={6}
                              strokeColor="#da3f81"
                              strokeLinecap="square"
                              trackStrokeWidth={8}
                              trackStrokeColor="#e6e6e6"
                              trackStrokeLinecap="square"
                              pointerRadius={0}
                              initialAnimation={true}
                              transition="1.5s ease 0.5s"
                              trackTransition="0s ease"
                            >
                              <div className="indicator-volume">
                                <img
                                  src={Icon01}
                                  className="img-fluid "
                                  alt="Patient"
                                  style={{
                                    position: "relative",
                                    top: "-83px",
                                    left: "45px",
                                  }}
                                />
                              </div>
                            </ProgressBar>
                            <div
                              className="dash-widget-info"
                              style={{ position: "relative", top: "-18px" }}
                            >
                              <h6>Total Patient</h6>
                              <h3>{currentDoctor?.patients?.length}</h3>
                              {/* eslint-disable-next-line react/no-unescaped-entities */}
                              <p className="text-muted">Jusqu'a aujoud'hui</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">
                            <ProgressBar
                              radius={40}
                              progress={65}
                              rotate={-183}
                              strokeWidth={6}
                              strokeColor="#68dda9"
                              strokeLinecap="square"
                              trackStrokeWidth={8}
                              trackStrokeColor="#e6e6e6"
                              trackStrokeLinecap="square"
                              pointerRadius={0}
                              initialAnimation={true}
                              transition="1.5s ease 0.5s"
                              trackTransition="0s ease"
                            >
                              <div className="indicator-volume">
                                <img
                                  src={Icon02}
                                  className="img-fluid"
                                  alt="Patient"
                                  style={{
                                    position: "relative",
                                    top: "-83px",
                                    left: "45px",
                                  }}
                                />
                              </div>
                            </ProgressBar>
                            <div
                              className="dash-widget-info"
                              style={{ position: "relative", top: "-18px" }}
                            >
                              <h6>Patients pour aujourd'hui</h6>
                              <h3>
                                {
                                  currentDoctor?.appointments?.filter((app) =>
                                    isToday(app?.date as Date)
                                  ).length
                                }
                              </h3>
                              <p className="text-muted">
                                {DateTime.fromJSDate(new Date()).toLocaleString(
                                  DateTime.DATE_MED_WITH_WEEKDAY
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget">
                            <ProgressBar
                              radius={40}
                              progress={50}
                              rotate={-183}
                              strokeWidth={6}
                              strokeColor="#1b5a90"
                              strokeLinecap="square"
                              trackStrokeWidth={8}
                              trackStrokeColor="#e6e6e6"
                              trackStrokeLinecap="square"
                              pointerRadius={0}
                              initialAnimation={true}
                              transition="1.5s ease 0.5s"
                              trackTransition="0s ease"
                            >
                              <div className="indicator-volume">
                                <img
                                  src={Icon03}
                                  className="img-fluid"
                                  alt="Patient"
                                  style={{
                                    position: "relative",
                                    top: "-83px",
                                    left: "45px",
                                  }}
                                />
                              </div>
                            </ProgressBar>
                            <div
                              className="dash-widget-info"
                              style={{ position: "relative", top: "-18px" }}
                            >
                              <h6>Rendez-vous</h6>
                              <h3>{currentDoctor?.appointments?.length}</h3>
                              <p className="text-muted">
                                {DateTime.fromJSDate(new Date()).toLocaleString(
                                  DateTime.DATE_MED_WITH_WEEKDAY
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h4 className="mb-4">Rendez-vous Patients</h4>

                  <Box sx={{ width: "100%" }}>
                    <>
                      <div className="row" style={{ marginBottom: "25px" }}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-password">
                            Rechercher RV (par nom, Date, statut )
                          </InputLabel>
                          <Input
                            id="outlined-adornment-password"
                            type="text"
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                            value={searchTerm}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton onClick={() => {}} edge="end">
                                  <MdPersonSearch size={20} />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                      >
                        <Tab label="Aujourd'hui" {...a11yProps(0)} />
                        <Tab label="A venir" {...a11yProps(1)} />
                        <Tab label="Rendez-vous passés" {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    {!isObjectEmptyOrUndefined(appointmentState) && (
                      <>
                        <CustomTabPanel value={value} index={0}>
                          <TodayAppointmentTab
                            todayAppointmentList={appointmentState.today}
                            sortConfig={sortConfig}
                            onSortChange={handleSortChange}
                          ></TodayAppointmentTab>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <UpcomingAppointmentsTab
                            upcomingAppointmentList={appointmentState.upcoming}
                            sortConfig={sortConfig}
                            onSortChange={handleSortChange}
                          ></UpcomingAppointmentsTab>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                          <TodayAppointmentTab
                            todayAppointmentList={appointmentState.past}
                            sortConfig={sortConfig}
                            onSortChange={handleSortChange}
                          ></TodayAppointmentTab>
                        </CustomTabPanel>{" "}
                      </>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DoctorDashboard;
