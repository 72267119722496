import React, {
  createContext,
  useState,
  useCallback,
  ReactNode,
  useEffect,
} from "react";
import { PatientService } from "../services/patientService";
import {
  IAppointment,
  IDossierMedical,
  IPatient,
  IRelatedPatients,
} from "../models/backend";
import { AppointmentService } from "../services/appointmentService";

interface PatientContextType {
  patient: IPatient | null;
  getPatientData: (id: string) => Promise<IPatient | null>;
  setPatientData: (user: IPatient) => Promise<void>;
  patientsInAccount: IRelatedPatients[];
  selectedAccount: IRelatedPatients | null;
  setPatientsInAccount: (patients: IRelatedPatients[]) => void;
  setSelectedAccount: (account: IRelatedPatients) => void;
}

// Create the context with a default value
export const PatientContext = createContext<PatientContextType>({
  patient: null,
  getPatientData: async (id: string) => null,
  setPatientData: async () => {},
  patientsInAccount: [],
  selectedAccount: null,
  setPatientsInAccount: () => {},
  setSelectedAccount: () => {},
});

interface PatientProviderProps {
  children: ReactNode;
}

// Create a provider component
export const PatientUserProvider: React.FC<PatientProviderProps> = ({
  children,
}) => {
  const [patient, setPatient] = useState<IPatient | null>(null);
  const [patientsInAccount, setPatientsInAccount] = useState<
    IRelatedPatients[]
  >([]);
  const [selectedAccount, setSelectedAccount] =
    useState<IRelatedPatients | null>(null);
  const patientService = new PatientService();
  const mainPatientId = localStorage.getItem("mainPatientId");

  // const doctorService = new DoctorService();
  const appointmentService = new AppointmentService();

  useEffect(() => {
    const fetchRelatedPatients = async () => {
      if (mainPatientId) {
        const relatedPatients = await patientService.getRelatedPatients(
          mainPatientId
        );
        const storedSelectedAccount = localStorage.getItem("selectedAccount");
        const parsedSelectedAccount = storedSelectedAccount
          ? (JSON.parse(storedSelectedAccount) as IRelatedPatients)
          : ({} as IRelatedPatients);
        setPatientsInAccount(relatedPatients);
        setSelectedAccount(parsedSelectedAccount);
      }
    };
    fetchRelatedPatients();
  }, [mainPatientId]);

  const loadPatientDataFromBackend = async (
    id: string
  ): Promise<IPatient | null> => {
    let patientData: IPatient;
    let appointmentList: IAppointment[] = [];
    let patientMedicalRecords: IDossierMedical[] = [];

    if (!id) {
      patientData = null;
      throw new Error("Impossible d'identifier le patient. Merci de réessayer");
    } else {
      try {
        await Promise.all([
          (patientData = await patientService.getPatientAsync(id)),
          (appointmentList =
            await appointmentService.getAllAppointmentsByPatient(id)),
          (patientMedicalRecords = await patientService.getMedicalRecords(id)),
        ]);
        if (patientData) {
          patientData.appointments = appointmentList;
          patientData.medicalRecords = patientMedicalRecords;
        }

        return patientData;
      } catch (error) {
        console.error("Erreur:", error);
        throw error;
      }
    }
  };

  // Function to fetch user data from the API and save it to the context
  const getPatientData = useCallback(
    async (id: string): Promise<IPatient | null> => {
      try {
        const storedPatientData = localStorage.getItem("patientData");
        let patientData: IPatient;

        if (storedPatientData) {
          const parsedPatientData = JSON.parse(storedPatientData) as IPatient;
          patientData = { ...parsedPatientData };
        } else {
          patientData =
            (await loadPatientDataFromBackend(id)) || ({} as IPatient);
          // Save to localStorage
          localStorage.setItem("patientData", JSON.stringify(patientData));
        }
        return patientData;
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        return null;
      }
    },
    []
  );

  const getAllRelatedPatientAccounts = useCallback(
    async (parentId: string): Promise<IRelatedPatients[]> => {
      try {
        const relatedPatientList = await patientService.getRelatedPatients(
          parentId
        );
        return relatedPatientList;
      } catch (error) {
        console.error("Failed to fetch related patients:", error);
        return null;
      }
    },
    []
  );

  const setPatientData = async (newData: IPatient) => {
    try {
      const id = newData?.id;
      const patientData = await loadPatientDataFromBackend(id);
      setPatient(patientData);
      localStorage.setItem("patientData", JSON.stringify(patientData));
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      throw error;
    }
  };

  const contextValue = {
    patient,
    getPatientData,
    setPatientData,
    patientsInAccount,
    selectedAccount,
    setPatientsInAccount,
    setSelectedAccount,
  };

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};
