import { IAppointment, IDoctor } from "../models/backend";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import frLocale from "@fullcalendar/core/locales/fr";
import { getCurrentUTCTime, isObjectEmptyOrUndefined } from "../utils/objUtils";
import interaction from "@fullcalendar/interaction";
import "../assets/DiiwdeStyling.css";
import { DoctorContext } from "./DoctorContext";
import { DoctorAppointmentDetails } from "./DoctorAppointmentDetails";
import { CreateEditAppointmentModal } from "./CreateAppointmentModal";
import { createEmptyAppointment } from "../utils/createModels";
import uuid from "react-uuid";
import { AppointmentService } from "../services/appointmentService";
import { useNotification } from "../common/NotificationContext";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { useNavigate } from "react-router-dom";

interface ICalendarProps {
  currentDoctor: IDoctor;
}

const today = new Date();
today.setHours(0, 0, 0, 0);

const getEndRecurrenceDay = () => {
  const sixWeeksLater = new Date(today);
  sixWeeksLater.setDate(today.getDate() + 42);

  return sixWeeksLater;
};

const DoctorScheduleView: React.FC<ICalendarProps> = (props: {
  currentDoctor: IDoctor;
}) => {
  const appointmentService = new AppointmentService();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);

  const calculateAppointmentEndTimeappt = (startTime: Date): Date => {
    const endTime = new Date(startTime);
    endTime.setMinutes(
      endTime.getMinutes() + props.currentDoctor.appointmentDuration,
      0,
      0
    );
    return endTime;
  };

  const formatCalendarEvents = (doctor: IDoctor) => {
    const appointmentEvents = isObjectEmptyOrUndefined(doctor.appointments)
      ? []
      : doctor.appointments.map((appt: IAppointment) => ({
          title: `RV ${appt.patient.firstName}`,
          start: appt.date,
          end: calculateAppointmentEndTimeappt(appt.date),
          extendedProps: {
            type: "appointment",
            patient: appt.patient.firstName,
            appointmentId: appt.id,
          },
        }));

    return appointmentEvents;
  };

  const doctorBusinessHours = isObjectEmptyOrUndefined(
    props.currentDoctor.availabilities
  )
    ? []
    : props.currentDoctor.availabilities.map((avail) => {
        const dayOfWeek = avail.day;
        if (dayOfWeek !== undefined) {
          const startTime = avail.startTime;
          const endTime = avail.endTime;

          return {
            daysOfWeek: [dayOfWeek],
            startTime: startTime,
            endTime: endTime,
          };
        }
      });

  function getMinMaxTimes(businessHours) {
    let minStartTime = "23:59";
    let maxEndTime = "00:00";

    businessHours.forEach((hour) => {
      if (hour.startTime < minStartTime) {
        minStartTime = hour.startTime;
      }
      if (hour.endTime > maxEndTime) {
        maxEndTime = hour.endTime;
      }
    });

    return { minStartTime, maxEndTime };
  }

  const { minStartTime, maxEndTime } = getMinMaxTimes(doctorBusinessHours);

  const formatSlotDuration = (duration: number) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00`;
  };

  const handleDateClick = (arg) => {
    arg.view.calendar.changeView("timeGridDay", arg.dateStr);
  };

  const calendarSlotDuration = formatSlotDuration(
    props.currentDoctor.appointmentDuration > 0
      ? props.currentDoctor.appointmentDuration
      : 30
  );

  const hasFreeSlotsForDay = (day: Date): boolean => {
    const startTime = new Date(day);
    const endTime = new Date(day);
    startTime.setHours(0, 0, 0, 0);
    endTime.setHours(23, 59, 59, 999);

    // RV du jour
    const eventsForDay = events.filter(
      (event) =>
        new Date(event.start) >= startTime && new Date(event.end) <= endTime
    );

    // horaires du jour
    const dayOfWeek = day.getDay();
    const businessHoursForDay = doctorBusinessHours.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );

    if (!businessHoursForDay) {
      return false;
    }

    const nbAcceptableAppointmentsInDay = calculateMaxAppointmentsPerDay(
      businessHoursForDay.startTime,
      businessHoursForDay.endTime,
      props.currentDoctor.appointmentDuration
    );
    const availableSlots = nbAcceptableAppointmentsInDay - eventsForDay.length;

    return availableSlots > 0;
  };

  const calculateMaxAppointmentsPerDay = (
    startTime: string,
    endTime: string,
    appointmentDuration: number
  ): number => {
    const startHourMinutes = startTime.split(":").map(Number);
    const endHourMinutes = endTime.split(":").map(Number);

    const startMinutes = startHourMinutes[0] * 60 + startHourMinutes[1];
    const endMinutes = endHourMinutes[0] * 60 + endHourMinutes[1];

    // tempos en minutes de la disponibilite
    const totalDuration = endMinutes - startMinutes;

    // Nb max RV par jour
    const maxAppointmentsPerDay = Math.floor(
      totalDuration / appointmentDuration
    );

    return maxAppointmentsPerDay;
  };

  const renderDayCellContent = (arg) => {
    const day = arg.date;
    const isFreeSlotsAvailable = hasFreeSlotsForDay(day);

    const text = isFreeSlotsAvailable
      ? "Rendez-vous disponible"
      : "Aucune disponibilité";

    if (arg?.view?.type === "dayGridMonth")
      return (
        <div className="text-end align-text-bottom">
          {" "}
          {arg.dayNumberText} <br></br> {text}
        </div>
      );
    else return null;
  };

  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointment | null>(null);

  const [appointmentToCreate, setAppointmentToCreate] =
    useState<IAppointment | null>(null);

  const [showAppointmentDetailsDialog, setShowAppointmentDetailsDialog] =
    useState(false);
  const [showCreateAppointmentDialog, setshowCreateAppointmentDialog] =
    useState(false);
  const handleCloseDetailsDialog = () => setShowAppointmentDetailsDialog(false);
  const handleShowDetailsDialog = () => setShowAppointmentDetailsDialog(true);

  const handleCloseCreateAppointmentDialog = () => {
    resetSelectedSlotInformation();
    setshowCreateAppointmentDialog(false);
  };
  const [selectedEvent, setSelectedEvent] = useState(null);

  const findAppointmentById = (
    appointmentId: string,
    appointmentList: IAppointment[]
  ): IAppointment => {
    if (!appointmentList || appointmentList.length === 0 || !appointmentId) {
      return null;
    }
    const currentAppointment = appointmentList.find((appointment) => {
      return appointment.id == appointmentId;
    });
    return currentAppointment;
  };

  const handleShowCreateAppointmentDialog = () => {
    setshowCreateAppointmentDialog(true);
  };

  const resetSelectedSlotInformation = () => {
    setAppointmentToCreate(null);
    setSelectedAppointment(null);
    setSelectedEvent(null);
  };

  const handleCreateAppointmentDialogSubmit = async (
    newAppointment: IAppointment
  ) => {
    console.log("Create Appointment", newAppointment);
    try {
      const appointmentCreated = await appointmentService.addAppointment(
        newAppointment
      );
      const updatedDoctor = {
        ...props.currentDoctor,
        appointments: [...props.currentDoctor.appointments, newAppointment],
      };
      await setDoctorData(updatedDoctor);
      if (appointmentCreated) {
        showNotification(
          "Rendez-vous créé avec succès",
          NOTIFICATION_TYPE.INFO
        );
        navigate("/dashboard-docteur");
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "Erreur survenue pendant la création du Rendez-vous",
        NOTIFICATION_TYPE.ERROR
      );
    }
  };

  const datesAreEqual = (date1, date2) => {
    const areDatesEqual =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    const areTimesEqual =
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes();

    return areDatesEqual && areTimesEqual;
  };

  const handleSlotSelect = (info) => {
    const date = info.start;
    if (!isSlotInBusinessHour(date)) {
      console.log("Slot out of availability range");
    } else {
      const overlappingAppointment = checkIfAppointmentInSlot(info);

      if (!overlappingAppointment) {
        const newAppointmentDateTime = new Date(date);
        const now = getCurrentUTCTime();
        if (newAppointmentDateTime < now) {
          let calendarApi = info.view.calendar;
          calendarApi.unselect();
          showNotification(
            "Ce créneau est passé. Merci de prendre un Rendez-vous plus tard",
            NOTIFICATION_TYPE.ERROR
          );
          return;
        }
        const newAppointment: IAppointment = createEmptyAppointment();
        newAppointment.date = newAppointmentDateTime;
        newAppointment.id = uuid();

        setAppointmentToCreate(newAppointment);
        handleShowCreateAppointmentDialog();
      } else {
        console.log("Found appointment, ", overlappingAppointment);
        setSelectedAppointment(overlappingAppointment);
        setAppointmentToCreate(null);
        handleShowDetailsDialog();
      }
    }
  };

  const handleEventClick = (clickInfo) => {
    const appointmentId = clickInfo.event?.extendedProps
      ?.appointmentId as string;
    const correspondingAppointment: IAppointment = findAppointmentById(
      appointmentId,
      props.currentDoctor.appointments
    );
    setSelectedAppointment(correspondingAppointment);
    setAppointmentToCreate(null);
    handleShowDetailsDialog();
  };

  const renderEventContent = (arg) => {
    const { timeText, event } = arg;
    if (arg.view.type === "timeGridWeek") {
      return (
        <div className="fc-content">
          <div className="fc-title">{event.title}</div>
        </div>
      );
    } else {
      return (
        <div className="fc-content">
          <div className="fc-title">
            {timeText}&nbsp;&nbsp;&nbsp;
            {event.title}
          </div>
        </div>
      );
    }
  };
  const checkIfAppointmentInSlot = (slot) => {
    return props.currentDoctor.appointments.find((appointment) => {
      return datesAreEqual(slot.start, appointment.date);
    });
  };

  const isSlotInBusinessHour = (date: Date): boolean => {
    const dayOfWeek = date.getDay();
    const businessHoursForDay = doctorBusinessHours.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );

    if (!businessHoursForDay) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setEvents(formatCalendarEvents(props.currentDoctor));
  }, [JSON.stringify(props.currentDoctor)]);

  const [events, setEvents] = useState([]);

  return (
    <>
      <div className="col-md-7 col-lg-8 col-xl-9">
        <Container className="md-9">
          <h3 className="text-center">
            Calendrier Dr. {props.currentDoctor.firstName}{" "}
            {props.currentDoctor.lastName}
          </h3>
          <FullCalendar
            height="auto"
            contentHeight="auto"
            plugins={[
              interaction,
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
            ]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            events={events}
            selectable={true}
            locale={frLocale}
            slotDuration={calendarSlotDuration}
            navLinks={true}
            slotMinTime={minStartTime}
            slotMaxTime={maxEndTime}
            allDaySlot={false}
            timeZone="UTC" // Setting timezone to UTC
            businessHours={doctorBusinessHours}
            selectConstraint={doctorBusinessHours}
            validRange={{
              start: getCurrentUTCTime(),
              end: getEndRecurrenceDay(),
            }}
            select={handleSlotSelect}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            dayCellContent={renderDayCellContent}
            eventContent={renderEventContent}
          />
          {selectedAppointment && (
            <>
              <DoctorAppointmentDetails
                show={showAppointmentDetailsDialog}
                handleClose={handleCloseDetailsDialog}
                appointmentDetails={selectedAppointment}
              ></DoctorAppointmentDetails>
            </>
          )}
          {appointmentToCreate && (
            <>
              <CreateEditAppointmentModal
                show={showCreateAppointmentDialog}
                handleClose={handleCloseCreateAppointmentDialog}
                appointment={appointmentToCreate}
                dialogTitle={"Ajout de Rendez vous"}
                onSubmit={async (newAppointment: IAppointment) =>
                  await handleCreateAppointmentDialogSubmit(newAppointment)
                }
                doctor={props.currentDoctor}
              ></CreateEditAppointmentModal>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default DoctorScheduleView;
