import React, { useEffect, useState } from "react";
import SidebarNav from "../sidebar";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DoctorListDasboard from "./doctorList";
import PatientsListDasboard from "./patientsList";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/custom.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/font-awesome.min.css";
import "../../assets/css/feathericon.min.css";
import Header from "../header";
import { useAuth } from "react-oidc-context";
import { IDoctor, IPatient } from "../../../models/backend";
import { DoctorService } from "../../../services/doctorService";
import { PatientService } from "../../../services/patientService";
import LineChart from "./lineChart";
import StatusChart from "./statusCharts";
import { SyncLoader } from "react-spinners";

const Dashboard = (props) => {
  const auth = useAuth();
  const [doctors, setDoctors] = useState<IDoctor[]>([]);
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const doctorService = new DoctorService();
  const patientService = new PatientService();

  useEffect(() => {
    // function that gets all the dashboard data in parallel
    async function fetchData() {
      setIsLoading(true);
      try {
        let [docs, pats] = await Promise.all([
          doctorService.getAllDoctorAsync(),
          patientService.getAllPatientAsync(),
        ]).finally(() => {
          setIsLoading(false);
        });

        // set the values
        setDoctors(docs ? docs : []);
        setPatients(pats ? pats : []);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();

    // TODO: get all the appointments
  }, []);

  return (
    <>
      <Header {...props} onMenuClick={() => {}} />
      <div className="main-wrapper">
        <SidebarNav />
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content container-fluid pb-0">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">
                    Welcome {auth.user?.profile["given_name"]} !
                  </h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-primary border-primary">
                        <i className="fe fe-users" />
                      </span>
                      <div className="dash-count">
                        <h3>{doctors?.length}</h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">Doctors</h6>
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-primary w-50" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon text-success">
                          <i className="fe fe-credit-card" />
                        </span>
                        <div className="dash-count">
                          <h3>
                            {isLoading ? (
                              <SyncLoader color="#1b5a90" size={9} />
                            ) : (
                              patients?.length
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="dash-widget-info">
                        <h6 className="text-muted">Patients</h6>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-success w-50" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon text-danger border-danger">
                          <i className="fe fe-money" />
                        </span>
                        <div className="dash-count">
                          <h3>485</h3>
                        </div>
                      </div>
                      <div className="dash-widget-info">
                        <h6 className="text-muted">Appointment</h6>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-danger w-50" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon text-warning border-warning">
                          <i className="fe fe-folder" />
                        </span>
                        <div className="dash-count">
                          <h3>$62523</h3>
                        </div>
                      </div>
                      <div className="dash-widget-info">
                        <h6 className="text-muted">Revenue</h6>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-warning w-50" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-6">
                {/* Sales Chart */}
                <div className="card card-chart">
                  <div className="card-header">
                    <h4 className="card-title">Revenue</h4>
                  </div>
                  <div className="card-body">
                    {/* <div id="morrisArea" /> */}
                    <LineChart />
                  </div>
                </div>
                {/* /Sales Chart */}
              </div>
              <div className="col-md-12 col-lg-6">
                {/* Invoice Chart */}
                <div className="card card-chart">
                  <div className="card-header">
                    <h4 className="card-title">Status</h4>
                  </div>
                  <div className="card-body">
                    <div id="morrisLine" />
                    <StatusChart />
                  </div>
                </div>
                {/* /Invoice Chart */}
              </div>
            </div>

            <div className="row">
              <DoctorListDasboard doctors={doctors} isLoading={isLoading} />
              <PatientsListDasboard patients={patients} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
