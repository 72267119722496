import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { DoctorSidebar } from "./DoctorSidebar";
import StickyBox from "react-sticky-box";
import Header from "../common/header";
import Footer from "../common/home/footer";
import { IDoctor, IPatient } from "../models/backend";
import { DoctorContext } from "./DoctorContext";
import { useAuth } from "react-oidc-context";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Input,
} from "@material-ui/core";
import { MdPersonSearch } from "react-icons/md";
import PatientCard from "../patient/PatientCard";

const MyPatient = (props) => {
  const auth = useAuth();
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>({} as IDoctor);
  const [searchPatient, setSearchPatient] = useState<IPatient[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);

  useEffect(() => {
    const fetchPatientData = async () => {
      const id = auth.user?.profile["email"];
      const doctorData = await getDoctorData(id);
      setCurrentDoctor(doctorData);
      setSearchPatient(doctorData?.patients);
    };
    fetchPatientData();
  }, [getDoctorData]);

  const filteredPatients = useMemo(() => {
    let patientList = searchPatient || [];

    if (searchInput) {
      patientList = patientList.filter((patient: IPatient) => {
        const patientName =
          `${patient.firstName} ${patient.lastName}`.toLowerCase();

        return patientName.includes(searchInput.toLowerCase());
      });
    }

    return patientList;
  }, [searchPatient, searchInput]);

  return (
    <div>
      <Header />
      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar-two">
          <div className="container">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">Mes Patients</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Mes Patients
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
      </>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DoctorSidebar />
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row" style={{ marginBottom: "25px" }}>
                <FormControl>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Rechercher patient
                  </InputLabel>
                  <Input
                    id="outlined-adornment-password"
                    type="text"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    value={searchInput}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSearchPatient(
                              currentDoctor?.patients?.filter(
                                (p: IPatient) =>
                                  p.firstName
                                    .toLowerCase()
                                    .includes(searchInput.toLowerCase()) ||
                                  p.lastName
                                    .toLowerCase()
                                    .includes(searchInput.toLowerCase())
                              )
                            );
                          }}
                          edge="end"
                        >
                          <MdPersonSearch size={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="row row-grid">
                {filteredPatients?.map((patient) => {
                  return <PatientCard patient={patient}></PatientCard>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer {...props} />
    </div>
  );
};

export default MyPatient;
