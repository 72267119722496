import React, { useState } from "react";
// import loginBanner from '../../assets/images/login-banner.png';
import camera from "../../assets/images/icons/camera.svg";
import { Link } from "react-router-dom";
import logo from "../../assets/images/allodoc.png";
import { useAuth } from "react-oidc-context";
import { ManagementService } from "../../services/managementService";

const Patientregisterstepone = () => {
  // TODO: get the user from the data base and display it's logo
  const [logoProfile, setLogo] = useState<string>();
  const auth = useAuth();
  const managementService = new ManagementService();

  return (
    <>
      <>
        {/* Page Content */}
        <div className="content login-page pt-0">
          <div className="container-fluid">
            {/* Register Content */}
            <div className="account-content">
              <div className="row align-items-center">
                <div className="login-right">
                  <div className="inner-right-login">
                    <div className="login-header">
                      <div className="logo-icon">
                        <img src={logo} alt="" />
                      </div>
                      <div className="step-list">
                        <ul>
                          <li>
                            <Link to="#" className="active">
                              1
                            </Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">3</Link>
                          </li>
                        </ul>
                      </div>
                      <form id="profile_pic_form" encType="multipart/form-data">
                        <div className="profile-pic-col">
                          <h3>Profile Picture</h3>
                          <div className="profile-pic-upload">
                            <div className="cam-col">
                              <img
                                src={logoProfile ?? camera}
                                id="prof-avatar"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <span>Mettre a jour votre photo de profile</span>
                            <input
                              type="file"
                              id="profile_image"
                              name="profile_image"
                              onChange={(e) => {
                                let file = e.target.files[0] || null;
                                let formFile = new FormData();
                                formFile.append(file?.name, file);
                                managementService
                                  .saveProfileLogoAsync(
                                    auth?.user?.profile["email"],
                                    auth?.user?.profile["role"] as string,
                                    formFile
                                  )
                                  .then((result) => {
                                    // update the logo on the page
                                    setLogo(result);
                                  });
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-5">
                          <Link
                            to="/patientregisterstep-2"
                            className="btn btn-primary w-100 btn-lg login-btn step1_submit"
                            state={{
                              logoPath: logoProfile,
                            }}
                          >
                            continue{" "}
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="login-bottom-copyright">
                    <span>© 2024 Allo-Doc. Tous droits réservés.</span>
                  </div>
                </div>
              </div>
            </div>
            {/* /Register Content */}
          </div>
        </div>
        {/* /Page Content */}
      </>
    </>
  );
};

export default Patientregisterstepone;
