import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import StickyBox from "react-sticky-box";
import Header from "../common/header";
import Footer from "../common/home/footer";
import "react-datepicker/dist/react-datepicker.css";
import PatientSidebar from "./PatientSidebar";
import {
  IDoctor,
  IDossierMedical,
  IPatient,
  IRecordType,
} from "../models/backend";
import MedicalRecordsList from "./MedicalRecordsList";
import { isObjectEmptyOrUndefined } from "../utils/objUtils";
import { PulseLoader } from "react-spinners";
import { DoctorContext } from "./DoctorContext";
import { useAuth } from "react-oidc-context";
import { PatientService } from "../services/patientService";
import { ManagementService } from "../services/managementService";
import { CreateEditMedicalRecordModal } from "./CreateEditMedicalRecordModal";

const PatientProfile = (props) => {
  const auth = useAuth();
  const { state } = useLocation();
  const patientService = new PatientService();
  const managementService = new ManagementService();

  const [currentPatient, setCurrentPatient] = useState<IPatient>(state);

  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>({} as IDoctor);
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const [recordTypes, setRecordTypes] = useState<IRecordType[]>([]);

  const [show, setShow] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const [patientId, setPatientId] = useState(currentPatient?.id);
  const [isLoading, setIsLoading] = useState(false);
  const [medicalRecords, setMedicalRecords] = useState<IDossierMedical[]>();

  const handleCloseAddMedRecordDialog = () => setShow(false);

  const fetchMedicalRecords = () => {
    setIsLoading(true);
    patientService.getMedicalRecords(patientId).then((result) => {
      setMedicalRecords(result);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchMedicalRecords();
    managementService.getAllRecordTypesAsync().then((rt) => {
      setRecordTypes(rt);
    });
  }, []);

  useEffect(() => {
    const fetchPatientData = async () => {
      const id = auth.user?.profile["email"];
      const doctorData = await getDoctorData(id);
      setCurrentDoctor(doctorData);
    };
    fetchPatientData();
  }, [getDoctorData]);

  return (
    <div>
      <Header {...props} />
      {/* Breadcrumb */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Profile</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-docteur">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Profile de{" "}
                    {`${currentPatient?.firstName} ${currentPatient?.lastName}`}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <PatientSidebar patient={currentPatient} />
              </StickyBox>
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mb-4">Dossier medical</h4>
                      <div className="text-end">
                        <Link
                          to="#"
                          onClick={() => setShow(true)}
                          className="btn btn-primary"
                        >
                          Nouveau dossier
                        </Link>
                      </div>
                      {isLoading ? (
                        <div style={{ margin: "auto", width: "10%" }}>
                          <PulseLoader color="#0e82fd" />
                        </div>
                      ) : (
                        !isObjectEmptyOrUndefined(medicalRecords) && (
                          <>
                            <MedicalRecordsList
                              activePage={activePage}
                              onPageChange={handlePageChange}
                              records={medicalRecords}
                              isCurrentUserOwner={true}
                              medicalRecords={medicalRecords}
                            />
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer {...props} />

      <CreateEditMedicalRecordModal
        showDialog={show}
        handleClose={handleCloseAddMedRecordDialog}
        patient={currentPatient}
        onSubmit={fetchMedicalRecords}
        doctor={currentDoctor}
        recordTypes={recordTypes}
      ></CreateEditMedicalRecordModal>
    </div>
  );
};

export default PatientProfile;
