import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IAppointment, IDoctor, IPatient } from "../models/backend";
import { isObjectEmptyOrUndefined } from "../utils/objUtils";
import { FixMeLater } from "../tsmigration";
import { createEmptyAppointment } from "../utils/createModels";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchPatientModal } from "./SearchPatientModal";

export const CreateEditAppointmentModal = ({
  show,
  handleClose,
  appointment,
  dialogTitle,
  doctor,
  onSubmit,
}: {
  show: boolean;
  handleClose: () => void;
  appointment: IAppointment;
  dialogTitle: string;
  doctor: IDoctor;
  onSubmit: (data: IAppointment) => void;
}) => {
  const emptyAppointment: IAppointment = createEmptyAppointment();
  const navigate = useNavigate();
  const location = useLocation();

  const initialState = isObjectEmptyOrUndefined(appointment)
    ? emptyAppointment
    : { ...appointment };

  const [appointmentData, setAppointmentData] = useState<IAppointment>({
    ...initialState,
  });

  const [patientList, setPatientList] = useState<IPatient[]>(doctor.patients);

  const [showSearchModal, setShowSearchModal] = useState(false);

  useEffect(() => {}, [patientList]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let newAppointment: IAppointment = { ...appointmentData };
    newAppointment.doctor = { ...doctor };
    console.log("Submitting form data with appointment:", newAppointment);
    await onSubmit(newAppointment);
    setAppointmentData(newAppointment);
    handleClose();
  };

  const findPatientById = (patientId: string) => {
    // const emptyPatient = {} as IPatient;
    if (!doctor?.patients || doctor.patients.length === 0) {
      return null;
    }
    const currentPatient = patientList.find((patient) => {
      return patient.id == patientId;
    });
    return currentPatient;
  };

  const handleSelectPatient = (selectedPatient: IPatient) => {
    setAppointmentData((prev) => ({
      ...prev,
      patient: selectedPatient,
    }));
    // Check if the selected patient is already in the doctor's patient list. If not, add this patient to the doctor's patient list
    const patientExists = patientList.some((p) => p.id === selectedPatient.id);
    if (!patientExists) {
      const updatedPatients = [...doctor.patients, selectedPatient];
      setPatientList(updatedPatients);
    }

    setShowSearchModal(false);
  };

  const handleChange = (e: FixMeLater) => {
    const { name, value } = e.target;
    if (name === "patient") {
      const thePatient: IPatient = findPatientById(value);
      setAppointmentData({
        ...appointmentData,
        patient: JSON.parse(JSON.stringify(thePatient)),
      });
      // if (value === "searchPatient") {
      //   setShowSearchModal(true);
      // } else {
      //   const thePatient: IPatient = findPatientById(value);
      //   setAppointmentData({
      //     ...appointmentData,
      //     patient: JSON.parse(JSON.stringify(thePatient)),
      //   });
      // }
    } else {
      setAppointmentData({
        ...appointmentData,
        [name]: value,
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal"
        style={{ marginTop: "80px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{dialogTitle}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {appointmentData && (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="patientSelect">Patient</label>
                  <select
                    id="patientSelect"
                    name="patient"
                    className="form-control"
                    value={appointmentData.patient?.id || ""}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choisir un patient</option>
                    {patientList.map((patient, index) => (
                      <option key={index} value={patient.id}>
                        {patient.firstName} {patient.lastName} ({patient.id})
                      </option>
                    ))}
                    {/* <option value="searchPatient">Chercher...</option> */}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="appointmentDate">Date et heure</label>
                  <input
                    type="text"
                    id="appointmentDate"
                    className="form-control"
                    readOnly
                    value={
                      appointmentData.date
                        ? new Date(appointmentData.date).toLocaleString(
                            "fr-FR",
                            { timeZone: "UTC" }
                          )
                        : ""
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="title" className="col-sm-2 col-form-label">
                    Notes:
                  </label>
                  <textarea
                    className="form-control"
                    name="doctorNote"
                    value={appointmentData.doctorNote}
                    onChange={handleChange}
                    required
                    placeholder="Notes et observations"
                    rows={5}
                    style={{ overflowY: "hidden" }}
                    onInput={(e: FixMeLater) => {
                      e.target.style.height = "auto";
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                  />
                </div>
                <div className="modal-footer text-center">
                  <button type="submit" className="btn btn-primary submit-btn">
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleClose}
                  >
                    Annuler
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {showSearchModal && (
        <SearchPatientModal
          show={showSearchModal}
          handleClose={() => setShowSearchModal(false)}
          onSubmit={handleSelectPatient}
        />
      )}
    </>
  );
};
