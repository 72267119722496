import { IAppointment } from "../../models/backend";

const DisplayAppointmentTimeAndDate = (props: {
  appointment: IAppointment;
}) => {
  const utcDate = new Date(props.appointment.date.getTime());
  const formattedDate = utcDate.toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  });

  const formattedTime = utcDate.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  return (
    <div>
      {formattedDate}
      <span className="d-block text-info">{formattedTime}</span>
    </div>
  );
};

export default DisplayAppointmentTimeAndDate;
