import React, { createContext, useContext, useState } from "react";
import NotificationComponent from "./NotificationComponent";

interface Notification {
  message: string;
  notificationType: string;
}

interface NotificationContextType {
  showNotification: (message: string, notificationType: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
export const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const showNotification = (message: string, notificationType: string) => {
    setNotification({ message, notificationType });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {notification && (
        <NotificationComponent
          message={notification.message}
          notificationType={notification.notificationType}
        />
      )}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
