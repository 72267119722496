/* eslint-disable prefer-const */
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { IAppointment, IPatient } from "../models/backend";
import { createEmptyAppointment } from "../utils/createModels";
import { useLocation, useNavigate } from "react-router-dom";
import PatientCard from "../patient/PatientCard";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Input,
} from "@material-ui/core";
import { MdPersonSearch } from "react-icons/md";
import { PatientService } from "../services/patientService";
import "../assets/DiiwdeStyling.css";

export const SearchPatientModal = ({
  show,
  handleClose,
  onSubmit,
}: {
  show: boolean;
  handleClose: () => void;
  onSubmit: (selectedPatient: IPatient) => void;
}) => {
  const [totalPatientList, setTotalPatientList] = useState<IPatient[]>([]);
  const patientService = new PatientService();

  useEffect(() => {
    const fetchPatientList = async () => {
      const patientList: IPatient[] = await patientService.getAllPatientAsync();
      setTotalPatientList(patientList);
    };
    fetchPatientList();
  }, []);

  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const filteredPatients = useMemo(() => {
    let patientList = totalPatientList || [];

    if (searchInput) {
      patientList = patientList.filter((patient: IPatient) => {
        const patientName =
          `${patient.firstName} ${patient.lastName}`.toLowerCase();

        return patientName.includes(searchInput.toLowerCase());
      });
    }

    return patientList;
  }, [totalPatientList, searchInput]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal"
        size="xl"
        style={{ marginTop: "80px", background: "white" }}
      >
        <Modal.Header
          closeButton
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1050,
            background: "white",
          }}
        >
          <Modal.Title>
            <h5 className="modal-title">Recherche patient</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <div className="modal-body">
            <div className="content">
              <div className="container-fluid">
                <div className="row" style={{ marginBottom: "25px" }}>
                  <FormControl>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Rechercher patient
                    </InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type="text"
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                      value={searchInput}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={() => {}} edge="end">
                            <MdPersonSearch size={20} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div className="row row-grid">
                  {filteredPatients?.map((patient) => {
                    return (
                      <>
                        <PatientCard
                          patient={patient}
                          showSelectButton={true}
                          onSelectPatient={onSubmit}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            position: "sticky",
            bottom: 0,
            zIndex: 1050,
            background: "white",
          }}
        >
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleClose}
          >
            Annuler
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
