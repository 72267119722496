import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import Header from "../common/header";
import DashboardSidebar from "./sidebar";
import Footer from "../common/home/footer";
import { useContext, useEffect, useState } from "react";
import "aos/dist/aos.css";
import { AddEditPatientDependantModal } from "./PatientDependant/AddPatientDependantModal";
import { v4 as uuid } from "uuid";
import { PatientContext } from "./PatientContext";
import { displayDay, isObjectEmptyOrUndefined } from "../utils/objUtils";
import { useAuth } from "react-oidc-context";
import { IPatient } from "../models/backend";
import { PatientService } from "../services/patientService";
import { useNotification } from "../common/NotificationContext";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { default_profile } from "../common/imagepath";
import { FixMeLater } from "../tsmigration";
import ConfirmationDialog from "../common/shared-components/ConfirmationDialog";

const PatientDependent = () => {
  const [dependants, setDependants] = useState<IPatient[]>([]);
  const { showNotification } = useNotification();

  const auth = useAuth();
  const patientService = new PatientService();

  const { patient, getPatientData, setPatientData, selectedAccount } =
    useContext(PatientContext);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPatient, setCurrentPatient] = useState<IPatient>(
    {} as IPatient
  );
  useEffect(() => {
    const fetchPatientData = async () => {
      const id = auth.user?.profile["email"] || "";
      const patientData = await getPatientData(id);
      setCurrentPatient(patientData);
      const patientDependants: IPatient[] = patientData?.dependents || [];
      setDependants(patientDependants);

      setIsLoading(false);
    };
    fetchPatientData();
  }, [getPatientData, JSON.stringify(currentPatient)]);

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedDependant, setSelectedDependant] = useState({} as IPatient);

  // Add dependant dialog
  const handleCloseAddDialog = () => setShowAddDialog(false);
  const handleShowAddDialog = () => setShowAddDialog(true);
  const handleAddDependantFormSubmit = async (newDependant: IPatient) => {
    newDependant.id = uuid();
    const parentId: string = currentPatient.id;

    try {
      const dependantAdded = await patientService.addDependantToPatient(
        parentId,
        newDependant
      );
      await setPatientData(currentPatient);
      const initialDependantList: IPatient[] = dependants || [];

      const newDependantList: IPatient[] = [
        ...initialDependantList,
        newDependant,
      ];
      setDependants(newDependantList);
      if (dependantAdded) {
        showNotification(
          "Patient à charge ajouté avec succès",
          NOTIFICATION_TYPE.INFO
        );
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "Erreur survenue. Merci de reessayer",
        NOTIFICATION_TYPE.ERROR
      );
    }
  };

  // Edit dependant dialog
  const handleCloseEditDialog = () => setShowEditDialog(false);
  const handleShowEditDialog = () => setShowEditDialog(true);
  const handleEditDependantFormSubmit = (updatedDependant: IPatient) => {
    const newDependantList = dependants.map((dep) => {
      if (dep.id === updatedDependant.id) {
        return updatedDependant;
      }
      return dep;
    });
    setDependants(newDependantList);
  };

  const handleOpenPatientDetails = (patientId: string) => {
    const currentPatient: IPatient = dependants.find(
      (item) => item.id === patientId
    );
    setSelectedDependant(currentPatient);
    handleShowEditDialog();
  };

  // delete patientDependant
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dependentToDelete, setDependentToDelete] = useState<IPatient | null>(
    null
  );

  const handleRemoveDependent = (dependant: IPatient) => {
    setDependentToDelete(dependant);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (dependentToDelete) {
        const dependantId: string = dependentToDelete.id as string;
        const deleted = await patientService.removeDependant(
          currentPatient.id,
          dependantId
        );
        if (deleted) {
          const newDependentList = patient.dependents.filter(
            (dep) => dep.id === dependantId
          );
          const updatedDoctor: IPatient = {
            ...patient,
            dependents: [...newDependentList],
          };
          setDependants(newDependentList);
          await setPatientData(updatedDoctor);

          showNotification(
            "Personne à charge supprimé avec succès",
            NOTIFICATION_TYPE.INFO
          );
        } else {
          showNotification(
            "Erreur lors de la suppression de la personne à charge. Réessayez",
            NOTIFICATION_TYPE.ERROR
          );
        }
        setDeleteDialogOpen(false);
        setDependentToDelete(null);
      }
    } catch (error: FixMeLater) {
      console.error(error);
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        "Erreur lors de la suppression de la personne à charge ";

      showNotification(errorMessage, NOTIFICATION_TYPE.ERROR);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDependentToDelete(null);
  };

  return (
    <>
      <Header profileCompleted={patient?.profileCompleted} />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Personnes à charge</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Personnes à charge
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Breadcrumb -->     */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar />
              </StickyBox>
            </div>{" "}
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h3 className="card-title">Personnes à charge</h3>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-end">
                        <button
                          onClick={handleShowAddDialog}
                          className="btn btn-primary btn-sm"
                          disabled={selectedAccount.isDependent}
                          style={{
                            opacity: selectedAccount.isDependent ? 0.5 : 1,
                          }}
                        >
                          Ajouter personne à charge
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="table-responsive">
                    <table className="table table-hover table-center mb-0">
                      <thead>
                        <tr>
                          <th>Nom</th>
                          <th>Relation</th>
                          <th>Genre</th>
                          <th>Date Naissance</th>
                          <th>Téléphone</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isObjectEmptyOrUndefined(dependants) && (
                          <>
                            {dependants.map((dependant) => (
                              <tr key={dependant.id}>
                                <td>
                                  <h2 className="table-avatar">
                                    <span className="avatar avatar-sm me-2">
                                      <img
                                        className="avatar-img rounded-circle"
                                        src={
                                          dependant.logoPath
                                            ? dependant.logoPath
                                            : default_profile
                                        }
                                        alt="User Image"
                                      />
                                    </span>
                                    {dependant.firstName} {dependant.lastName}{" "}
                                  </h2>
                                </td>
                                <td>{dependant.dependentRelationship}</td>
                                <td>{dependant.gender}</td>
                                <td>{displayDay(dependant.dayOfBirth)} </td>
                                <td>{dependant.phoneNumber || "N/A"}</td>
                                <td>
                                  <div className="table-action text-center">
                                    <a
                                      className="btn btn-sm bg-info-light"
                                      onClick={() =>
                                        handleOpenPatientDetails(dependant.id)
                                      }
                                    >
                                      {" "}
                                      <i className="fas fa-edit" /> Modifier
                                    </a>
                                    &nbsp;
                                    <a
                                      className="btn btn-sm bg-danger-light"
                                      onClick={() =>
                                        handleRemoveDependent(dependant)
                                      }
                                    >
                                      <i className="fas fa-times" /> Supprimer
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                    {selectedAccount.isDependent && (
                      <>
                        <br></br>
                        <h3 className="text-center">
                          {" "}
                          Veuillez sélectionner le titulaire du compte pour la
                          gestion des personnes à charge{" "}
                        </h3>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Dependant */}
        <AddEditPatientDependantModal
          show={showAddDialog}
          handleClose={handleCloseAddDialog}
          onSubmit={handleAddDependantFormSubmit}
          dialogTitle="Ajouter personne à charge"
        ></AddEditPatientDependantModal>
        {/* Edit Dependant */}
        <AddEditPatientDependantModal
          show={showEditDialog}
          handleClose={handleCloseEditDialog}
          onSubmit={handleEditDependantFormSubmit}
          dependant={selectedDependant}
          dialogTitle="Modifier personne à charge"
        ></AddEditPatientDependantModal>
        {dependentToDelete && (
          <ConfirmationDialog
            open={deleteDialogOpen}
            title="Suppression personne à charge"
            message={`Êtes-vous sur de vouloir supprimer ce dépendant (${dependentToDelete.firstName} ${dependentToDelete.lastName})?`}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}
      </div>

      <Footer />
    </>
  );
};

export default PatientDependent;
