import React, { useEffect } from "react";
import {
  work_01,
  work_02,
  work_03,
  work_04,
  work_img,
  lock,
  serv_img_icon_2,
} from "../imagepath";
import AOS from "aos";
import "aos/dist/aos.css";
import { useAuth } from "react-oidc-context";

function Worksection() {
  //Aos
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const auth = useAuth();

  return (
    <>
      <section className="work-section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-12 work-img-info aos"
              data-aos="fade-up"
            >
              <div className="work-img">
                <img src={work_img} className="img-fluid" alt="" />
              </div>
            </div>

            {auth.user?.profile["role"] == "docteur" ? (
              <div className="col-lg-8 col-md-12 work-details">
                <div className="section-header-one aos" data-aos="fade-up">
                  <h5>Pourquoi Allodoc</h5>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_01} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Gestion des Rendez-vous</h5>
                        <p>
                          Accédez à l’historique complet de vos consultations et
                          gérez les demandes de rendez-vous en attente de
                          manière centralisée.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_02} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Dossiers Patients Centralisés</h5>
                        <p>
                          Consultez les dossiers médicaux de vos patients en
                          toute sécurité, incluant les ordonnances, analyses,
                          recommandations et résultats des consultations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_03} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Calendrier Intuitif</h5>
                        <p>
                          Visualisez et gérez vos rendez-vous facilement grâce à
                          un calendrier interactif. Ajoutez, modifiez ou annulez
                          des créneaux horaires en quelques clics.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_04} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Notifications Automatiques</h5>
                        <p>
                          Recevez des rappels pour vos rendez-vous et ceux de
                          vos patients pour éviter les oublis et améliorer la
                          gestion du temps.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={lock} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Confidentialité et Sécurité</h5>
                        <p>
                          Toutes les informations médicales sont protégées par
                          des normes de sécurité rigoureuses, garantissant la
                          confidentialité de vos patients.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={serv_img_icon_2} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Mise à Jour Instantanée</h5>
                        <p>
                          Mettez à jour les informations médicales de vos
                          patients en temps réel et assurez-vous que toutes les
                          données soient à jour et accessibles rapidement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-8 col-md-12 work-details">
                <div className="section-header-one aos" data-aos="fade-up">
                  <h5>Comment ça fonctionne</h5>
                  <h2 className="section-title">
                    4 étapes faciles pour obtenir votre solution
                  </h2>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_01} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Rechercher un médecin</h5>
                        <p>
                          Recherchez un médecin en entrant vos critères de choix
                          : nom, spécialité, disponibilité… Cette formulation
                          met en avant la simplicité et l'efficacité de votre
                          outil de recherche de médecin, en invitant les
                          utilisateurs à spécifier leurs préférences pour
                          trouver le professionnel de santé qui leur convient le
                          mieux.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_02} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Consulter le profil du médecin</h5>
                        <p>
                          Consultez les informations sur votre medecin comme son
                          calendrier, ses spécialités et sa formation. Cette
                          formulation est concise et claire, indiquant aux
                          utilisateurs qu'ils peuvent accéder aux informations
                          détaillées sur le médecin de leur choix.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_03} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Prendre rendez-vous</h5>
                        <p>
                          Sélectionnez un créneau horaire de disponibilité de
                          votre médecin et obtenez une confirmation directement
                          sur la plateforme. Rapide, simple et sécurisé, pour
                          des rendez-vous accessibles 24/7. Réservez dès
                          maintenant !
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 aos" data-aos="fade-up">
                    <div className="work-info">
                      <div className="work-icon">
                        <span>
                          <img src={work_04} alt="" />
                        </span>
                      </div>
                      <div className="work-content">
                        <h5>Obtenir votre solution</h5>
                        <p>
                          Recevez une notification de votre rendez-vous et
                          accédez à votre dossier médical à tout moment
                          (ordonnances, analyses, recommandations, et résultats
                          de consultations). Simple, sécurisé, et disponible
                          24/7.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Worksection;
