import React, { useEffect, useState } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { ISpeciality } from "../models/backend";
import { isObjectEmptyOrUndefined } from "../utils/objUtils";
import { Chip } from "@material-ui/core";
interface SpecialitySelectorProps {
  acceptedSpecialities: ISpeciality[];
  initialSelectedSpecialities: ISpeciality[];
  onUpdateSpecialities: (newSpecialities: ISpeciality[]) => void;
}

const SpecialitySelector: React.FC<SpecialitySelectorProps> = ({
  acceptedSpecialities,
  initialSelectedSpecialities,
  onUpdateSpecialities,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedSpecialities, setSelectedSpecialities] = useState<
    ISpeciality[]
  >(initialSelectedSpecialities || []);
  const [suggestions, setSuggestions] =
    useState<ISpeciality[]>(acceptedSpecialities);

  useEffect(() => {
    // This effect updates the suggestions based on the current selectedSpecialities
    const updatedSuggestions = acceptedSpecialities.filter(
      (speciality) =>
        !selectedSpecialities.some((selected) => selected.id === speciality.id)
    );
    setSuggestions(updatedSuggestions);
  }, [selectedSpecialities, acceptedSpecialities, initialSelectedSpecialities]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (!value) {
      // Reset suggestions to exclude selectedSpecialities when input is empty
      setSuggestions(
        acceptedSpecialities.filter(
          (speciality) =>
            !selectedSpecialities.some(
              (selected) => selected.id === speciality.id
            )
        )
      );
      return;
    }
    const matchedSpecialities = acceptedSpecialities.filter(
      (speciality) =>
        speciality.title?.toLowerCase().includes(value.toLowerCase()) &&
        !selectedSpecialities.some((selected) => selected.id === speciality.id)
    );
    setSuggestions(matchedSpecialities);
  };

  const handleSelectSpeciality = (speciality: ISpeciality) => {
    setSelectedSpecialities((prevSelected) => {
      const updatedSelected = [...prevSelected, speciality];
      onUpdateSpecialities(updatedSelected);
      return updatedSelected;
    });
    setInputValue("");
  };

  const handleRemoveSpeciality =
    (specialityId: string) => (event: React.MouseEvent) => {
      event.stopPropagation();
      setSelectedSpecialities((prevSelected) => {
        const updatedSelected = prevSelected.filter(
          (s) => s.id !== specialityId
        );
        onUpdateSpecialities(updatedSelected);
        return updatedSelected;
      });
    };

  return (
    <div>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Entrer une spécialité"
          value={inputValue}
          onChange={handleInputChange}
          autoComplete="off"
        />
        {!isObjectEmptyOrUndefined(suggestions) && (
          <>
            <Dropdown.Menu
              show={suggestions.length > 0 && inputValue.length > 0}
            >
              {suggestions.map((speciality) => (
                <Dropdown.Item
                  key={speciality.id}
                  onClick={() => handleSelectSpeciality(speciality)}
                >
                  {speciality.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </>
        )}
      </Form.Group>
      <br></br>
      <div>
        {!isObjectEmptyOrUndefined(selectedSpecialities) && (
          <>
            {selectedSpecialities.map((speciality) => (
              <Chip
                key={speciality.id}
                label={speciality.title}
                style={{ marginLeft: "5px" }}
                onDelete={handleRemoveSpeciality(speciality.id)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SpecialitySelector;
