import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SelectionListComponent from "../../common/shared-components/SelectionListComponent";
import { FAMILY_MEMBER_TYPE } from "../model/patient-model";
import { useEffect, useState } from "react";
import { getEnumKeyFromValue } from "../../utils/enumUtils";
import { isObjectEmptyOrUndefined } from "../../utils/objUtils";
import { IPatient } from "../../models/backend";

export const AddEditPatientDependantModal = ({
  show,
  handleClose,
  onSubmit,
  dialogTitle,
  dependant,
}: {
  show: boolean;
  handleClose: () => void;
  dialogTitle: string;
  onSubmit: (data: IPatient) => void;
  dependant?: IPatient;
}) => {
  const emptyDependantData: IPatient = {
    dependentRelationship: FAMILY_MEMBER_TYPE.WIFE,
    firstName: "",
    lastName: "",
    gender: "Homme",
    dayOfBirth: new Date(),
    phoneNumber: "",
    appointments: [],
  };

  const initialState = isObjectEmptyOrUndefined(dependant)
    ? emptyDependantData
    : { ...dependant };

  const [dependantData, setDependantData] = useState<IPatient>({
    ...initialState,
  });

  registerLocale("fr", fr);
  const familyRelationships: string[] = Object.values(FAMILY_MEMBER_TYPE);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (dependant) {
      setDependantData(dependant);
      setDate(new Date(dependant?.dayOfBirth));
    }
  }, [dependant]);
  const handleChangeDate = (date) => {
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0, 0)
    ); // on sauvegarde la date UTC a 8:00 pour une meilleure gestion d'ensemble

    setDate(utcDate);
    setDependantData({
      ...dependantData,
      dayOfBirth: utcDate,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDependantData({
      ...dependantData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Submitting form data:", dependantData);
    onSubmit(dependantData);
    setDependantData(emptyDependantData);
    handleClose();
  };

  const handleRelationShipChange = (selectedValue: string) => {
    const enumKey = getEnumKeyFromValue(FAMILY_MEMBER_TYPE, selectedValue);
    setDependantData({
      ...dependantData,
      dependentRelationship: FAMILY_MEMBER_TYPE[enumKey],
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal"
        style={{ marginTop: "80px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{dialogTitle}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {!isObjectEmptyOrUndefined(dependantData) && (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="firstName" className="control-label mb-10">
                      Prenom <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={dependantData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName" className="control-label mb-10">
                      Nom <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={dependantData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">
                      Lien / Parente <span className="text-danger">*</span>
                    </label>{" "}
                    <div className="col-sm-10">
                      <SelectionListComponent
                        availableOptions={familyRelationships}
                        onChange={handleRelationShipChange}
                        initialValue={dependantData.dependentRelationship}
                      ></SelectionListComponent>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="phoneNumber"
                      className="control-label mb-10"
                    >
                      Téléphone:
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNumber"
                        value={dependantData.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">
                      Date de naissance<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10"></div>
                    <DatePicker
                      name="dob"
                      id="dob"
                      locale="fr"
                      dateFormat="dd/MM/yyyy"
                      required
                      selected={date}
                      onChange={handleChangeDate}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Photo </label>
                    <div className="col-sm-10">
                      <input
                        type="file"
                        name="profile_image"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer text-center">
                  <button type="submit" className="btn btn-primary submit-btn">
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleClose}
                  >
                    Annuler
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
      ;
    </>
  );
};
