import React, { useContext, useEffect, useState } from "react";
// import loginBanner from "../../../assets/images/login-banner.png";
import Logo from "../../assets/images/allodoc.png";
import camera from "../../assets/images/icons/camera.svg";
import male from "../../assets/images/icons/male.png";
import female from "../../assets/images/icons/female.png";
import countryList from "react-select-country-list";

import { Link, useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import uuid from "react-uuid";
import { IDoctor } from "../../models/backend";

const Registersteptwo = (props) => {
  const options = countryList().getData();
  const auth = useAuth();
  const { state } = useLocation();
  const [country, setCountry] = useState("");
  const [addLigne1, setAddLigne1] = useState("");
  const [addLigne2, setAddLigne2] = useState("");
  const [region, setRegion] = useState("");
  const [etat, setEtat] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [genre, setGenre] = useState("");
  const [dob, setDOB] = useState("");
  const [erreur, setErreur] = useState(false);
  const [msgErreur, setMessageErreur] = useState([]);
  const [defaultValueSet, setDefaultValueFlag] = useState(false);

  const validateForm = () => {
    let messageErreur = [];
    if (addLigne1 == "") {
      messageErreur.push("Au moins la ligne 1 de l'adresse");
    }

    if (region == "") {
      messageErreur.push("Region");
    }

    if (genre == "") {
      messageErreur.push("Genre");
    }

    if (country == "") {
      messageErreur.push("Le pays");
    }

    if (messageErreur.length > 0) {
      setErreur(true);
      setMessageErreur(messageErreur);
    } else {
      setErreur(false);
    }
  };

  const setDefaultValues = (doctor: IDoctor) => {
    setCountry(doctor?.doctorAddress?.country);
    setAddLigne1(doctor?.doctorAddress?.addressLine1);
    setAddLigne2(doctor?.doctorAddress?.addressLine2);
    setRegion(doctor?.doctorAddress?.region);
    setEtat(doctor?.doctorAddress?.state);
    setCodePostal(doctor?.doctorAddress?.postalCode);
    setGenre(doctor?.gender);
    setDefaultValueFlag(true);
  };

  useEffect(() => {
    document.body.classList.add("account-page");
    return () => document.body.classList.remove("account-page");
  }, []);

  useEffect(() => {
    if (state && !defaultValueSet) {
      setDefaultValues(state as IDoctor);
    }
    validateForm();
  }, [addLigne1, region, genre, country]);

  return (
    <>
      {/* Page Content */}
      <div className="content login-page pt-0">
        <div className="container-fluid">
          {/* Register Content */}
          <div className="account-content">
            <div className="row align-items-center">
              <div className="login-right">
                <div className="inner-right-login">
                  <div className="login-header">
                    <div className="logo-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="step-list">
                      <ul>
                        <li>
                          <Link to="#" className="active-done">
                            1
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="active">
                            2
                          </Link>
                        </li>
                        <li>
                          <Link to="#">3</Link>
                        </li>
                      </ul>
                    </div>
                    <form id="personal_details" encType="multipart/form-data">
                      <div className="text-start mt-2">
                        <h4 className="mt-3">
                          Select Your Gender{" "}
                          <span className="text-danger">*</span>
                        </h4>
                      </div>
                      <div className="select-gender-col">
                        <div className="row">
                          <div className="col-6 pe-0">
                            <input
                              type="radio"
                              id="test1"
                              name="gender"
                              checked={genre == "Homme"}
                              defaultValue="Homme"
                              onChange={(e) => {
                                setGenre(e.target.value);
                              }}
                            />
                            <label htmlFor="test1">
                              <span className="gender-icon">
                                <img src={male} alt="" />
                              </span>
                              <span>Homme</span>
                            </label>
                          </div>
                          <div className="col-6 ps-2">
                            <input
                              type="radio"
                              id="test2"
                              name="gender"
                              checked={genre == "Femme"}
                              defaultValue="Femme"
                              onChange={(e) => {
                                setGenre(e.target.value);
                              }}
                            />
                            <label htmlFor="test2">
                              <span className="gender-icon">
                                <img src={female} alt="" />
                              </span>
                              <span>Femme</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="step-process-col mt-4">
                        <div className="form-group">
                          <label>
                            Adresse personnelle{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="addressligne2"
                            value={addLigne1}
                            placeholder="ligne 1"
                            className="form-control"
                            id="addressligne1"
                            onChange={(e) => {
                              setAddLigne1(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="addressligne2"
                            value={addLigne2}
                            placeholder="ligne 2"
                            className="form-control"
                            id="addressligne2"
                            onChange={(e) => {
                              setAddLigne2(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Ville / Region{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="region"
                            value={region}
                            placeholder=""
                            className="form-control"
                            id="region"
                            onChange={(e) => {
                              setRegion(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Etat</label>
                          <input
                            type="text"
                            name="etat"
                            value={etat}
                            placeholder=""
                            className="form-control"
                            id="etat"
                            onChange={(e) => {
                              setEtat(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Code postal</label>
                          <input
                            type="text"
                            name="codepostal"
                            value={codePostal}
                            placeholder=""
                            className="form-control"
                            id="codepostal"
                            onChange={(e) => {
                              setCodePostal(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Pays <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select form-control"
                            id="pays"
                            name="pays"
                            tabIndex={-1}
                            aria-hidden="true"
                            value={country}
                            onChange={(e) => {
                              setCountry(e.target.value);
                            }}
                          >
                            <option value="">Selectionnez votre pays</option>
                            {options.map((opt) => {
                              return (
                                <option value={opt?.value} key={opt?.value}>
                                  {opt?.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {/* <div className="form-group">
                          <label>
                            Certification and Employer{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="row justify-content-center">
                            <div className="col-12 col-md-6 d-flex">
                              <div className="profile-pic-upload d-flex flex-wrap justify-content-center">
                                <div className="cam-col">
                                  <img src={camera} alt="" />
                                </div>
                                <span className="text-center">
                                  Upload Rigth To sell Certigifcate
                                </span>
                                <input
                                  type="file"
                                  id="quali_certificate"
                                  name="quali_certificate"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex">
                              <div className="profile-pic-upload d-flex flex-wrap justify-content-center">
                                <div className="cam-col">
                                  <img src={camera} alt="" />
                                </div>
                                <span className="text-center">
                                  Upload Photo ID
                                </span>
                                <input
                                  type="file"
                                  id="photo_id"
                                  name="photo_id"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex">
                              <div className="profile-pic-upload d-flex flex-wrap justify-content-center">
                                <div className="cam-col">
                                  <img src={camera} alt="" />
                                </div>
                                <span className="text-center">
                                  Upload Clinical employment
                                </span>
                                <input
                                  type="file"
                                  id="clinical_employment"
                                  name="clinical_employment"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      {erreur && (
                        <div
                          className="alert alert-danger alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>Erreur!</strong> Merci de remplir tous les
                          champs du formulaire avant de passer a la page
                          suivante
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                          <ul>
                            {msgErreur.map((m) => {
                              return <li key={m}>{m}</li>;
                            })}
                          </ul>
                        </div>
                      )}
                      <div className="mt-5">
                        <Link
                          to={{
                            pathname: "/register-step-3",
                          }}
                          state={
                            {
                              id: auth?.user?.profile["email"],
                              firstName: auth?.user?.profile["given_name"],
                              lastName: auth?.user?.profile["family_name"],
                              phoneNumber: auth?.user?.profile["phone_number"],
                              gender: genre,
                              joinedOn: new Date(),
                              logoPath: state?.logoPath,
                              doctorAddress: {
                                id: uuid(),
                                addressLine1: addLigne1,
                                addressLine2: addLigne2,
                                city: region,
                                region: region,
                                postalCode: codePostal,
                                country: country,
                                state: etat,
                              },
                            } as IDoctor
                          }
                          className={`btn ${
                            erreur ? "disabled" : ""
                          } btn-primary w-100 btn-lg login-btn step2_submit`}
                        >
                          continue{" "}
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="login-bottom-copyright">
                  <span>© 2024 Allo-Doc. Tous droits réservés.</span>
                </div>
              </div>
            </div>
          </div>
          {/* /Register Content */}
        </div>
      </div>
      {/* /Page Content */}
    </>
  );
};

export default Registersteptwo;
