import { IContactUsModel, IRecordType, ISpeciality } from "../models/backend";
import {
  IDoctorRepository,
  DoctorRepository,
} from "../repositories/doctorRepository";
import {
  IManagementRepository,
  ManagementRepository,
} from "../repositories/managementRepository";

interface IManagementService {
  getAllSpecialitiesAsync(): Promise<ISpeciality[]>;
  saveSpecialityAsync(speciality: ISpeciality): Promise<boolean>;
  deleteSpecialityAsync(specialityId: string): Promise<boolean>;
  sendContactMessageAsync(model: IContactUsModel): Promise<boolean>;
  saveProfileLogoAsync(
    userId: string,
    role: string,
    file: FormData
  ): Promise<string>;
  getAllRecordTypesAsync(): Promise<IRecordType[]>;
  saveRecordTypeAsync(speciality: IRecordType): Promise<boolean>;
  deleteRecordTypeAsync(specialityId: string): Promise<boolean>;
}

class ManagementService implements IManagementService {
  private _managementRepo: IManagementRepository;

  /**
   *Repository that will handle the request for the doctors
   */
  constructor() {
    this._managementRepo = new ManagementRepository();
  }

  async getAllRecordTypesAsync(): Promise<IRecordType[]> {
    return await this._managementRepo.getAllRecordTypesAsync();
  }

  async saveRecordTypeAsync(recordType: IRecordType): Promise<boolean> {
    return await this._managementRepo.saveRecordTypeAsync(recordType);
  }

  async deleteRecordTypeAsync(recordTypeId: string): Promise<boolean> {
    return await this._managementRepo.deleteRecordTypeAsync(recordTypeId);
  }

  async sendContactMessageAsync(model: IContactUsModel): Promise<boolean> {
    return await this._managementRepo.sendContactMessageAsync(model);
  }

  async saveProfileLogoAsync(userId: string, role: string, file: FormData) {
    return await this._managementRepo.saveProfileLogoAsync(userId, role, file);
  }

  async getAllSpecialitiesAsync(): Promise<ISpeciality[]> {
    return await this._managementRepo.getAllSpecialitiesAsync();
  }
  async saveSpecialityAsync(speciality: ISpeciality): Promise<boolean> {
    return await this._managementRepo.saveSpecialityAsync(speciality);
  }
  async deleteSpecialityAsync(specialityId: string): Promise<boolean> {
    return await this._managementRepo.deleteSpecialityAsync(specialityId);
  }
}

export { ManagementService, IManagementService };
