import React, { useContext, useEffect, useRef, useState } from "react";
// import { Doctor01, Doctor2, Doctor3 } from "../../home/image";
// import { doctor04, doctor05 } from "../aboutus/img";
import {
  FiAward,
  FiBook,
  FiCalendar,
  FiClock,
  FiDollarSign,
  FiGrid,
  FiHeart,
  FiInfo,
  FiList,
  FiMapPin,
  FiThumbsUp,
} from "react-icons/fi";
import { default_profile } from "../../common/imagepath";
import { Link, useLocation } from "react-router-dom";
import { DoctorService } from "../../services/doctorService";
import {
  IAddress,
  IDoctor,
  IPatient,
  ISearchFilter,
} from "../../models/backend";
import { DateTime } from "luxon";
import { useAuth } from "react-oidc-context";
import { PulseLoader } from "react-spinners";
import { PatientContext } from "../../patient/PatientContext";
import { formatAddress } from "../../utils/objUtils";

const SearchDoctorsList = (props) => {
  const [doctors, setDoctors] = useState<IDoctor[]>([]);
  const doctorService = new DoctorService();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);
  const [currentPatient, setCurrentPatient] = useState<IPatient>(
    {} as IPatient
  );

  useEffect(() => {
    let doctorsPromise: Promise<any>;
    setIsLoading(true);
    if (props?.filtre?.name) {
      doctorsPromise = doctorService.getDoctorByNameAsync(props?.filtre?.name);
    } else {
      doctorsPromise = doctorService.getAllDoctorAsync();
    }

    doctorsPromise
      .then((result: IDoctor[]) => {
        // remove all the doctors that are not authorized yet
        if (result) {
          result = result.filter((d) => d.isAuthorized);
        }

        if (result) {
          // apply the filter information
          if (props?.filtre?.homme) {
            result = result.filter((d) => d.gender.toLowerCase() === "homme");
          }
          if (props?.filtre?.femme) {
            result = result.filter((d) => d.gender.toLowerCase() === "femme");
          }

          const today = new Date();
          // available today
          if (props?.filtre?.availableToday) {
            result = result.filter(
              // returns all the doctors when
              // the number of appointment is less than 5 in that day
              (d) =>
                d.appointments?.filter(
                  (a) =>
                    a.date.getDate() == today.getDate() &&
                    a.date.getMonth() == today.getMonth() &&
                    a.date.getFullYear() == today.getFullYear()
                ).length < 5
            );
          }

          // available tomorrow
          const tomorrow = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + 1
          );
          if (props?.filtre?.availableTomorrow) {
            result = result.filter(
              // returns all the doctors when
              // the number of appointment is less than 5 in that day
              (d) =>
                d.appointments?.filter(
                  (a) =>
                    a.date.getDate() == tomorrow.getDate() &&
                    a.date.getMonth() == tomorrow.getMonth() &&
                    a.date.getFullYear() == tomorrow.getFullYear()
                ).length < 5
            );
          }

          if (props?.filtre?.specialities?.length > 0) {
            result = result.filter(
              // return all the doctor that
              // has on of the selected specialities
              (d) =>
                d.specialities?.find((s) =>
                  props?.filtre?.specialities.includes(s.title)
                )
            );
          }

          setDoctors(result);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.filtre]);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      var id = auth.user?.profile["email"] || "";
      getPatientData(id).then((patientData) => {
        if (patientData) {
          setCurrentPatient(patientData);
        }
      });
    }
  }, []);

  const calculateJoiningTime = (joinedOn: Date) => {
    let durationString = "";
    const dateJoined = DateTime.fromISO(new Date(joinedOn).toISOString());
    const currentDate = DateTime.fromISO(new Date().toISOString());

    const diff = currentDate.diff(dateJoined, [
      "years",
      "months",
      "days",
      "hours",
    ]);

    const durations = diff.toObject();
    if (durations.years > 0) {
      durationString = `${Math.round(durations.years)} annees.`;
    } else if (durations.months > 0) {
      durationString = `${Math.round(durations.months)} mois.`;
    } else if (durations.days > 0) {
      durationString = `${Math.round(durations.days)} jours.`;
    } else {
      durationString = `${Math.round(durations.hours)} heures.`;
    }

    return durationString;
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ margin: "auto", width: "10%" }}>
          <PulseLoader color="#0e82fd" />
        </div>
      ) : doctors.length == 0 ? (
        <div style={{ margin: "auto", width: "30%" }}>Aucun docteur trouve</div>
      ) : (
        <div>
          {doctors.map((doc) => {
            return (
              <div className="card doctor-card" key={doc.id}>
                <div className="card-body">
                  <div className="doctor-widget-one">
                    <div className="doc-info-left">
                      <div className="doctor-img">
                        <Link to="/patient/doctor-list">
                          <img
                            src={
                              doc?.logoPath ? doc?.logoPath : default_profile
                            }
                            className="img-fluid"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="doc-info-cont">
                        <h4 className="doc-name">
                          <Link to="/patient/doctor-profile">
                            Dr. {`${doc.firstName} ${doc.lastName}`}
                          </Link>
                          <i className="fas fa-circle-check" />
                        </h4>
                        <p className="doc-speciality">
                          {doc.specialities.map((s) => s.title).join(", ")}
                        </p>
                        <div className="clinic-details">
                          <p className="doc-location">
                            <FiMapPin /> {formatAddress(doc.doctorAddress)}
                            <Link
                              to={`https://maps.google.com/?q=${formatAddress(
                                doc.doctorAddress
                              )}`}
                              target="_blank"
                            >
                              Obtenir Direction
                            </Link>
                          </p>
                          <p className="doc-location">
                            <FiAward /> Inscrit sur Allo-Doc depuis{" "}
                            {calculateJoiningTime(doc?.joinedOn)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doc-info-right">
                      <div className="clini-infos">
                        <ul>
                          <li>
                            <FiClock /> &nbsp;
                            <span className="available-date available-today">
                              Disponible Aujourd'hui
                            </span>
                          </li>
                          {auth?.isAuthenticated && (
                            <li>
                              <FiBook />
                              {doc.consultationFee} FCFA
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="clinic-booking book-appoint">
                        <Link
                          className={`btn btn-primary ${
                            auth?.isAuthenticated &&
                            auth.user?.profile["role"] == "patient"
                              ? ""
                              : "disabled"
                          }`}
                          to={
                            auth?.isAuthenticated &&
                            auth.user?.profile["role"] == "patient"
                              ? "/patient-book-rv"
                              : "#"
                          }
                          state={{
                            doctor: doc,
                            currentPatient,
                          }}
                        >
                          {auth?.isAuthenticated &&
                          auth.user?.profile["role"] == "patient"
                            ? "Prendre un rendez-vous"
                            : "Connectez vous comme patient pour prendre un rendez-vous"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchDoctorsList;
