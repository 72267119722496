/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from "react";
import Priceingplan from "./priceingplan";
import Homebanner from "./homebanner";
import Speacialities from "./speacialities";
import Worksection from "./worksection";
import Testimonial from "./testimonial";
import Parentssection from "./parentssection";
import Appsection from "./appsection";
import Doctor from "./doctor";
import Faq from "./faq";
import Footer from "./footer";
import Header from "../header";
import { useAuth } from "react-oidc-context";
import { DoctorService } from "../../services/doctorService";
import { PatientService } from "../../services/patientService";
import { TokenHelpers } from "../../utils/tokenHelpers";
import { DoctorContext } from "../../doctor/DoctorContext";
import { PatientContext } from "../../patient/PatientContext";
// import Footer from "../../footer";
// import ProgressCircle from "../paediatric/scrolltotop";

export const AccessTokenContext = createContext("");

function Home(props) {
  // recuperer les informations de l'utilisateur connecte
  const userAuth = useAuth();
  const [profileCompleted, setProfileCompleted] = useState(false);
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);
  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);

  // let pathname = props.location.pathname;
  require("../assets/css/feather.css");

  useEffect(() => {
    if (userAuth?.isAuthenticated /*&& userNotCached*/) {
      // get the user from the database
      const id = userAuth?.user?.profile["email"];
      const userRole = userAuth.user?.profile["role"];

      // set the access token of the user,
      const token = userAuth?.user?.access_token;
      const tokenHelper = new TokenHelpers();
      tokenHelper.setToken(token);

      if (userRole == "patient") {
        localStorage.setItem("mainPatientId", id);
        getPatientData(id)
          .then((user) => {
            setProfileCompleted(user?.profileCompleted);
          })
          .catch((err) => console.log(err));

        // const patientService = new PatientService();
        // patientService
        //   .getPatientAsync(userName)
        //   .then((user) => {
        //     setUser(user);
        //   })
        //   .catch((err) => console.log(err));
      } else if (userRole == "docteur") {
        getDoctorData(id)
          .then((user) => {
            setProfileCompleted(user?.profileCompleted);
          })
          .catch((err) => console.log(err));

        // const doctorService = new DoctorService();
        // doctorService
        //   .getDoctorAsync(userName)
        //   .then((user) => {
        //     setUser(user);
        //   })
        //   .catch((err) => console.log(err));
      } else if (userRole == "admin") {
        console.log("Do we need to fetch the informations of the admin ?");
      } else {
        console.log("User role incorrect");
      }
    }
  }, [userAuth]);

  // useEffect(() => {
  //   window.location.reload();
  // }, []);
  return (
    <>
      <>
        <div className="main-wrapper">
          <Header profileCompleted={profileCompleted} />
          <Homebanner profileCompleted={profileCompleted} />
          <Speacialities />
          <Worksection />
          <Parentssection />
          <Faq />
          <Footer {...props} />
        </div>
      </>
    </>
  );
}

export default Home;
