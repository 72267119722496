import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/images/allodoc.png";
import countryList from "react-select-country-list";
import { Link, useLocation } from "react-router-dom";
import { IClinic, IDoctor } from "../../models/backend";
import uuid from "react-uuid";
import { DoctorContext } from "../DoctorContext";

const Registerstepthree = (props) => {
  const options = countryList().getData();
  const { state } = useLocation();
  const [country, setCountry] = useState("");
  const [addLigne1, setAddLigne1] = useState("");
  const [addLigne2, setAddLigne2] = useState("");
  const [region, setRegion] = useState("");
  const [etat, setEtat] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [nomClinic, setNomClinic] = useState("");
  const [identification, setIdentification] = useState("");
  const [currentDoctor, setCurrentDoctor] = useState<IDoctor>(state as IDoctor);
  const [erreur, setErreur] = useState(false);
  const [msgErreur, setMessageErreur] = useState([]);
  const [defaultValueSet, setDefaultValueFlag] = useState(false);
  const validateForm = () => {
    let messageErreur = [];

    if (identification == "") {
      messageErreur.push("Renseignez votre numero de medecin");
    }

    if (addLigne1 == "") {
      messageErreur.push("Au moins la ligne 1 de l'adresse");
    }

    if (region == "") {
      messageErreur.push("Region");
    }

    if (nomClinic == "") {
      messageErreur.push("Nom de la clinique");
    }

    if (country == "") {
      messageErreur.push("Le pays");
    }

    if (messageErreur.length > 0) {
      setErreur(true);
      setMessageErreur(messageErreur);
    } else {
      setErreur(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [addLigne1, region, country]);

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
    <>
      <>
        {/* Page Content */}
        <div className="content login-page pt-0">
          <div className="container-fluid">
            {/* Register Content */}
            <div className="account-content">
              <div className="row align-items-center">
                <div className="login-right">
                  <div className="inner-right-login">
                    <div className="login-header">
                      <div className="logo-icon">
                        <img src={Logo} alt="" />
                      </div>
                      <div className="step-list">
                        <ul>
                          <li>
                            <Link to="#" className="active-done">
                              1
                            </Link>
                          </li>
                          <li>
                            <Link
                              state={currentDoctor as IDoctor}
                              to={{
                                pathname: "/register-step-2",
                              }}
                              className="active-done"
                            >
                              2
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="active">
                              3
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <form method="post">
                        <h3 className="my-4">Information sur la profession</h3>
                        <div className="form-group">
                          <label>
                            Identification{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="identification"
                            value={identification}
                            placeholder=""
                            className="form-control"
                            id="identification"
                            onChange={(e) => {
                              setIdentification(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Nom de la clinique{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="nomClinic"
                            value={nomClinic}
                            placeholder=""
                            className="form-control"
                            id="addressligne2"
                            onChange={(e) => {
                              setNomClinic(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Adresse de votre lieu d'exercice{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="addressligne2"
                            value={addLigne1}
                            placeholder="ligne 1"
                            className="form-control"
                            id="addressligne1"
                            onChange={(e) => {
                              setAddLigne1(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="addressligne2"
                            value={addLigne2}
                            placeholder="ligne 2"
                            className="form-control"
                            id="addressligne2"
                            onChange={(e) => {
                              setAddLigne2(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Ville / Region{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="region"
                            value={region}
                            placeholder=""
                            className="form-control"
                            id="region"
                            onChange={(e) => {
                              setRegion(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Etat</label>
                          <input
                            type="text"
                            name="etat"
                            value={etat}
                            placeholder=""
                            className="form-control"
                            id="etat"
                            onChange={(e) => {
                              setEtat(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Code postal</label>
                          <input
                            type="text"
                            name="codepostal"
                            value={codePostal}
                            placeholder=""
                            className="form-control"
                            id="codepostal"
                            onChange={(e) => {
                              setCodePostal(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Pays <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select form-control"
                            id="pays"
                            name="pays"
                            tabIndex={-1}
                            aria-hidden="true"
                            value={country}
                            onChange={(e) => {
                              setCountry(e.target.value);
                            }}
                          >
                            <option value="">Selectionnez votre pays</option>
                            {options.map((opt) => {
                              return (
                                <option value={opt?.value} key={opt?.value}>
                                  {opt?.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {erreur && (
                          <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>Erreur!</strong> Merci de remplir tous les
                            champs du formulaire avant de passer a la page
                            suivante
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                            <ul>
                              {msgErreur.map((m) => {
                                return <li key={m}>{m}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                        <div className="mt-5">
                          <Link
                            to={{
                              pathname: "/dashboard-docteur",
                            }}
                            state={
                              {
                                ...currentDoctor,
                                profileCompleted: true,
                                idNumber: identification,
                                doctorClinic: {
                                  id: uuid(),
                                  name: nomClinic,
                                  clinicAddress: {
                                    id: uuid(),
                                    addressLine1: addLigne1,
                                    addressLine2: addLigne2,
                                    city: region,
                                    region: region,
                                    postalCode: codePostal,
                                    country: country,
                                    state: etat,
                                  },
                                } as IClinic,
                              } as IDoctor
                            }
                            className={`btn ${
                              erreur ? "disabled" : ""
                            } btn-primary w-100 btn-lg login-btn step2_submit`}
                          >
                            Update{" "}
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="login-bottom-copyright">
                    <span>© 2024 Allo-Doc. Tous droits réservés.</span>
                  </div>
                </div>
              </div>
            </div>
            {/* /Register Content */}
          </div>
        </div>
        {/* /Page Content */}
      </>
    </>
  );
};

export default Registerstepthree;
