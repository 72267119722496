import { Table } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { itemRender, onShowSizeChange } from "../paginationfunction";
import { IPatient } from "../../../models/backend";
import { SyncLoader } from "react-spinners";

const PatientsListDasboard = (props) => {
  const isLoading = props.isLoading;
  const data = props?.patients.map((p: IPatient) => {
    return {
      id: p.id,
      PatientID: p.id,
      PatientName: `${p.firstName} ${p.lastName}`,
      Age: p.dayOfBirth,
      Address: `${p.patientAddress?.addressLine1} ${p.patientAddress?.city} ${p.patientAddress?.country}`,
      Phone: p.phoneNumber,
      VisitLast: "18 Sep 2019", // TODO: get the latest update date
    };
  });

  const columns = [
    {
      title: "Nom patient",
      dataIndex: "PatientName",
      render: (text, record) => (
        <>
          <label>{text}</label>
        </>
      ),
      sorter: (a, b) => a.PatientName.length - b.PatientName.length,
    },

    {
      title: "Telephone",
      dataIndex: "Phone",
      sorter: (a, b) => a.Phone.length - b.Phone.length,
    },
    {
      title: "Derniere visite",
      dataIndex: "VisitLast",
      sorter: (a, b) => a.length - b.length,
    },
  ];
  return (
    <>
      <div className="col-md-6 d-flex">
        <div className="card  card-table flex-fill">
          <div className="card-header">
            <h4 className="card-title">
              Liste des patients
              <span style={{ marginLeft: "25px" }}>
                {isLoading && <SyncLoader color="#1b5a90" size={10} />}
              </span>
            </h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <Table
                pagination={{
                  total: data.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PatientsListDasboard;
