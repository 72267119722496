import { IDossierMedical, IPatient, IRelatedPatients } from "../models/backend";
import { FixMeLater } from "../tsmigration";
import { BACKEND_URL } from "../utils/constants";
import HttpClient from "../utils/httpClient";
import { TokenHelpers } from "../utils/tokenHelpers";

interface IPatientRepository {
  getPatientAsync(patientId: string): Promise<any>;
  savePatientAsync(patient: IPatient): Promise<boolean>;
  deletePatientAsync(patientId: string): Promise<boolean>;
  getMedicalRecords(patientId: string): Promise<IDossierMedical[]>;
  lockPatientAccountAsync(
    patientId: string,
    lockAccount: boolean
  ): Promise<boolean>;
  getAllPatientAsync(): Promise<IPatient[]>;
  addDependantToPatient(
    patientId: string,
    dependant: IPatient
  ): Promise<boolean>;

  removeDependant(parentId: string, dependantId: string): Promise<boolean>;
  getRelatedPatients(parentId: string): Promise<IRelatedPatients[]>;
}

class PatientRepository implements IPatientRepository {
  private _httpClient: HttpClient;
  private _tokenHelpers: TokenHelpers;

  /**
   *Repository that will handle the request for the patients
   */
  constructor() {
    this._tokenHelpers = new TokenHelpers();
    this._httpClient = new HttpClient(
      BACKEND_URL,
      this._tokenHelpers.getToken()
    );
  }
  async getRelatedPatients(parentId: string): Promise<IRelatedPatients[]> {
    return await this._httpClient.get<IRelatedPatients[]>(
      "/api/patient/GetDependentList",
      {
        parentId: parentId,
      }
    );
  }
  async addDependantToPatient(
    patientId: string,
    dependant: IPatient
  ): Promise<boolean> {
    return await this._httpClient.post<IPatient, boolean>(
      "/api/patient/AddDependent",
      { parentId: patientId },
      dependant
    );
  }

  async removeDependant(
    parentId: string,
    dependantId: string
  ): Promise<boolean> {
    return await this._httpClient.post<IPatient, boolean>(
      "/api/patient/RemoveDependant",
      { parentId: parentId, patientId: dependantId }
    );
  }

  async getMedicalRecords(patientId: string): Promise<IDossierMedical[]> {
    return await this._httpClient.get<IDossierMedical[]>(
      "/api/patient/records",
      {
        id: patientId,
      }
    );
  }

  async getAllPatientAsync(): Promise<IPatient[]> {
    return await this._httpClient.get<IPatient[]>("/api/patient/getall");
  }

  async savePatientAsync(patient: IPatient): Promise<boolean> {
    return await this._httpClient.post<IPatient, boolean>(
      "/api/patient/add",
      {},
      patient
    );
  }

  async deletePatientAsync(patientId: string): Promise<boolean> {
    const requestUrl = `/api/Patient/Delete/${patientId}`;

    return await this._httpClient.remove<FixMeLater, boolean>(requestUrl);
  }

  async getPatientAsync(patientId: string) {
    return await this._httpClient.get<IPatient>("/api/patient/getbyid", {
      id: patientId,
    });
  }

  async lockPatientAccountAsync(
    patientId: string,
    lockAccount: boolean
  ): Promise<boolean> {
    return await this._httpClient.post<IPatient, boolean>("/api/patient/lock", {
      id: patientId,
      lockAccount: lockAccount,
    });
  }
}

export { PatientRepository, IPatientRepository };
