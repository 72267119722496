import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { FixMeLater } from "./tsmigration";
import Home from "./common/home/Home";
import Login from "./common/home/Login";
import PatientDashboard from "./patient/dashboard";
import PatientProfileSettings from "./patient/PatientProfileSettings";
import PatientDependent from "./patient/PatientDependent";
import PatientMedicalRecords from "./patient/PatientMedicalRecords";
import PasswordChange from "./patient/PasswordChange";
import Patientregisterstepone from "./patient/register/patientregisterstepone";
import Patientregistersteptwo from "./patient/register/patientregistersteptwo";
import Patientregisterstepthree from "./patient/register/patientregisterstepthree";
import Registerstepone from "./doctor/register/registerstepone";
import Registersteptwo from "./doctor/register/registersteptwo";
import Registerstepthree from "./doctor/register/registerstepthree";
import DoctorDashboard from "./doctor/DoctorDashboard";
import DoctorProfileSettings from "./doctor/DoctorProfileSettings";
import DoctorPasswordChange from "./doctor/DoctorChangePassword";
import ScheduleTiming from "./doctor/ScheduleTiming";
import AvailableTiming from "./doctor/AvailabilityTiming";
import Dashboard from "./admin/components/dashboard/dashboard";
import Specialities from "./admin/components/specialities";
import Doctors from "./admin/components/doctors";
import Patients from "./admin/components/patients";
import Finance from "./admin/components/finance";
import AvailabilityCalendar from "./doctor/AvailabilityCalendar";
import SearchDoctor from "./doctor/SearchDoctor/searchDoctor";
import { useAuth } from "react-oidc-context";
import Contactus from "./common/home/contactus";
import PatientBookAppointment from "./patient/PatientBookAppointment";
import DoctorSearchComponent from "./patient/DoctorSearchComponent";
import MyPatient from "./doctor/MyPatients";
import PatientProfile from "./doctor/PatientProfile";
import RecordType from "./admin/components/recordType";
import Conditions from "./common/home/conditions";
import Politiques from "./common/home/politiques";
import PatientSearchComponent from "./doctor/SearchPatientComponent";

const AppContainer = function () {
  const auth = useAuth();
  useEffect(() => {
    const handleMouseMove = (event) => {
      const cursorInner: FixMeLater = document.querySelector(".cursor-inner");
      const cursorOuter: FixMeLater = document.querySelector(".cursor-outer");

      if (cursorOuter) {
        cursorOuter.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
      }

      if (cursorInner) {
        cursorInner.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
      }
    };

    const handleMouseEnter = () => {
      const cursorInner: FixMeLater = document.querySelector(".cursor-inner");
      const cursorOuter: FixMeLater = document.querySelector(".cursor-outer");

      if (cursorInner) {
        cursorInner.classList.add("s");
      }

      if (cursorOuter) {
        cursorOuter.classList.add("s");
      }
    };

    const handleMouseLeave = (event) => {
      const cursorInner: FixMeLater = document.querySelector(".cursor-inner");
      const cursorOuter: FixMeLater = document.querySelector(".cursor-outer");

      if (
        event.target.tagName !== "A" ||
        !event.target.closest(".cursor-pointer")
      ) {
        if (cursorInner) {
          cursorInner.classList.remove("cursor-hover");
        }

        if (cursorOuter) {
          cursorOuter.classList.remove("cursor-hover");
        }
      }
    };

    document.body.addEventListener("mousemove", handleMouseMove);
    document.body.addEventListener("mouseenter", handleMouseEnter);
    document.body.addEventListener("mouseleave", handleMouseLeave);

    const cursorInner: FixMeLater = document.querySelector(".cursor-inner");
    const cursorOuter: FixMeLater = document.querySelector(".cursor-outer");

    if (cursorInner) {
      cursorInner.style.visibility = "visible";
    }

    if (cursorOuter) {
      cursorOuter.style.visibility = "visible";
    }

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
      document.body.removeEventListener("mouseenter", handleMouseEnter);
      document.body.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Dashboard />}></Route>
        <Route path="/admin-specialities" element={<Specialities />} />
        <Route path="/admin-doctors" element={<Doctors />} />
        <Route path="/admin-patients" element={<Patients />} />
        <Route path="/admin-medical" element={<RecordType />} />
        <Route path="/admin-finances" element={<Finance />} />
        <Route path="/connexion" element={<Login />}></Route>
        <Route path="/doctorsList" element={<SearchDoctor />}></Route>
        <Route path="/dashboard-patient" element={<PatientDashboard />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route path="/terms" element={<Conditions />}></Route>
        <Route path="/privacy-policy" element={<Politiques />}></Route>
        <Route
          path="/infos-patient"
          element={<PatientProfileSettings />}
        ></Route>
        <Route
          path="/patient-book-rv"
          element={<PatientBookAppointment />}
        ></Route>
        <Route path="/mes-patients" element={<MyPatient />}></Route>
        <Route
          path="/recherche-patient"
          element={<PatientSearchComponent />}
        ></Route>
        <Route path="/patient-profile" element={<PatientProfile />}></Route>
        <Route path="/recherche-docteur" element={<SearchDoctor />}></Route>
        <Route
          path="/patient-dependants"
          element={<PatientDependent />}
        ></Route>
        <Route
          path="/patient-dossiermedical"
          element={<PatientMedicalRecords />}
        ></Route>
        <Route path="/patient-changepass" element={<PasswordChange />}></Route>

        {/* Profile completion */}
        <Route
          path="/patientregisterstep-1"
          element={<Patientregisterstepone />}
        />
        <Route
          path="/patientregisterstep-2"
          element={<Patientregistersteptwo />}
        />
        <Route
          path="/patientregisterstep-3"
          element={<Patientregisterstepthree />}
        />

        <Route path="/register-step-1" element={<Registerstepone />} />
        <Route path="/register-step-2" element={<Registersteptwo />} />
        <Route path="/register-step-3" element={<Registerstepthree />} />
        {/* //Profile completion */}

        {/* Doctor */}
        <Route path="/dashboard-docteur" element={<DoctorDashboard />}></Route>
        <Route
          path="/infos-docteur"
          element={<DoctorProfileSettings />}
        ></Route>
        <Route
          path="/calendrier-docteur"
          element={<AvailabilityCalendar />}
        ></Route>
        <Route
          path="/medecin-changepass"
          element={<DoctorPasswordChange />}
        ></Route>
        <Route
          path="/medecin-disponibilité"
          element={<ScheduleTiming />}
        ></Route>
        <Route path="/medecin-calendrier" element={<AvailableTiming />}></Route>
        {/* // Doctor */}
      </Routes>
    </Router>
  );
};

export default AppContainer;
