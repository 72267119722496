import { default_profile } from "../common/imagepath";

import { Link } from "react-router-dom";
import { IPatient } from "../models/backend";

const PatientSidebar = (props) => {
  const patient = props.patient as IPatient;
  const lastTwoAppointments =
    patient?.appointments?.length > 1
      ? [
          patient?.appointments[patient?.appointments.length - 1],
          patient?.appointments[patient?.appointments.length - 2],
        ]
      : [];

  return (
    <>
      <div className="card widget-profile pat-widget-profile">
        <div className="card-body">
          <div className="pro-widget-content">
            <div className="profile-info-widget">
              <Link to="#0" className="booking-doc-img">
                <img
                  src={patient?.logoPath ? patient?.logoPath : default_profile}
                  alt="User"
                />
              </Link>
              <div className="profile-det-info">
                <h3>{`${patient?.firstName} ${patient?.lastName}`}</h3>

                <div className="patient-details">
                  <h5>
                    <b>ID Patient :</b> {patient?.id}
                  </h5>
                  <h5 className="mb-0">
                    <i className="fas fa-map-marker-alt"></i>
                    {patient?.patientAddress?.addressLine1
                      ? `${patient?.patientAddress?.addressLine1}, ${patient?.patientAddress?.city}, ${patient?.patientAddress?.country}`
                      : "Aucune adresse fournie"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="patient-info">
            <ul>
              <li>
                Telephone <span>{patient?.phoneNumber}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Last Booking */}
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Dernier rendez-vous</h4>
        </div>
        <ul className="list-group list-group-flush">
          {lastTwoAppointments.map((app) => {
            return (
              <li className="list-group-item">
                <div className="media align-items-center d-flex">
                  <div className="me-3 flex-shrink-0">
                    <img
                      alt="Image placeholder"
                      src={
                        app.doctor?.logoPath
                          ? app.doctor?.logoPath
                          : default_profile
                      }
                      className="avatar  rounded-circle"
                    />
                  </div>
                  <div className="media-body flex-grow-1">
                    <h5 className="d-block mb-0">
                      Dr. {`${app.doctor.firstName} ${app.doctor.lastName}`}{" "}
                    </h5>
                    <span className="d-block text-sm text-muted">
                      ${app.doctor.specialities?.join(", ")}
                    </span>
                    <span className="d-block text-sm text-muted">
                      {app.date}
                    </span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {/* /Last Booking */}
    </>
  );
};

export default PatientSidebar;
