import {
  IAppointment,
  ICalendarEvent,
  IDoctor,
  IPatient,
} from "../models/backend";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import frLocale from "@fullcalendar/core/locales/fr";
import { getCurrentUTCTime, isObjectEmptyOrUndefined } from "../utils/objUtils";
import interaction from "@fullcalendar/interaction";
import "../assets/DiiwdeStyling.css";
import { createEmptyAppointment } from "../utils/createModels";
import uuid from "react-uuid";
import { PatientContext } from "./PatientContext";
import { PatientBookEditAppointmentModal } from "./PatientAppointmentReserveModal";
import { useNotification } from "../common/NotificationContext";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { useAuth } from "react-oidc-context";
import { FixMeLater } from "../tsmigration";
import { DoctorService } from "../services/doctorService";
import { AppointmentService } from "../services/appointmentService";
import { useNavigate } from "react-router-dom";

interface ICalendarProps {
  currentDoctor: IDoctor;
  currentPatient: IPatient;
}

const today = new Date();
today.setHours(0, 0, 0, 0);

const getEndRecurrenceDay = () => {
  const sixWeeksLater = new Date(today);
  sixWeeksLater.setDate(today.getDate() + 42);

  return sixWeeksLater;
};

const PatientScheduleView: React.FC<ICalendarProps> = (props: {
  currentDoctor: IDoctor;
  currentPatient: IPatient;
}) => {
  const auth = useAuth();
  const doctorService = new DoctorService();
  const appointmentService = new AppointmentService();

  const [isLoading, setIsLoading] = useState(true);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const [currentPatient, setCurrentPatient] = useState<IPatient>(
    {} as IPatient
  );

  const [doctorBusySlots, setDoctorBusySlots] = useState<ICalendarEvent[]>([]);

  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);

  useEffect(() => {
    const fetchPatientData = async () => {
      setIsLoading(true);
      const id = auth.user?.profile["email"] || "";
      const patientData = await getPatientData(id);
      const busySlots = await doctorService.getDoctorBusySpots(
        props.currentDoctor.id
      );
      setCurrentPatient(patientData);
      setDoctorBusySlots(busySlots);
      const plannedEvents = formatCalendarEvents(busySlots);
      setEvents(plannedEvents);

      setIsLoading(false);
    };
    fetchPatientData();
  }, [
    getPatientData,
    JSON.stringify(props.currentDoctor),
    JSON.stringify(props.currentPatient),
  ]);

  const formatCalendarEvents = (plannedAppointments: FixMeLater[]) => {
    const appointmentEvents =
      isObjectEmptyOrUndefined(plannedAppointments) ||
      plannedAppointments.length === 0
        ? []
        : plannedAppointments.map((spot: FixMeLater) => ({
            title: `Indisponible`,
            start: new Date(spot.startTime),
            end: new Date(spot.endTime),
            backgroundColor: "red",
            // borderColor: "darkred",
            extendedProps: {
              type: "appointment",
            },
          }));

    return appointmentEvents;
  };

  const doctorBusinessHours = isObjectEmptyOrUndefined(
    props.currentDoctor.availabilities
  )
    ? []
    : props.currentDoctor.availabilities.map((avail) => {
        const dayOfWeek = avail.day;
        if (dayOfWeek !== undefined) {
          const startTime = avail.startTime;
          const endTime = avail.endTime;

          return {
            daysOfWeek: [dayOfWeek],
            startTime: startTime,
            endTime: endTime,
          };
        }
      });

  function getMinMaxTimes(businessHours) {
    let minStartTime = "23:59";
    let maxEndTime = "00:00";

    businessHours.forEach((hour) => {
      if (hour.startTime < minStartTime) {
        minStartTime = hour.startTime;
      }
      if (hour.endTime > maxEndTime) {
        maxEndTime = hour.endTime;
      }
    });

    return { minStartTime, maxEndTime };
  }

  const { minStartTime, maxEndTime } = getMinMaxTimes(doctorBusinessHours);

  const formatSlotDuration = (duration: number) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00`;
  };

  const handleDateClick = (arg) => {
    arg.view.calendar.changeView("timeGridDay", arg.dateStr);
  };

  const calendarSlotDuration = formatSlotDuration(
    props.currentDoctor.appointmentDuration > 0
      ? props.currentDoctor.appointmentDuration
      : 30
  );

  const hasFreeSlotsForDay = (day: Date): boolean => {
    const startTime = new Date(day);
    const endTime = new Date(day);
    startTime.setHours(0, 0, 0, 0);
    endTime.setHours(23, 59, 59, 999);

    // RV du jour
    const eventsForDay = events.filter(
      (event) =>
        new Date(event.start) >= startTime && new Date(event.end) <= endTime
    );

    // horaires du jour
    const dayOfWeek = day.getDay();
    const businessHoursForDay = doctorBusinessHours.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );

    if (!businessHoursForDay) {
      return false;
    }

    const nbAcceptableAppointmentsInDay = calculateMaxAppointmentsPerDay(
      businessHoursForDay.startTime,
      businessHoursForDay.endTime,
      props.currentDoctor.appointmentDuration
    );
    const availableSlots = nbAcceptableAppointmentsInDay - eventsForDay.length;

    return availableSlots > 0;
  };

  const calculateMaxAppointmentsPerDay = (
    startTime: string,
    endTime: string,
    appointmentDuration: number
  ): number => {
    const startHourMinutes = startTime.split(":").map(Number);
    const endHourMinutes = endTime.split(":").map(Number);

    const startMinutes = startHourMinutes[0] * 60 + startHourMinutes[1];
    const endMinutes = endHourMinutes[0] * 60 + endHourMinutes[1];

    // tempos en minutes de la disponibilite
    const totalDuration = endMinutes - startMinutes;

    // Nb max RV par jour
    const maxAppointmentsPerDay = Math.floor(
      totalDuration / appointmentDuration
    );

    return maxAppointmentsPerDay;
  };

  const renderDayCellContent = (arg) => {
    const day = arg.date;
    const isFreeSlotsAvailable = hasFreeSlotsForDay(day);

    const text = isFreeSlotsAvailable
      ? "Rendez-vous disponible"
      : "Aucune disponibilité";

    if (arg?.view?.type === "dayGridMonth")
      return (
        <div className="text-end align-text-bottom">
          {" "}
          {arg.dayNumberText} <br></br> {text}
        </div>
      );
    else return null;
  };

  const [appointmentToCreate, setAppointmentToCreate] =
    useState<IAppointment | null>(null);

  const [showCreateAppointmentDialog, setshowCreateAppointmentDialog] =
    useState(false);

  const handleCloseCreateAppointmentDialog = () => {
    resetSelectedSlotInformation();
    setshowCreateAppointmentDialog(false);
  };

  const handleShowCreateAppointmentDialog = () => {
    setshowCreateAppointmentDialog(true);
  };

  const resetSelectedSlotInformation = () => {
    setAppointmentToCreate(null);
  };

  const handleCreateAppointmentDialogSubmit = async (
    newAppointment: IAppointment
  ) => {
    console.log("Create Appointment", newAppointment);
    try {
      const appointmentCreated = await appointmentService.addAppointment(
        newAppointment
      );

      const updatedPatient = {
        ...props.currentPatient,
        appointments: [...props.currentPatient.appointments, newAppointment],
      };
      await setPatientData(updatedPatient);
      if (appointmentCreated) {
        showNotification(
          "Rendez-vous réservé avec succès",
          NOTIFICATION_TYPE.INFO
        );
        navigate("/dashboard-patient");
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "Erreur survenue pendant la création du Rendez-vous",
        NOTIFICATION_TYPE.ERROR
      );
    }
  };

  const datesAreEqual = (date1, date2) => {
    const areDatesEqual =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    const areTimesEqual =
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes();

    return areDatesEqual && areTimesEqual;
  };

  const handleSlotSelect = (info) => {
    const date = info.start;
    if (!isSlotInBusinessHour(date)) {
      console.log("Slot out of availability range");
      showNotification(
        "Créneau de Rendez vous indisponible",
        NOTIFICATION_TYPE.ERROR
      );
    } else {
      const overlappingAppointment = checkIfAppointmentInSlot(info);

      if (!overlappingAppointment) {
        const newAppointmentDateTime = new Date(date);
        const now = getCurrentUTCTime();
        if (newAppointmentDateTime < now) {
          let calendarApi = info.view.calendar;
          calendarApi.unselect();
          showNotification(
            "Ce créneau est passé. Merci de prendre un Rendez-vous plus tard",
            NOTIFICATION_TYPE.ERROR
          );
          return;
        }
        const newAppointment: IAppointment = createEmptyAppointment();
        newAppointment.date = newAppointmentDateTime;
        newAppointment.id = uuid();
        newAppointment.doctor = { ...props.currentDoctor };
        newAppointment.patient = { ...props.currentPatient };

        setAppointmentToCreate(newAppointment);
        handleShowCreateAppointmentDialog();
      } else {
        showNotification(
          "Créneau de Rendez vous indisponible",
          NOTIFICATION_TYPE.ERROR
        );
      }
    }
  };
  const checkIfAppointmentInSlot = (slot) => {
    return doctorBusySlots.find((appointment) => {
      return datesAreEqual(slot.start, new Date(appointment.startTime));
    });
  };

  const isSlotInBusinessHour = (date: Date): boolean => {
    const dayOfWeek = date.getDay();
    const businessHoursForDay = doctorBusinessHours.find((hours) =>
      hours.daysOfWeek.includes(dayOfWeek)
    );

    if (!businessHoursForDay) {
      return false;
    }
    return true;
  };

  const [events, setEvents] = useState([]);

  return (
    <>
      <div className="col-md-7 col-lg-8 col-xl-9">
        <Container className="mt-5">
          <h3 className="text-center">
            Calendrier Dr. {props.currentDoctor.firstName}
            {props.currentDoctor.lastName}
          </h3>
          <FullCalendar
            height="auto"
            contentHeight="auto"
            plugins={[
              interaction,
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
            ]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            events={events}
            selectable={true}
            locale={frLocale}
            slotDuration={calendarSlotDuration}
            navLinks={true}
            slotMinTime={minStartTime}
            slotMaxTime={maxEndTime}
            allDaySlot={false}
            businessHours={doctorBusinessHours}
            selectConstraint={doctorBusinessHours}
            timeZone="UTC" // Setting timezone to UTC
            validRange={{
              start: getCurrentUTCTime(),
              end: getEndRecurrenceDay(),
            }}
            select={handleSlotSelect}
            dateClick={handleDateClick}
            dayCellContent={renderDayCellContent}
          />
          {appointmentToCreate && (
            <>
              <PatientBookEditAppointmentModal
                show={showCreateAppointmentDialog}
                handleClose={handleCloseCreateAppointmentDialog}
                appointment={appointmentToCreate}
                dialogTitle={"Prise de Rendez vous"}
                onSubmit={(newAppointment: IAppointment) =>
                  handleCreateAppointmentDialogSubmit(newAppointment)
                }
                selectedDoctor={props.currentDoctor}
              ></PatientBookEditAppointmentModal>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default PatientScheduleView;
