import React, { createContext, useState, useCallback, ReactNode } from "react";
import { IAppointment, IDoctor, IPatient } from "../models/backend";
import { convertAppointmentDates, getDoctorPatients } from "../utils/objUtils";
import { DoctorService } from "../services/doctorService";
import { AppointmentService } from "../services/appointmentService";
import { PatientService } from "../services/patientService";

interface DoctorContextType {
  doctor: IDoctor | null;
  getDoctorData: (id: string) => Promise<IDoctor | null>;
  setDoctorData: (user: IDoctor) => Promise<void>;
}

// Create the context with a default value
export const DoctorContext = createContext<DoctorContextType>({
  doctor: null,
  getDoctorData: async (id: string) => null,
  setDoctorData: async () => {},
});

interface DoctorProviderProps {
  children: ReactNode;
}

// Create a provider component
export const DoctorUserProvider: React.FC<DoctorProviderProps> = ({
  children,
}) => {
  const [doctor, setDoctor] = useState<IDoctor | null>(null);
  const doctorService = new DoctorService();
  const appointmentService = new AppointmentService();
  const patientService = new PatientService();

  const loadDoctorDataFromBackend = async (
    id: string
  ): Promise<IDoctor | null> => {
    if (!id) {
      return null;
    } else {
      try {
        let doctorData: IDoctor;
        let appointmentList: IAppointment[] = [];
        // let doctorPatientsList: IPatient[] = [];

        await Promise.all([
          (doctorData = await doctorService.getDoctorAsync(id)),
          (appointmentList =
            await appointmentService.getAllAppointmentsByDoctor(id)),
          // (patientList = await patientService.getAllPatientAsync()),
          // (doctorPatientsList = await doctorService.getAllPatientsAsync(id)),
        ]);

        if (doctorData) {
          doctorData.appointments = appointmentList;
          doctorData.authorizedRecords = [];
          doctorData.patients = getDoctorPatients(appointmentList);
        }

        return doctorData;
      } catch (error) {
        console.error("Erreur:", error);
        throw error;
      }
    }
  };
  // Function to fetch user data from the API and save it to the context
  const getDoctorData = useCallback(
    async (id: string): Promise<IDoctor | null> => {
      try {
        const storedDoctorData = localStorage.getItem("doctorData");
        let doctorData: IDoctor;
        if (storedDoctorData) {
          const parsedDoctorData = JSON.parse(storedDoctorData) as IDoctor;
          parsedDoctorData.authorizedRecords = [];
          const doctorDataWithDates = convertAppointmentDates(parsedDoctorData);
          doctorData = { ...doctorDataWithDates };
        } else {
          doctorData = (await loadDoctorDataFromBackend(id)) || ({} as IDoctor);
          localStorage.setItem("doctorData", JSON.stringify(doctorData)); // Save to localStorage
        }
        return doctorData;
      } catch (error) {
        console.error("Erreur:", error);
        return null;
      }
    },
    []
  );

  const setDoctorData = async (newDoctorState: IDoctor): Promise<void> => {
    const id = newDoctorState?.id;
    const doctorData = await loadDoctorDataFromBackend(id);
    setDoctor(doctorData);
    localStorage.setItem("doctorData", JSON.stringify(doctorData));
  };

  const contextValue = {
    doctor,
    getDoctorData,
    setDoctorData,
  };

  return (
    <DoctorContext.Provider value={contextValue}>
      {children}
    </DoctorContext.Provider>
  );
};
