import Cookies from "js-cookie";

export class TokenHelpers {
  getToken = () => {
    return Cookies.get("token");
  };

  setToken = (token: string) => {
    Cookies.set("token", token, { expires: 1, secure: true });
  };
}
