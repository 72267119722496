export const NOTIFICATION_TYPE = {
  INFO: "info",
  WARNING: "Attention",
  ERROR: "Erreur",
  SUCCESS: "Succès",
};

export interface ShowSnackbarProps {
  message: string;
  notificationType: string;
}
