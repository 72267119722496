export interface IPersonelInfos {
  firstName: string;
  lastName: string;
  birthDate: Date;
  phoneNumber?: string;
  streetAddress?: string;
  city?: string;
}

export type FamilyMemberType =
  (typeof FAMILY_MEMBER_TYPE)[keyof typeof FAMILY_MEMBER_TYPE];

export interface IPatientDependents {
  id?: string;
  relationShip: FamilyMemberType;
  personalInfos: IPersonelInfos;
}

// May be needed in the future
// export type FAMILY_MEMBER_TYPE =
//   | "Femme"
//   | "Mari"
//   | "Fils"
//   | "Fille"
//   | "Père"
//   | "Mère"
//   | "Grand-Parent"
//   | "Petit-enfant"
//   | "Soeur"
//   | "Frère";

export enum FAMILY_MEMBER_TYPE {
  WIFE = "Femme",
  HUSBAND = "Mari",
  SON = "Fils",
  DAUGHTER = "Fille",

  FATHER = "Père",
  MOTHER = "Mère",
  GRAND_PARENT = "Grand parent",
  GRAND_SON = "Petit enfant",

  SISTER = "Soeur",
  Brother = "Frère",
}
