import { IAppointment } from "../models/backend";
import {
  AppointmentRepository,
  IAppointmentRepository,
} from "../repositories/appointmentRepository";
import { FixMeLater } from "../tsmigration";

interface IAppointmentService {
  getAllAppointments(): Promise<FixMeLater[]>;
  addAppointment(appointment: IAppointment): Promise<boolean>;
  editAppointment(appointment: IAppointment): Promise<boolean>;
  deleteAppointment(appointmentId: string): Promise<boolean>;
  getAllAppointmentsByDoctor(doctorId: string): Promise<IAppointment[]>;
  getAllAppointmentsByPatient(patientId: string): Promise<IAppointment[]>;
}

class AppointmentService implements IAppointmentService {
  private _appointmentRepo: IAppointmentRepository;

  /**
   *Repository that will handle the request for the doctors
   */
  constructor() {
    this._appointmentRepo = new AppointmentRepository();
  }
  async getAllAppointments(): Promise<FixMeLater[]> {
    return await this._appointmentRepo.getAllAppointments();
  }
  async addAppointment(appointment: IAppointment): Promise<boolean> {
    return await this._appointmentRepo.addAppointment(appointment);
  }
  async editAppointment(appointment: IAppointment): Promise<boolean> {
    return await this._appointmentRepo.editAppointment(appointment);
  }
  async deleteAppointment(appointmentId: string): Promise<boolean> {
    return await this._appointmentRepo.deleteAppointment(appointmentId);
  }
  async getAllAppointmentsByDoctor(doctorId: string): Promise<IAppointment[]> {
    return await this._appointmentRepo.getAllAppointmentsByDoctor(doctorId);
  }
  async getAllAppointmentsByPatient(
    patientId: string
  ): Promise<IAppointment[]> {
    return await this._appointmentRepo.getAllAppointmentsByPatient(patientId);
  }
}

export { AppointmentService, IAppointmentService };
