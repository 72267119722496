import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import Header from "../common/header";
import Footer from "../common/home/footer";
import DashboardSidebar from "./sidebar";
import { useAuth } from "react-oidc-context";
import { PatientContext } from "./PatientContext";
import { useContext, useEffect, useState } from "react";
import { IDossierMedical } from "../models/backend";
import PulseLoader from "react-spinners/PulseLoader";
import { isObjectEmptyOrUndefined } from "../utils/objUtils";
import MedicalRecordsList from "../doctor/MedicalRecordsList";

const PatientMedicalRecords = () => {
  const auth = useAuth();
  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);

  const [isLoading, setIsLoading] = useState(false);
  const [medicalRecords, setMedicalRecords] = useState<IDossierMedical[]>();
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    const fetchPatientData = async () => {
      setIsLoading(true);

      const id = auth.user?.profile["email"] || "";
      const patientData = await getPatientData(id);
      const currentMedicalRecords = patientData.medicalRecords || [];
      setMedicalRecords(currentMedicalRecords);
      setIsLoading(false);
    };
    fetchPatientData();
  }, [getPatientData]);

  return (
    <>
      <Header profileCompleted={patient?.profileCompleted} />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Dossier médical</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dossier médical
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Breadcrumb -->     */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar />
              </StickyBox>
            </div>{" "}
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
                <div className="card-body pt-0">
                  <h2>Dossier médical</h2>
                </div>
                {isLoading ? (
                  <div style={{ margin: "auto", width: "10%" }}>
                    <PulseLoader color="#0e82fd" />
                  </div>
                ) : (
                  !isObjectEmptyOrUndefined(medicalRecords) && (
                    <>
                      <MedicalRecordsList
                        activePage={activePage}
                        onPageChange={handlePageChange}
                        records={medicalRecords}
                        isCurrentUserOwner={true}
                        medicalRecords={medicalRecords}
                      />
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PatientMedicalRecords;
