import { Link } from "react-router-dom";
import { default_profile } from "../common/imagepath";
import { IAppointment } from "../models/backend";
import { useContext, useState } from "react";
import { DoctorAppointmentDetails } from "./DoctorAppointmentDetails";
import { useNotification } from "../common/NotificationContext";
import ConfirmationDialog from "../common/shared-components/ConfirmationDialog";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { AppointmentService } from "../services/appointmentService";
import { FixMeLater } from "../tsmigration";
import { DoctorContext } from "./DoctorContext";
import DisplayAppointmentTimeAndDate from "../common/shared-components/DisplayAppointmentTimeAndDateInTable";
import { OnSortChange, SortConfig } from "../utils/filterAndSearchUtils";
import SortArrow from "../common/shared-components/SortArrow";

const UpcomingAppointmentsTab = (props: {
  upcomingAppointmentList: IAppointment[];
  sortConfig: SortConfig;
  onSortChange: OnSortChange;
}) => {
  const appointmentService = new AppointmentService();
  const { showNotification } = useNotification();
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);

  const handleSortChange = (key) => {
    const direction =
      props.sortConfig &&
      props.sortConfig.key === key &&
      props.sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    props.onSortChange({ key, direction });
  };

  const [selectedAppointment, setSelectedAppointment] = useState(
    {} as IAppointment
  );
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const handleCloseDetailsDialog = () => setShowDetailsDialog(false);
  const handleShowDetailsDialog = () => setShowDetailsDialog(true);
  const handleOpenAppointmentDetails = (appointmentId: string) => {
    const currentAppointment: IAppointment = props.upcomingAppointmentList.find(
      (item) => item.id === appointmentId
    );
    setSelectedAppointment(currentAppointment);
    handleShowDetailsDialog();
  };

  // delete appointment
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] =
    useState<IAppointment | null>(null);

  const handleCancelAppointment = (appointment: IAppointment) => {
    setAppointmentToDelete(appointment);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (appointmentToDelete) {
        const appointmentId: string = appointmentToDelete.id as string;
        console.log(`Appointment with id ${appointmentId} has been deleted`);
        const deleted = await appointmentService.deleteAppointment(
          appointmentId
        );
        if (deleted) {
          const newAppointmentList = props.upcomingAppointmentList.filter(
            (appointment) => appointment.id === appointmentId
          );
          const updatedDoctor = {
            ...doctor,
            appointments: [...newAppointmentList],
          };
          await setDoctorData(updatedDoctor);

          showNotification(
            "Rendez vous supprimé avec succès",
            NOTIFICATION_TYPE.INFO
          );
        } else {
          showNotification(
            "Erreur lors de la suppression du rendez vous. Réessayez",
            NOTIFICATION_TYPE.ERROR
          );
        }
        setDeleteDialogOpen(false);
        setAppointmentToDelete(null);
      }
    } catch (error: FixMeLater) {
      console.error(error);
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        "Erreur lors de la suppression du rendez vous ";

      showNotification(errorMessage, NOTIFICATION_TYPE.ERROR);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setAppointmentToDelete(null);
  };
  return (
    <>
      <div className="card card-table mb-0">
        <div className="card-body">
          <div className="table-responsive">
            {props.upcomingAppointmentList.length > 0 ? (
              <>
                <table className="table table-hover table-center mb-0">
                  <thead style={{ borderBottom: "none" }}>
                    <tr>
                      <th onClick={() => handleSortChange("patient.firstName")}>
                        Nom du patient
                        {props.sortConfig?.key === "patient.firstName" && (
                          <SortArrow direction={props.sortConfig.direction} />
                        )}
                      </th>
                      <th onClick={() => handleSortChange("date")}>
                        Date{" "}
                        {props.sortConfig?.key === "date" && (
                          <SortArrow direction={props.sortConfig.direction} />
                        )}
                      </th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: "none" }}>
                    {props.upcomingAppointmentList.map((appointment) => (
                      <tr key={appointment.id}>
                        <td>
                          <h2 className="table-avatar">
                            <Link
                              to="/patient-profile"
                              className="avatar avatar-sm me-2"
                            >
                              <img
                                className="avatar-img rounded-circle"
                                src={
                                  appointment.patient?.logoPath
                                    ? appointment.patient?.logoPath
                                    : default_profile
                                }
                                alt="User Image"
                              />
                            </Link>
                            <Link to="/patient-profile">
                              {appointment.patient.firstName}{" "}
                              {appointment.patient.lastName}
                              <span>{appointment.patient.id}</span>
                            </Link>
                          </h2>
                        </td>
                        <td>
                          <DisplayAppointmentTimeAndDate
                            appointment={appointment}
                          ></DisplayAppointmentTimeAndDate>
                        </td>
                        <td className="text-end">
                          <div className="table-action text-center">
                            <Link
                              to="#"
                              className="btn btn-sm bg-info-light"
                              onClick={() =>
                                handleOpenAppointmentDetails(appointment.id)
                              }
                              style={{ marginRight: "5px" }}
                            >
                              <i className="far fa-eye" /> Voir
                            </Link>

                            <Link
                              to="#"
                              className="btn btn-sm bg-danger-light"
                              onClick={() =>
                                handleCancelAppointment(appointment)
                              }
                              style={{ marginRight: "5px" }}
                            >
                              <i className="far fa-eye" /> Annuler
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <h3 className="mb-4 text-center"> Aucun Rendez vous</h3>
            )}
          </div>
        </div>
      </div>

      <DoctorAppointmentDetails
        show={showDetailsDialog}
        handleClose={handleCloseDetailsDialog}
        appointmentDetails={selectedAppointment}
      ></DoctorAppointmentDetails>
      {appointmentToDelete && (
        <ConfirmationDialog
          open={deleteDialogOpen}
          title="Suppression du rendez-vous"
          message="Êtes-vous sur de vouloir supprimer ce Rendez vous?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </>
  );
};

export default UpcomingAppointmentsTab;
