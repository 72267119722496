export function getEnumKeyFromValue<T>(
  enumObject: T,
  value: string
): keyof T | undefined {
  const keys = Object.keys(enumObject) as (keyof T)[];
  for (const key of keys) {
    if (enumObject[key] === value) {
      return key;
    }
  }
  return undefined; // If no match is found
}
