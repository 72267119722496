import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import Header from "../common/header";
import Footer from "../common/home/footer";
import DashboardSidebar from "./sidebar";
import { useContext, useState } from "react";
import { PatientContext } from "./PatientContext";

const PasswordChange = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { selectedAccount } = useContext(PatientContext);

  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const ERROR_MESSAGES = {
    PASSWORD_LENGTH: "Le mot de passe doit contenir au moins 6 caracteres",
    PASSWORD_REGEX:
      "Le mot de passe doit contenir au moins un caractere minuscule, majuscule et un chiffre",
    NO_MATCH_PASSWORD: "Les mots de passe ne correspondent pas",
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    if (formData.newPassword.length < 6) {
      newErrors.newPassword = ERROR_MESSAGES.PASSWORD_LENGTH;
      valid = false;
    } else {
      newErrors.newPassword = "";
    }

    if (
      !/[a-z]/.test(formData.newPassword) ||
      !/[A-Z]/.test(formData.newPassword) ||
      !/[0-9]/.test(formData.newPassword)
    ) {
      newErrors.newPassword = ERROR_MESSAGES.PASSWORD_REGEX;
      valid = false;
    } else {
      newErrors.newPassword = "";
    }

    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = ERROR_MESSAGES.NO_MATCH_PASSWORD;
      valid = false;
    } else {
      newErrors.confirmPassword = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitPasswordChange = (e: React.FormEvent) => {
    // TODO: implement password change logic here.
    e.preventDefault();
    if (validateForm()) {
      console.log("Valeur de la form", formData);
    } else {
      console.error("Erreur lors de la soumission");
    }
  };
  return (
    <>
      <Header profileCompleted={false} />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Mot de passe</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Changer mot de passe
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Breadcrumb -->     */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar />
              </StickyBox>
            </div>{" "}
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
                <div className="card-body pt-0">
                  {selectedAccount.isDependent ? (
                    <>
                      <br></br>
                      <h3 className="text-center">
                        Veuillez sélectionner le titulaire du compte pour
                        changer le mot de passe
                      </h3>
                    </>
                  ) : (
                    <form onSubmit={handleSubmitPasswordChange}>
                      <div className="form-group">
                        <label htmlFor="currentPassword">
                          Mot de passe actuel
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="currentPassword"
                          defaultValue={formData.currentPassword}
                          onChange={handleChange}
                          required
                        />
                        <span style={{ color: "red" }}>
                          {errors.currentPassword}
                        </span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="newPassword">
                          Nouveau mot de passe
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="newPassword"
                          defaultValue={formData.newPassword}
                          onChange={handleChange}
                          required
                        />
                        <span style={{ color: "red" }}>
                          {errors.newPassword}
                        </span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirmPassword">
                          Confirmer le mot de passe
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          defaultValue={formData.confirmPassword}
                          onChange={handleChange}
                          required
                        />
                        <span style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </span>
                      </div>
                      <div className="submit-section text-center">
                        <button
                          type="submit"
                          className="btn btn-outline-primary submit-btn"
                        >
                          Sauvegarder
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PasswordChange;
