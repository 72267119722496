import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IAppointment, IDoctor } from "../models/backend";
import { isObjectEmptyOrUndefined } from "../utils/objUtils";
import { FixMeLater } from "../tsmigration";
import { createEmptyAppointment } from "../utils/createModels";

export const PatientBookEditAppointmentModal = ({
  show,
  handleClose,
  appointment,
  dialogTitle,
  selectedDoctor,
  onSubmit,
}: {
  show: boolean;
  handleClose: () => void;
  appointment: IAppointment;
  dialogTitle: string;
  selectedDoctor: IDoctor;
  onSubmit: (data: IAppointment) => void;
}) => {
  const emptyAppointment: IAppointment = createEmptyAppointment();

  const initialState = isObjectEmptyOrUndefined(appointment)
    ? emptyAppointment
    : { ...appointment };

  const [appointmentData, setAppointmentData] = useState<IAppointment>({
    ...initialState,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Submitting form data with appointment:", appointmentData);
    await onSubmit(appointmentData);
    setAppointmentData(emptyAppointment);
    handleClose();
  };

  const handleChange = (e: FixMeLater) => {
    const { name, value } = e.target;
    setAppointmentData({
      ...appointmentData,
      [name]: value,
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal"
        style={{ marginTop: "80px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{dialogTitle}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {appointmentData && (
              <form onSubmit={handleSubmit}>
                <h3 className="text-center">
                  Rendez vous avec Dr {selectedDoctor.firstName}{" "}
                  {selectedDoctor.lastName}
                </h3>
                <h5 className="text-center">
                  {selectedDoctor.specialities.map((x) => x.title).join(" - ")}
                </h5>
                <div className="form-group">
                  <label htmlFor="appointmentDate">Date et heure</label>
                  <input
                    type="text"
                    id="appointmentDate"
                    className="form-control"
                    readOnly
                    value={
                      appointmentData.date
                        ? new Date(appointmentData.date).toLocaleString(
                            "fr-FR",
                            { timeZone: "UTC" }
                          )
                        : ""
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="title" className="col-sm-2 col-form-label">
                    Notes du patient:
                  </label>
                  <textarea
                    className="form-control"
                    name="patientNote"
                    value={appointmentData.patientNote}
                    onChange={handleChange}
                    required
                    placeholder="Notes et observations"
                    rows={5}
                    style={{ overflowY: "hidden" }}
                    onInput={(e: FixMeLater) => {
                      e.target.style.height = "auto";
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                  />
                </div>
                <div className="modal-footer text-center">
                  <button type="submit" className="btn btn-primary submit-btn">
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleClose}
                  >
                    Annuler
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
