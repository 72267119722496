import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Modal, Pagination } from "react-bootstrap";
import { IDossierMedical } from "../models/backend";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { Document, Page, pdfjs } from "react-pdf";
import SortArrow from "../common/shared-components/SortArrow";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { MdPersonSearch } from "react-icons/md";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const NUMBER_ITEMS_PER_PAGE: number = 3;
const MedicalRecordsList = (props: {
  records: IDossierMedical[];
  activePage: number;
  isCurrentUserOwner: boolean;
  medicalRecords: IDossierMedical[];
  onPageChange: (pageNumber: number) => void;
}) => {
  const totalPages = Math.ceil(props.records.length / NUMBER_ITEMS_PER_PAGE);
  const paginatedRecords = props.records.slice(
    (props.activePage - 1) * NUMBER_ITEMS_PER_PAGE,
    props.activePage * NUMBER_ITEMS_PER_PAGE
  );

  const [show, setShow] = useState(false);
  const [filepath, setFilepath] = useState("");
  const [medicalRecords, setMedicalRecords] = useState(props.medicalRecords);
  const [msg, setMsg] = useState("");

  // Helper to handle nested object access from dot notation string
  const getValueFromDotNotation = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);

  const sortedAndFilteredRecords = useMemo(() => {
    let processedRecords = props.medicalRecords || [];

    if (searchTerm) {
      processedRecords = processedRecords.filter((record) => {
        const doctorName =
          `${record.issuer.firstName} ${record.issuer.lastName}`.toLowerCase();
        const date = new Date(record.creationDate).toLocaleDateString("fr-FR");
        const recordTitle = record.title;

        return (
          doctorName.includes(searchTerm.toLowerCase()) ||
          date.includes(searchTerm) ||
          recordTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }

    if (sortConfig) {
      processedRecords.sort((a, b) => {
        const aValue = getValueFromDotNotation(a, sortConfig.key);
        const bValue = getValueFromDotNotation(b, sortConfig.key);

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return processedRecords;
  }, [props.medicalRecords, searchTerm, sortConfig]);

  const requestSort = (key: string) => {
    setSortConfig((prevConfig) => {
      if (
        prevConfig !== null &&
        prevConfig.key === key &&
        prevConfig.direction === "ascending"
      ) {
        return { key, direction: "descending" };
      } else {
        return { key, direction: "ascending" };
      }
    });
  };

  return (
    <>
      <div
        id="pat_medicalrecords"
        className="tab-pane fade show active"
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <div className="card card-table mb-0">
          <div className="card-body">
            <div className="table-responsive">
              <div
                className="row"
                style={{
                  marginBottom: "25px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Rechercher Dossier (Par propriétaire (Docteur ou Patient),
                    Date, Type)
                  </InputLabel>
                  <Input
                    id="outlined-adornment-password"
                    type="text"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    value={searchTerm}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => {}} edge="end">
                          <MdPersonSearch size={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <table className="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th onClick={() => requestSort("issuer.firstName")}>
                      Docteur
                      {sortConfig?.key === "issuer.firstName" && (
                        <SortArrow direction={sortConfig?.direction} />
                      )}
                    </th>
                    <th onClick={() => requestSort("creationDate")}>
                      Date
                      {sortConfig?.key === "creationDate" && (
                        <SortArrow direction={sortConfig?.direction} />
                      )}
                    </th>
                    <th onClick={() => requestSort("title")}>
                      Type
                      {sortConfig?.key === "title" && (
                        <SortArrow direction={sortConfig?.direction} />
                      )}
                    </th>
                    <th>Note</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAndFilteredRecords.map((record) => {
                    return (
                      <tr key={record?.id}>
                        <td>{`${record.issuer?.firstName} ${record.issuer?.lastName}`}</td>
                        <td>
                          {DateTime.fromJSDate(
                            new Date(record.creationDate)
                          ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}{" "}
                          <span className="d-block text-info">
                            {DateTime.fromJSDate(
                              new Date(record.creationDate)
                            ).toLocaleString(DateTime.TIME_24_SIMPLE)}
                          </span>
                        </td>
                        <td>{record.title}</td>
                        <td>{record.recordNotes[0].note}</td>
                        <td>
                          <Link
                            to="#"
                            title="Download attachment"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              setFilepath(record.files?.at(0)?.path);
                              setShow(true);
                            }}
                          >
                            {" "}
                            <i className="fa fa-eye" /> Voir
                          </Link>
                          {"  "}
                          <Link
                            to={record.files?.at(0)?.path}
                            title="Download attachment"
                            className="btn btn-primary btn-sm"
                          >
                            {" "}
                            <i className="fa fa-download" /> Telecharger
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination>
        {[...Array(totalPages)].map((_, i) => (
          <Pagination.Item
            key={i}
            active={i + 1 === props.activePage}
            onClick={() => props.onPageChange(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        style={{ marginTop: "75px" }}
        className="custom-medicalrecord-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">Record file</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Document file={filepath} onContextMenu={(e) => e.preventDefault()}>
            <Page pageNumber={1} />
          </Document>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MedicalRecordsList;
