import { Link } from "react-router-dom";
import Footer from "./footer";
import Header from "../header";

const Politiques = (props) => {
  return (
    <>
      <Header {...props} />

      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar-two">
          <div className="container">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">
                  Politique de Confidentialité
                </h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Politique de Confidentialité
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Termes et Conditions */}
        <section className="contact-section">
          <div className="container">
            <div className="row">
              <p>
                <b>
                  Politique de Confidentialit&eacute; de la Plateforme Allodoc
                  Derni&egrave;re mise &agrave; jour : 30 juillet 2024
                </b>
              </p>

              <p>
                1. Introduction La protection de vos donn&eacute;es personnelles
                est une priorit&eacute; pour Allodoc. Cette Politique de
                Confidentialit&eacute; d&eacute;crit comment nous collectons,
                utilisons, stockons, et prot&eacute;geons vos informations
                lorsque vous utilisez notre plateforme de prise de rendez-vous
                m&eacute;dicaux en ligne. En utilisant Allodoc, vous acceptez
                les pratiques d&eacute;crites dans cette politique.
              </p>

              <p>
                2. Informations Collect&eacute;es 2.1 Informations Personnelles
                : Nous collectons des informations personnelles lorsque vous
                vous inscrivez sur la plateforme, r&eacute;servez un
                rendez-vous, ou interagissez avec nos services. Ces informations
                peuvent inclure : Nom Pr&eacute;nom Date de naissance Adresse
                e-mail Num&eacute;ro de t&eacute;l&eacute;phone Informations
                m&eacute;dicales pertinentes pour la prise de rendez-vous 2.2
                Informations de Connexion : Nous recueillons des informations
                sur votre utilisation de la plateforme, telles que votre adresse
                IP, le type de navigateur, les pages visit&eacute;es, et la
                dur&eacute;e de votre visite. 2.3 Informations de Paiement : Si
                vous effectuez des paiements via la plateforme, nous collectons
                des informations li&eacute;es aux transactions, telles que les
                d&eacute;tails de la carte de cr&eacute;dit ou de d&eacute;bit,
                mais ne stockons pas ces informations directement. Elles sont
                trait&eacute;es par des prestataires de services de paiement
                s&eacute;curis&eacute;s.
              </p>

              <p>
                3. Utilisation des Informations 3.1 Gestion des Rendez-vous :
                Nous utilisons vos informations personnelles pour traiter et
                g&eacute;rer les rendez-vous m&eacute;dicaux que vous
                r&eacute;servez sur notre plateforme. Cela inclut la
                confirmation des rendez-vous, la gestion des annulations, et la
                communication avec les professionnels de sant&eacute;. 3.2
                Am&eacute;lioration des Services : Les informations
                collect&eacute;es nous aident &agrave; am&eacute;liorer nos
                services, &agrave; personnaliser votre exp&eacute;rience, et
                &agrave; analyser l'utilisation de la plateforme pour optimiser
                ses fonctionnalit&eacute;s. 3.3 Communications : Nous pouvons
                utiliser vos coordonn&eacute;es pour vous envoyer des
                notifications importantes concernant votre compte, les
                rendez-vous, ou des mises &agrave; jour de notre politique. Vous
                pouvez choisir de recevoir ou de ne pas recevoir des
                communications marketing en ajustant vos
                pr&eacute;f&eacute;rences dans votre compte. 3.4
                S&eacute;curit&eacute; : Nous utilisons vos informations pour
                assurer la s&eacute;curit&eacute; de notre plateforme,
                pr&eacute;venir les activit&eacute;s frauduleuses, et
                prot&eacute;ger vos donn&eacute;es contre les acc&egrave;s non
                autoris&eacute;s.
              </p>

              <p>
                4. Partage des Informations 4.1 Avec les professionnels de
                sant&eacute; : Vos informations personnelles sont
                partag&eacute;es avec les professionnels de sant&eacute;
                aupr&egrave;s desquels vous r&eacute;servez des rendez-vous pour
                assurer le bon d&eacute;roulement de la consultation et la
                gestion de votre dossier m&eacute;dical. 4.2 Avec des
                Prestataires de Services : Nous pouvons partager vos
                informations avec des prestataires de services tiers qui nous
                aident &agrave; g&eacute;rer la plateforme, traiter les
                paiements, ou fournir des services de support technique. Ces
                prestataires sont tenus de prot&eacute;ger vos informations
                conform&eacute;ment &agrave; notre politique de
                confidentialit&eacute;. 4.3 Obligations L&eacute;gales : Nous
                pouvons divulguer vos informations personnelles si la loi
                l'exige ou pour prot&eacute;ger les droits, la
                s&eacute;curit&eacute;, ou les biens de Allodoc, de ses
                utilisateurs, ou d'autres parties. 4.4 Transferts Internationaux
                : Vos informations peuvent &ecirc;tre transf&eacute;r&eacute;es
                et stock&eacute;es dans des serveurs situ&eacute;s en dehors du
                S&eacute;n&eacute;gal. Nous veillons &agrave; ce que ces
                transferts respectent les exigences l&eacute;gales en
                mati&egrave;re de protection des donn&eacute;es.
              </p>

              <p>
                5. S&eacute;curit&eacute; des Informations Nous mettons en
                &#339;uvre des mesures de s&eacute;curit&eacute; techniques et
                organisationnelles appropri&eacute;es pour prot&eacute;ger vos
                informations personnelles contre les acc&egrave;s non
                autoris&eacute;s, les modifications, les divulgations, ou les
                destructions. Cela inclut l'utilisation de protocoles de
                s&eacute;curit&eacute; tels que le cryptage et les pare-feu.
                Cependant, aucune m&eacute;thode de transmission sur Internet ou
                de stockage &eacute;lectronique n'est totalement
                s&eacute;curis&eacute;e. Bien que nous nous effor&ccedil;ons de
                prot&eacute;ger vos donn&eacute;es, nous ne pouvons garantir
                leur s&eacute;curit&eacute; absolue.
              </p>

              <p>
                6. Vos Droits 6.1 Droit d'Acc&egrave;s : Vous avez le droit de
                demander l'acc&egrave;s aux informations personnelles que nous
                d&eacute;tenons &agrave; votre sujet. Nous vous fournirons une
                copie de ces informations dans un d&eacute;lai raisonnable. 6.2
                Droit de Rectification : Vous pouvez demander la rectification
                des informations personnelles inexactes ou incompl&egrave;tes
                que nous d&eacute;tenons sur vous. 6.3 Droit &agrave;
                l'Effacement : Vous pouvez demander la suppression de vos
                informations personnelles lorsque ces donn&eacute;es ne sont
                plus n&eacute;cessaires aux fins pour lesquelles elles ont
                &eacute;t&eacute; collect&eacute;es, ou si vous retirez votre
                consentement. 6.4 Droit &agrave; la Limitation du Traitement :
                Vous pouvez demander la limitation du traitement de vos
                informations personnelles dans certaines situations, par exemple
                si vous contestez l'exactitude des donn&eacute;es. 6.5 Droit
                d'Opposition : Vous avez le droit de vous opposer au traitement
                de vos informations personnelles pour des raisons
                l&eacute;gitimes, sauf si nous avons des raisons
                imp&eacute;rieuses de continuer &agrave; traiter ces
                informations. Pour exercer vos droits, veuillez nous contacter
                &agrave; l'adresse suivante : [adresse e-mail de contact].
              </p>

              <p>
                7. Modifications de la Politique Nous pouvons modifier cette
                politique de confidentialit&eacute; &agrave; tout moment pour
                refl&eacute;ter les changements dans notre pratique ou dans la
                l&eacute;gislation applicable. Les modifications seront
                publi&eacute;es sur notre plateforme et entreront en vigueur
                d&egrave;s leur publication. Nous vous encourageons &agrave;
                consulter r&eacute;guli&egrave;rement cette politique pour
                &ecirc;tre inform&eacute; des mises &agrave; jour.
              </p>

              <p>
                8. Contact Pour toute question concernant cette politique de
                confidentialit&eacute; ou la mani&egrave;re dont nous traitons
                vos informations personnelles, veuillez nous contacter : Adresse
                e-mail : [adresse e-mail de contact] Adresse postale : [adresse
                postale] Num&eacute;ro de t&eacute;l&eacute;phone :
                [num&eacute;ro de t&eacute;l&eacute;phone] Merci d'utiliser
                Allodoc. Nous nous engageons &agrave; prot&eacute;ger votre vie
                priv&eacute;e et &agrave; assurer la s&eacute;curit&eacute; de
                vos informations personnelles.
              </p>
            </div>
          </div>
        </section>
        {/* /Termes et Conditions */}
      </>
      <Footer {...props} />
    </>
  );
};

export default Politiques;
