import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./footer";
import Header from "../header";
import FeatherIcon from "feather-icons-react";
import { choose01, choose02, choose03, choose04 } from "../imagepath";
import { ManagementService } from "../../services/managementService";
import { IContactUsModel } from "../../models/backend";

const Contactus = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const managementService = new ManagementService();

  const sendMessage = () => {
    setIsLoading(true);
    managementService
      .sendContactMessageAsync({
        email: email,
        message: message,
        name: name,
        subject: subject,
      } as IContactUsModel)
      .then(() => {
        // TODO: set the loading status
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Header {...props} />

      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar-two">
          <div className="container">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">Contactez-nous</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Contactez-nous
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Contact Us */}
        <section className="contact-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="section-inner-header contact-inner-header">
                  <h6>Restons en contact</h6>
                  <h2>Vous avez des questions?</h2>
                </div>
                <div className="card contact-card">
                  <div className="card-body">
                    <div className="contact-icon">
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          style={{ width: "44px", height: "44px" }}
                        />
                      </i>
                    </div>
                    <div className="contact-details">
                      <h4>Adresse</h4>
                      <p>Dakar, Sénégal</p>
                    </div>
                  </div>
                </div>
                <div className="card contact-card">
                  <div className="card-body">
                    <div className="contact-icon">
                      <i>
                        <FeatherIcon
                          icon="phone"
                          style={{ width: "44px", height: "44px" }}
                        />
                      </i>
                    </div>
                    <div className="contact-details">
                      <h4>Numéro de téléphone</h4>
                      <p>+221 77 4749797</p>
                    </div>
                  </div>
                </div>
                <div className="card contact-card">
                  <div className="card-body">
                    <div className="contact-icon">
                      <i>
                        <FeatherIcon
                          icon="mail"
                          style={{ width: "44px", height: "44px" }}
                        />
                      </i>
                    </div>
                    <div className="contact-details">
                      <h4>Adresse email</h4>
                      <p>contact@allodocapp.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 d-flex">
                <div className="card contact-form-card w-100">
                  <div className="card-body">
                    <form action="#">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Nom</label>
                            <input
                              type="text"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              className="form-control"
                              placeholder="Saisir votre nom"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Adresse email</label>
                            <input
                              type="text"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              className="form-control"
                              placeholder="Saisir adresse mail"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Sujet</label>
                            <input
                              type="text"
                              value={subject}
                              onChange={(e) => {
                                setSubject(e.target.value);
                              }}
                              className="form-control"
                              placeholder="Sujet"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Message</label>
                            <textarea
                              className="form-control"
                              value={message}
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                              placeholder="Saisir votre message"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-group-btn mb-0">
                            <button
                              type="submit"
                              className="btn btn-primary prime-btn"
                              onClick={sendMessage}
                            >
                              Envoyer Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Contact Us */}
        {/* Why Choose Us */}
        <section className="why-choose-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-inner-header text-center">
                  <h2>Pourquoi Allodoc</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card why-choose-card w-100">
                  <div className="card-body">
                    <div className="why-choose-icon">
                      <span>
                        <img src={choose01} alt="" />
                      </span>
                    </div>
                    <div className="why-choose-content">
                      <h4>Recherche facile</h4>
                      <p>
                        Trouvez rapidement le médecin dont vous avez besoin en
                        utilisant notre moteur de recherche. Filtrez par
                        spécialité, localisation, ou nom pour trouver le
                        professionnel de santé qui vous convient le mieux.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card why-choose-card w-100">
                  <div className="card-body">
                    <div className="why-choose-icon">
                      <span>
                        <img src={choose02} alt="" />
                      </span>
                    </div>
                    <div className="why-choose-content">
                      <h4>Accessibilité 24/7</h4>
                      <p>
                        Prenez rendez-vous à tout moment, où que vous soyez.
                        Notre plateforme est disponible 24 heures sur 24, 7
                        jours sur 7.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card why-choose-card w-100">
                  <div className="card-body">
                    <div className="why-choose-icon">
                      <span>
                        <img src={choose03} alt="" />
                      </span>
                    </div>
                    <div className="why-choose-content">
                      <h4>Dossier Médical Accessible</h4>
                      <p>
                        Accédez à votre dossier médical en ligne à tout moment.
                        Retrouvez vos ordonnances, analyses, recommandations, et
                        résultats de consultations, le tout en toute sécurité.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card why-choose-card w-100">
                  <div className="card-body">
                    <div className="why-choose-icon">
                      <span>
                        <img src={choose04} alt="" />
                      </span>
                    </div>
                    <div className="why-choose-content">
                      <h4>Disponibilités en Temps Réel</h4>
                      <p>
                        Consultez les créneaux horaires disponibles et
                        choisissez le rendez-vous qui vous convient. Vous
                        obtenez une confirmation instantanée de votre
                        rendez-vous directement sur la plateforme.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Why Choose Us */}
      </>
      <Footer {...props} />
    </>
  );
};

export default Contactus;
