import { useEffect, useState } from "react";
import { Table } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { itemRender, onShowSizeChange } from "./paginationfunction";
import SidebarNav from "./sidebar";
import { Link } from "react-router-dom";
import Header from "./header";
import { Modal } from "react-bootstrap";
import { ISpeciality } from "../../models/backend";
import { ManagementService } from "../../services/managementService";
import uuid from "react-uuid";
import { NOTIFICATION_TYPE } from "../../models/notifications";
import NotificationComponent from "../../common/NotificationComponent";
// TODO: save the speciality in the database
const Specialities = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [specTitle, setSpecTitle] = useState("");
  const [specDescription, setSpecDescription] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<ISpeciality>({});
  const [specialities, setSpecialities] = useState<ISpeciality[]>([]);
  const managementService = new ManagementService();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    managementService.getAllSpecialitiesAsync().then((s) => {
      setSpecialities(s && s?.length > 0 ? s : []);
    });
  }, []);

  const resetSelection = () => {
    setSelectedRecord({});
    setSpecTitle("");
    setSpecDescription("");

    // hide the popups
    setShowDelete(false);
    setShowEdit(false);
    setShowAdd(false);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const columns = [
    {
      title: "Titre",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => <>{text}</>,
      sorter: (a, b) => a.desciption.length - b.desciption.length,
    },
    {
      title: "Action",
      className: "text-end",
      dataIndex: "",
      render: (text, record) => (
        <div className="text-end">
          <a
            className="me-1 btn btn-sm bg-success-light "
            data-bs-toggle="modal"
            onClick={() => {
              setSelectedRecord(record);
              setSpecTitle(record?.title);
              setSpecDescription(record?.description);
              setShowEdit(true);
            }}
          >
            <i className="fe fe-pencil"></i> Modifier
          </a>
          <a
            className="me-1 btn btn-sm bg-danger-light"
            data-bs-toggle="modal"
            onClick={() => {
              setSelectedRecord(record);
              setShowDelete(true);
            }}
          >
            <i className="fe fe-trash"></i> Supprimer
          </a>
        </div>
      ),
      sorter: (a, b) => a.length - b.length,
    },
  ];

  return (
    <>
      <Header onMenuClick={() => {}} />
      <SidebarNav />
      {showNotification && (
        <NotificationComponent
          message="Cette sprecialite existe deja."
          notificationType={NOTIFICATION_TYPE.WARNING}
        />
      )}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7 col-aut0">
                <h3 className="page-title">Specialities</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Specialities</li>
                </ul>
              </div>
              <div className="col-sm-5 col">
                <a
                  data-bs-toggle="modal"
                  className="btn btn-primary float-end mt-2"
                  onClick={() => {
                    setShowAdd(true);
                  }}
                >
                  Ajouter
                </a>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      pagination={{
                        total: specialities?.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      dataSource={[...specialities]}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal"
        show={showEdit || showAdd}
        onHide={() => {
          resetSelection();
        }}
        style={{ marginTop: "80px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title">
              {showEdit ? "Modifier specialite" : "Ajouter specialite"}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row form-row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Titre</label>
                <input
                  type="text"
                  className="form-control"
                  value={specTitle}
                  onChange={(e) => {
                    setSpecTitle(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  value={specDescription}
                  onChange={(e) => {
                    setSpecDescription(e.target.value);
                  }}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-primary w-100"
            onClick={() => {
              // add the speciality to the list
              if (showAdd) {
                let newSpeciality = {
                  description: specDescription,
                  title: specTitle,
                  id: uuid(),
                };

                if (!specialities.find((s) => s.title == newSpeciality.title)) {
                  specialities.push(newSpeciality);
                  managementService.saveSpecialityAsync(newSpeciality);
                } else {
                  setShowNotification(true);
                }
              } else {
                let spec = specialities.find((s) => s.id == selectedRecord.id);
                spec.title = specTitle;
                spec.description = specDescription;
                managementService.deleteSpecialityAsync(spec.id).then(() => {
                  managementService.saveSpecialityAsync(spec);
                });
              }

              // hide the popup
              resetSelection();
            }}
          >
            Enregistrer
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="modal"
        show={showDelete}
        onHide={() => {
          resetSelection();
        }}
        style={{ marginTop: "80px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title">Supprimer</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4">
            Voulez-vous supprimer la specialite {selectedRecord.title}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary mx-1"
            onClick={() => {
              managementService
                .deleteSpecialityAsync(selectedRecord.id)
                .then(() => {
                  setSpecialities(
                    specialities.filter((s) => s.id != selectedRecord?.id)
                  );
                  resetSelection();
                });
            }}
          >
            Enregistrer{" "}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              resetSelection();
            }}
          >
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Specialities;
