import {
  IDoctor,
  IMedicalRecordModel,
  IPatient,
  ICalendarEvent,
} from "../models/backend";
import { FixMeLater } from "../tsmigration";
import { BACKEND_URL } from "../utils/constants";
import HttpClient from "../utils/httpClient";
import { TokenHelpers } from "../utils/tokenHelpers";

interface IDoctorRepository {
  getDoctorAsync(doctorId: string): Promise<FixMeLater>;
  getDoctorByNameAsync(name: string): Promise<IDoctor[]>;
  saveDoctorAsync(doctor: IDoctor): Promise<boolean>;
  getAllDoctorAsync(): Promise<IDoctor[]>;
  // createAppointment(appointment): Promise<FixMeLater>;
  getAllPatientsAsync(doctorId: string): Promise<IPatient[]>;
  lockDoctorAccountAsync(
    doctorId: string,
    lockAccount: boolean
  ): Promise<boolean>;
  saveMedicalRecordFileAsync(file: FormData): Promise<string>;
  saveMedicalRecordAsync(record: IMedicalRecordModel): Promise<boolean>;

  getDoctorBusySpots(doctorId: string): Promise<ICalendarEvent[]>;
}

class DoctorRepository implements IDoctorRepository {
  private _httpClient: HttpClient;
  private _tokenHelpers: TokenHelpers;

  /**
   *Repository that will handle the request for the doctors
   */
  constructor() {
    this._tokenHelpers = new TokenHelpers();
    this._httpClient = new HttpClient(
      BACKEND_URL,
      this._tokenHelpers.getToken()
    );
  }

  async saveMedicalRecordAsync(record: IMedicalRecordModel): Promise<boolean> {
    return await this._httpClient.post<IMedicalRecordModel, boolean>(
      "/api/doctor/savemedicalrecord",
      {},
      record
    );
  }

  async saveMedicalRecordFileAsync(file: FormData): Promise<string> {
    return await this._httpClient.postMedia<FormData, string>(
      "/api/doctor/savemedicalrecordfile",
      {},
      file
    );
  }

  async getAllPatientsAsync(doctorId: string): Promise<IPatient[]> {
    const params = { doctorId: doctorId };
    return await this._httpClient.get<IPatient[]>(
      "/api/doctor/getallpatients",
      params
    );
  }

  async getDoctorByNameAsync(name: string): Promise<IDoctor[]> {
    // eslint-disable-next-line no-extra-boolean-cast
    const params = !!name ? { name: name } : { name: "" };
    return await this._httpClient.get<IDoctor[]>(
      "/api/doctor/getbyname",
      params
    );
  }

  async lockDoctorAccountAsync(
    doctorId: string,
    lockAccount: boolean
  ): Promise<boolean> {
    return await this._httpClient.post<FixMeLater, boolean>(
      "/api/doctor/lock",
      {
        id: doctorId,
        lockAccount: lockAccount,
      }
    );
  }

  async getAllDoctorAsync(): Promise<IDoctor[]> {
    return await this._httpClient.get<IDoctor[]>("/api/doctor/getall");
  }

  async saveDoctorAsync(doctor: IDoctor): Promise<boolean> {
    return await this._httpClient.post<IDoctor, boolean>(
      "/api/Doctor/Add",
      {},
      doctor
    );
  }

  async getDoctorAsync(doctorId: string) {
    return await this._httpClient.get<IDoctor>("/api/Doctor/GetById", {
      id: doctorId,
    });
  }

  async getDoctorAppointments(doctorId: string) {
    return await this._httpClient.get<FixMeLater>("/api/Doctor/GetById", {
      id: doctorId,
    });
  }

  async getDoctorBusySpots(doctorId: string): Promise<ICalendarEvent[]> {
    return await this._httpClient.get<ICalendarEvent[]>(
      "/api/Doctor/GetDoctorBusySlots",
      {
        id: doctorId,
      }
    );
  }
}

export { DoctorRepository, IDoctorRepository };
