import React, { useEffect, useState } from "react";
// import Searchfilter from "./searchFilter";
import StickyBox from "react-sticky-box";
import SearchDoctorsList from "./searchDoctorsList";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../common/header";
import Footer from "../../common/home/footer";
import { ISearchFilter, ISpeciality } from "../../models/backend";
import { ManagementService } from "../../services/managementService";
import DashboardSidebar from "../../patient/sidebar";
import { Button } from "react-bootstrap";
import { FaCaretLeft } from "react-icons/fa";
// import Slider from "react-slider";

const SearchDoctor = (props) => {
  const [minValue, setMinValue] = useState(10);
  const [maxValue, setMaxValue] = useState(5000);

  const { state } = useLocation();
  const [homme, setHomme] = useState(false);
  const [femme, setFemme] = useState(false);
  const [availableToday, setAvailableToday] = useState(false);
  const [availableTomorrow, setAvailableTomorrow] = useState(false);
  const [availableIn7Day, setAvailableIn7Day] = useState(false);
  const [availableIn30Day, setAvailableIn30Day] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [serverSpecialities, setServerSpecialities] = useState<ISpeciality[]>(
    []
  );
  const [name, setName] = useState(state?.search);
  const [filter, setFilter] = useState<ISearchFilter>({ name: state?.search });
  const pathname = window.location.pathname;

  const managementService = new ManagementService();
  const navigate = useNavigate();

  useEffect(() => {
    if (document.getElementById("price-range")) {
      setMinValue(10);
      setMaxValue(10000);

      const slider = document.getElementById("price-range");
      slider.addEventListener("input", handleSliderChange);

      return () => {
        slider.removeEventListener("input", handleSliderChange);
      };
    }
  }, []);

  useEffect(() => {
    managementService.getAllSpecialitiesAsync().then((result) => {
      setServerSpecialities(result);
    });
  });

  const handleSliderChange = (event) => {
    const min = parseInt(event.target.value.split(",")[0]);
    const max = parseInt(event.target.value.split(",")[1]);

    setMinValue(min);
    setMaxValue(max);
  };
  return (
    <div className="main-wrapper">
      <Header {...props} />

      {/* Breadcrumb */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Liste des docteurs</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  {pathname.includes("recherche-docteur") && (
                    <li className="breadcrumb-item">
                      <Link to="/dashboard-patient">Rendez-vous</Link>
                    </li>
                  )}
                  <li className="breadcrumb-item" aria-current="page">
                    Liste des docteurs
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}

      <div className="doctor-content content">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 map-view">
              <div className="row">
                <div className="col-md-3 theiaStickySidebar">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <div>
                      <div className="filter-contents">
                        <div className="filter-header">
                          <h4 className="filter-title">Filtre</h4>
                        </div>
                        <div className="filter-details">
                          {/* Filter Grid */}
                          <div className="filter-grid">
                            <h4>
                              <Link to="#collapseone" data-bs-toggle="collapse">
                                Nom
                              </Link>
                            </h4>
                            <div id="collapseone" className="collapse show">
                              <div className="filter-collapse">
                                <ul>
                                  <li>
                                    <input
                                      type="text"
                                      name="nom"
                                      value={name}
                                      className="form-control"
                                      onChange={(e) => {
                                        setName(e.target.value);
                                      }}
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="filter-grid">
                            <h4>
                              <Link to="#collapseone" data-bs-toggle="collapse">
                                Genre
                              </Link>
                            </h4>
                            <div id="collapseone" className="collapse show">
                              <div className="filter-collapse">
                                <ul>
                                  <li>
                                    <label className="custom_check d-inline-flex">
                                      <input
                                        type="checkbox"
                                        name="gender"
                                        checked={homme}
                                        onChange={(e) => {
                                          setHomme(!homme);
                                        }}
                                      />
                                      <span className="checkmark" />
                                      Homme
                                    </label>
                                  </li>
                                  <li>
                                    <label className="custom_check d-inline-flex">
                                      <input
                                        type="checkbox"
                                        name="gender"
                                        checked={femme}
                                        onChange={(e) => {
                                          setFemme(!femme);
                                        }}
                                      />
                                      <span className="checkmark" />
                                      Femme
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="filter-grid">
                            <h4>
                              <Link to="#collapsetwo" data-bs-toggle="collapse">
                                Disponibilite
                              </Link>
                            </h4>
                            <div id="collapsetwo" className="collapse show">
                              <div className="filter-collapse">
                                <ul>
                                  <li>
                                    <label className="custom_check d-inline-flex">
                                      <input
                                        type="checkbox"
                                        name="availability"
                                        checked={availableToday}
                                        onChange={() => {
                                          setAvailableToday(!availableToday);
                                        }}
                                      />
                                      <span className="checkmark" />
                                      Disponible aujourd'hui
                                    </label>
                                  </li>
                                  <li>
                                    <label className="custom_check d-inline-flex">
                                      <input
                                        type="checkbox"
                                        name="availability"
                                        checked={availableTomorrow}
                                        onChange={() => {
                                          setAvailableTomorrow(
                                            !availableTomorrow
                                          );
                                        }}
                                      />
                                      <span className="checkmark" />
                                      Disponible demain
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="filter-grid">
                            <h4>
                              <Link
                                to="#collapsefour"
                                data-bs-toggle="collapse"
                              >
                                Specialite
                              </Link>
                            </h4>
                            <div id="collapsefour" className="collapse show">
                              <div className="filter-collapse">
                                <ul>
                                  {serverSpecialities?.map((s) => {
                                    return (
                                      <li key={s.title}>
                                        <label className="custom_check d-inline-flex">
                                          <input
                                            type="checkbox"
                                            name="speciality"
                                            onClick={(e) => {
                                              if (
                                                specialities.includes(s.title)
                                              ) {
                                                setSpecialities(
                                                  specialities.filter(
                                                    (sp) => sp === s.title
                                                  )
                                                );
                                              } else {
                                                specialities.push(s.title);
                                              }
                                            }}
                                          />
                                          <span className="checkmark" />
                                          {s.title}
                                        </label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="filter-btn apply-btn">
                            <div className="row">
                              <div className="col-6">
                                <Link
                                  to="#"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setFilter({
                                      homme: homme,
                                      femme: femme,
                                      availableToday: availableToday,
                                      availableTomorrow: availableTomorrow,
                                      availableIn7Day: availableIn7Day,
                                      availableIn30Day: availableIn30Day,
                                      specialities: specialities,
                                      name: name,
                                    });
                                  }}
                                >
                                  Appliquer
                                </Link>
                              </div>
                              <div className="col-6">
                                <Link
                                  to="#"
                                  className="btn btn-outline-primary"
                                  onClick={() => {
                                    setHomme(false);
                                    setFemme(false);
                                    setAvailableToday(false);
                                    setAvailableTomorrow(false);
                                    setAvailableIn7Day(false);
                                    setAvailableIn30Day(false);
                                    setSpecialities([]);
                                    setName("");
                                    setFilter(null);
                                  }}
                                >
                                  Réinitialiser
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </StickyBox>
                </div>
                <div className="col-md-9">
                  {pathname.includes("recherche-docteur") && (
                    <div className="row">
                      <div className="text-start filter-btn apply-btn">
                        <Button
                          variant="info"
                          className="btn btn-primary"
                          type="button"
                          onClick={() => navigate("/dashboard-patient")}
                        >
                          {/* <FaCaretLeft></FaCaretLeft> */}
                          Retour
                        </Button>
                      </div>
                    </div>
                  )}
                  <br></br>
                  <SearchDoctorsList filtre={filter} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer {...props} />
    </div>
  );
};

export default SearchDoctor;
