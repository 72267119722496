import React, { useState, useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { NOTIFICATION_TYPE, ShowSnackbarProps } from "../models/notifications";

const getColorClass = (notificationType: string) => {
  switch (notificationType) {
    case NOTIFICATION_TYPE.INFO:
      return "bg-info text-white";
    case NOTIFICATION_TYPE.WARNING:
      return "bg-warning text-dark";
    case NOTIFICATION_TYPE.ERROR:
      return "bg-danger text-white";
    case NOTIFICATION_TYPE.SUCCESS:
      return "bg-info text-white";
    default:
      return "";
  }
};

const NotificationComponent: React.FC<ShowSnackbarProps> = ({
  message,
  notificationType,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShow(false), 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ToastContainer className="toast-container-bottom-center">
      <Toast
        show={show}
        onClose={() => setShow(false)}
        className={getColorClass(notificationType)}
      >
        <Toast.Header>
          <strong className="me-auto">{notificationType}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default NotificationComponent;
