/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
// import { avatar01, avatar02, avatar03, avatar05, avatar06, logo, logoSmall } from '../imagepath'
// import { Appcontext } from "../../../approuter";
import {
  avatar01,
  doctor_thumb_01,
  logo,
  logoSmall,
  patient1,
  patient2,
  patient3,
} from "./imagepath";
import {
  Dropdown,
  DropdownButton,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { useAuth } from "react-oidc-context";

const Header = (props) => {
  // let pathname = props.location.pathname;
  // const { isAuth, setIsAuth } = useContext(Appcontext);
  // const [task, settask] = useState(true);
  // const [task1, settask1] = useState(true);
  // const [dropdown, setdropdown] = useState(false);
  // const [dropdown1, setdropdown1] = useState(false);

  // const handletheme = () => {
  //   document.body.classList.toggle("darkmode");
  //   settask(!task);
  //   settask1(!task1);
  // };
  // const handledropdown = () => {
  //   setdropdown(!dropdown);
  //   setdropdown1(false);
  // };
  // const handledropdown1 = () => {
  //   setdropdown1(!dropdown1);
  //   setdropdown(false);
  // };

  const auth = useAuth();
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
  };

  require("../assets/css/feathericon.min.css");
  require("../assets/js/feather.min.js");
  require("../assets/plugins/fontawesome/css/fontawesome.min.css");
  require("../assets/plugins/fontawesome/css/all.min.css");
  require("../assets/plugins/fontawesome/js/fontawesome.min.js");
  require("../assets/css/font-awesome.min.css");
  require("../assets/css/custom.css");

  // const exclusionArray = ["/admin/login"];
  // if (exclusionArray.indexOf(props.location.pathname) >= 0) {
  //   return "";
  // }

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          <Link to="/admin" className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <Link to="/admin" className="logo logo-small">
            <img src={logoSmall} alt="Logo" width="30" height="30" />
          </Link>
        </div>
        <Link to="#" id="toggle_btn" onClick={handlesidebar}>
          <i className="fe fe-text-align-left"></i>
        </Link>
        {/* /Logo */}

        {/* Mobile Menu Toggle */}
        <Link
          to="#"
          className="mobile_btn"
          id="mobile_btn"
          onClick={() => handlesidebarmobilemenu()}
        >
          <i className="fa fa-bars" />
        </Link>
        {/* /Mobile Menu Toggle */}
        {/* Header Right Menu */}
        <ul className="nav user-menu">
          <li className="nav-item dropdown has-arrow">
            <Nav style={{ backgroundColor: "#1b5a90" }}>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Retour"
                style={{ backgroundColor: "#1b5a90" }}
              >
                <NavDropdown.Item style={{ color: "#1b5a90" }} href="/">
                  Accueil
                </NavDropdown.Item>
                <NavDropdown.Item
                  style={{ color: "#1b5a90" }}
                  onClick={() => {
                    auth?.signoutRedirect();
                  }}
                >
                  Se deconnecter
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </li>
        </ul>
        {/* /Header Right Menu */}
      </div>

      {/* /Header */}
    </>
  );
};

export default Header;
