import { APPOINTMENT_STATUS } from "../models/backend";
import { isAppointmentTimeInThePast } from "./objUtils";

export type SortConfig = {
  key: string;
  direction: "ascending" | "descending";
} | null;

export type OnSortChange = (config: SortConfig) => void;

export function deriveAppointmentStatus(appointment) {
  if (appointment.isHonored) {
    return APPOINTMENT_STATUS.COMPLETED;
  } else if (isAppointmentTimeInThePast(appointment.date)) {
    return APPOINTMENT_STATUS.TO_UPDATE;
  } else {
    return appointment.appointmentState || APPOINTMENT_STATUS.UPCOMING;
  }
}
