import { Link } from "react-router-dom";
import Footer from "./footer";
import Header from "../header";

const Conditions = (props) => {
  return (
    <>
      <Header {...props} />

      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar-two">
          <div className="container">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">Termes et Conditions</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Termes et Conditions
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Termes et Conditions */}
        <section className="contact-section">
          <div className="container">
            <div className="row">
              <p>
                <b>
                  Conditions G&eacute;n&eacute;rales d'Utilisation de la
                  Plateforme Allodoc Derni&egrave;re mise &agrave; jour : 25
                  juillet 2024
                </b>
              </p>

              <p>
                1. Introduction Bienvenue sur Allodoc, la plateforme en ligne de
                prise de rendez-vous m&eacute;dicaux. En utilisant notre
                service, vous acceptez de respecter les pr&eacute;sentes
                conditions g&eacute;n&eacute;rales d'utilisation. Si vous
                n'acceptez pas ces conditions, veuillez ne pas utiliser la
                plateforme. Allodoc se r&eacute;serve le droit de modifier ces
                conditions &agrave; tout moment, et les modifications seront
                publi&eacute;es sur cette page. Votre utilisation continue de la
                plateforme apr&egrave;s modification constitue votre acceptation
                des nouvelles conditions.
              </p>

              <p>
                2. D&eacute;finitions &quot;Plateforme&quot; : D&eacute;signe le
                site web et l'application mobile Allodoc permettant aux
                utilisateurs de r&eacute;server des rendez-vous m&eacute;dicaux
                en ligne. &quot;Utilisateur&quot; : Toute personne utilisant la
                plateforme pour rechercher et r&eacute;server des rendez-vous
                avec des professionnels de sant&eacute;. &quot;Professionnel de
                sant&eacute;&quot; : M&eacute;decin ou autre professionnel
                m&eacute;dical inscrit sur la plateforme et offrant des
                consultations.
              </p>

              <p>
                3. Acceptation des Conditions En acc&eacute;dant &agrave; la
                plateforme, vous acceptez les pr&eacute;sentes conditions
                g&eacute;n&eacute;rales. Vous vous engagez &agrave; respecter
                les lois et r&egrave;glements en vigueur au
                S&eacute;n&eacute;gal, ainsi que les conditions
                sp&eacute;cifi&eacute;es dans ce document. Nous nous
                r&eacute;servons le droit de suspendre ou de r&eacute;silier
                votre acc&egrave;s &agrave; la plateforme en cas de non-respect
                des conditions.
              </p>

              <p>
                4. Utilisation de la Plateforme Inscription : Pour utiliser la
                plateforme, vous devez cr&eacute;er un compte en fournissant des
                informations exactes et compl&egrave;tes. Vous &ecirc;tes
                responsable de la confidentialit&eacute; de vos identifiants et
                de toutes les activit&eacute;s effectu&eacute;es sous votre
                compte. Vous devez signaler imm&eacute;diatement toute
                utilisation non autoris&eacute;e de votre compte.
                R&eacute;servation de rendez-vous : Vous pouvez rechercher des
                professionnels de sant&eacute; en fonction de la
                sp&eacute;cialit&eacute;, du nom ou de la localisation. Vous
                pouvez consulter les cr&eacute;neaux horaires disponibles et
                r&eacute;server un rendez-vous en ligne. Les rendez-vous sont
                confirm&eacute;s par la plateforme et vous recevrez une
                notification de confirmation. Modification et Annulation : Vous
                pouvez modifier ou annuler vos rendez-vous via la plateforme en
                respectant les d&eacute;lais indiqu&eacute;s par le
                professionnel de sant&eacute;. Veuillez noter que des frais
                d'annulation peuvent s'appliquer selon les politiques du
                professionnel.
              </p>

              <p>
                5. Donn&eacute;es Personnelles Collecte et Utilisation : Allodoc
                collecte et utilise vos donn&eacute;es personnelles pour
                g&eacute;rer les rendez-vous et am&eacute;liorer nos services.
                Les informations collect&eacute;es incluent des donn&eacute;es
                d'identification, des coordonn&eacute;es, et des informations
                sur les rendez-vous. Confidentialit&eacute; : Nous mettons en
                &#339;uvre des mesures de s&eacute;curit&eacute;
                appropri&eacute;es pour prot&eacute;ger vos donn&eacute;es
                personnelles contre la perte, l'acc&egrave;s non
                autoris&eacute;, ou la divulgation. Toutefois, nous ne pouvons
                garantir la s&eacute;curit&eacute; absolue des informations
                transmises sur Internet. Droits des Utilisateurs :
                Conform&eacute;ment &agrave; la l&eacute;gislation
                s&eacute;n&eacute;galaise (Loi n&deg; 2008-12 du 25 janvier 2008
                sur la protection des donn&eacute;es personnelles), vous avez le
                droit d&rsquo;acc&eacute;der &agrave; vos donn&eacute;es
                personnelles, de les rectifier ou de les supprimer. Pour exercer
                ces droits, veuillez nous contacter &agrave; [adresse e-mail de
                contact].
              </p>

              <p>
                6. Obligations des Utilisateurs Exactitude des Informations :
                Vous vous engagez &agrave; fournir des informations exactes et
                &agrave; jour lors de votre inscription et lors de la
                r&eacute;servation de rendez-vous. Utilisation Responsable :
                Vous devez utiliser la plateforme de mani&egrave;re conforme aux
                lois en vigueur et ne pas l'utiliser &agrave; des fins
                ill&eacute;gales ou nuisibles. Comportement : Vous devez
                respecter les professionnels de sant&eacute; et les autres
                utilisateurs. Tout comportement abusif ou inappropri&eacute;
                peut entra&icirc;ner la suspension ou la suppression de votre
                compte.
              </p>

              <p>
                7. Responsabilit&eacute; Limitation de Responsabilit&eacute; :
                Allodoc ne peut &ecirc;tre tenu responsable des erreurs ou
                omissions sur la plateforme. Nous ne sommes pas responsables des
                actions ou omissions des professionnels de sant&eacute;
                inscrits, ni des &eacute;ventuels pr&eacute;judices
                r&eacute;sultant de l&rsquo;utilisation des services.
                Exon&eacute;ration de Responsabilit&eacute; : Vous acceptez de
                d&eacute;gager Allodoc de toute responsabilit&eacute; en cas de
                r&eacute;clamation, dommage ou perte r&eacute;sultant de votre
                utilisation de la plateforme ou de votre violation des
                pr&eacute;sentes conditions.
              </p>

              <p>
                8. Propri&eacute;t&eacute; Intellectuelle Tous les contenus
                disponibles sur la plateforme, y compris les textes, images,
                logos et logiciels, sont prot&eacute;g&eacute;s par des droits
                de propri&eacute;t&eacute; intellectuelle. Vous
                n&rsquo;&ecirc;tes pas autoris&eacute; &agrave; reproduire,
                distribuer, ou exploiter ces contenus sans notre autorisation
                &eacute;crite pr&eacute;alable.
              </p>

              <p>
                9. R&eacute;siliation Nous nous r&eacute;servons le droit de
                suspendre ou de r&eacute;silier votre compte si vous ne
                respectez pas les pr&eacute;sentes conditions ou si nous jugeons
                n&eacute;cessaire d&rsquo;agir pour assurer le bon
                fonctionnement de la plateforme.
              </p>

              <p>
                10. Loi Applicable et Juridiction Les pr&eacute;sentes
                conditions sont r&eacute;gies par la l&eacute;gislation
                s&eacute;n&eacute;galaise. Tout litige relatif &agrave;
                l&rsquo;utilisation de la plateforme sera soumis aux tribunaux
                comp&eacute;tents du S&eacute;n&eacute;gal.
              </p>

              <p>
                11. Contact Pour toute question concernant ces conditions
                g&eacute;n&eacute;rales ou l&rsquo;utilisation de la plateforme,
                veuillez nous contacter : Adresse e-mail : [adresse e-mail de
                contact] Adresse postale : [adresse postale] Num&eacute;ro de
                t&eacute;l&eacute;phone : [num&eacute;ro de
                t&eacute;l&eacute;phone] Merci d'utiliser Allodoc. Nous nous
                engageons &agrave; vous offrir un service de r&eacute;servation
                de rendez-vous m&eacute;dicaux simple, s&eacute;curis&eacute; et
                efficace.
              </p>
            </div>
          </div>
        </section>
        {/* /Termes et Conditions */}
      </>
      <Footer {...props} />
    </>
  );
};

export default Conditions;
