import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/allodoc.png";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/aos.css";

import { Link, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { IPatient } from "../../models/backend";
import uuid from "react-uuid";
import { DateTime } from "luxon";
import { FixMeLater } from "../../tsmigration";

const Patientregisterstepthree = (props) => {
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [validation, setValidation] = useState(false);
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [lien, setLien] = useState("");
  const [genre, setGenre] = useState("");
  const [dob, setDOB] = useState("");
  const [telephone, setTelephone] = useState("");
  const [dependents, setDependents] = useState<IPatient[]>([]);
  const [currentPatient, setCurrentPatient] = useState<IPatient>(state);
  const [modifiedDependent, setModifiedDependent] = useState<IPatient>();
  const [erreur, setErreur] = useState(false);
  const [afficherErreur, setAfficherErreur] = useState(false);
  const [msgErreur, setMessageErreur] = useState([]);
  const [dependentToDelete, setDependentToDelete] = useState("");

  const resetStates = () => {
    setShow(false);
    setEdit(false);
    setAfficherErreur(false);

    setPrenom("");
    setNom("");
    setLien("");
    setGenre("");
    setDOB("");
    setTelephone("");
  };

  const editDependent = (dependent: IPatient) => {
    setPrenom(dependent.firstName);
    setNom(dependent.lastName);
    setLien(dependent.dependentRelationship);
    setGenre(dependent.gender);
    setDOB(dependent.dayOfBirth.toString());
    setTelephone(dependent.phoneNumber);

    setModifiedDependent(dependent);

    setEdit(true);
  };

  const validateForm = () => {
    let messageErreur = [];

    if (nom == "") {
      messageErreur.push("Nom");
    }

    if (prenom == "") {
      messageErreur.push("Prenom");
    }

    if (lien == "") {
      messageErreur.push("Lien de parente");
    }

    if (genre == "") {
      messageErreur.push("Genre");
    }

    if (dob == "") {
      messageErreur.push("Date de naissance");
    }

    if (messageErreur.length > 0) {
      setErreur(true);
      setMessageErreur(messageErreur);
    } else {
      setErreur(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [dob, genre, nom, prenom, lien]);

  return (
    <>
      <>
        {/* Page Content */}
        <div className="content login-page pt-0">
          <div className="container-fluid">
            {/* Register Content */}
            <div className="account-content">
              <div className="row align-items-center">
                <div className="login-right">
                  <div
                    className="inner-right-login"
                    style={{ maxWidth: "100%" }}
                  >
                    <div className="login-header">
                      <div className="logo-icon">
                        <img src={Logo} alt="" />
                      </div>
                      <div className="step-list">
                        <ul>
                          <li>
                            <Link to="#" className="active-done">
                              1
                            </Link>
                          </li>
                          <li>
                            <Link
                              state={currentPatient as IPatient}
                              to={{
                                pathname: "/patientregisterstep-2",
                              }}
                              className="active-done"
                            >
                              2
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="active">
                              3
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <form
                        method="post"
                        style={{ margin: "auto", width: "50%" }}
                      >
                        <div className="text-start mt-2">
                          <p>Voulez vous ajouter des personnes a charge ?</p>
                          <div className="col-sm-6">
                            <a
                              href="#modal_form"
                              onClick={() => setShow(true)}
                              className="btn btn-primary btn-sm"
                              tabIndex={0}
                            >
                              Ajouter
                            </a>
                          </div>
                        </div>
                        <div className="card-body " style={{ paddingLeft: 0 }}>
                          {/* Dependent Tab */}
                          <div
                            className="card card-table mb-0"
                            style={{ width: "900px" }}
                          >
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table table-hover table-center mb-0">
                                  <thead>
                                    <tr>
                                      <th>Nom</th>
                                      <th>Relation</th>
                                      <th>Genre</th>
                                      <th>Telephone</th>
                                      <th>Date de Naiss.</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dependents.map((dependent) => {
                                      return (
                                        <tr key={dependent.id}>
                                          <td>
                                            <h2 className="table-avatar">
                                              {`${dependent.firstName} ${dependent.lastName}`}
                                            </h2>
                                          </td>
                                          <td>
                                            {dependent.dependentRelationship}
                                          </td>
                                          <td>{dependent.gender}</td>
                                          <td>{dependent.phoneNumber}</td>
                                          <td>
                                            {new Date(
                                              dependent.dayOfBirth
                                            ).toISOString()}
                                          </td>
                                          <td>
                                            <div className="table-action text-center">
                                              <a
                                                href="#edit_form"
                                                className="btn btn-sm bg-info-light"
                                                data-bs-toggle="modal"
                                                onClick={() =>
                                                  editDependent(dependent)
                                                }
                                              >
                                                {" "}
                                                <i className="fas fa-edit" />{" "}
                                                Edit
                                              </a>
                                              &nbsp;
                                              <a
                                                href="#"
                                                className="btn btn-sm bg-danger-light"
                                                onClick={() => {
                                                  setValidation(true);
                                                  setDependentToDelete(
                                                    dependent.id
                                                  );
                                                }}
                                              >
                                                <i className="fas fa-times" />{" "}
                                                Remove
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          {/* /Dependent Tab */}
                        </div>
                        <div
                          className="mt-5"
                          style={{ maxWidth: "355px", margin: "auto" }}
                        >
                          <Link
                            state={{
                              ...currentPatient,
                              profileCompleted: true,
                            }}
                            to={{
                              pathname: "/dashboard-patient",
                            }}
                            className="btn btn-primary w-100 btn-lg login-btn step3_submit"
                          >
                            continue{" "}
                          </Link>
                        </div>
                      </form>
                      <Modal
                        show={show || edit}
                        onHide={() => {
                          resetStates();
                        }}
                        className="modal"
                        style={{ marginTop: "80px" }}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <h5 className="modal-title">
                              {edit
                                ? "Modifier une personne a charge"
                                : "Ajouter une personne a charge"}
                            </h5>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="modal-body">
                            <div className="modal-body">
                              <div className="form-group">
                                {erreur && afficherErreur && (
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Erreur!</strong> Merci de remplir
                                    tous les champs du formulaire avant de
                                    valider
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="alert"
                                      aria-label="Close"
                                    ></button>
                                    <ul>
                                      {msgErreur.map((m) => {
                                        return <li key={m}>{m}</li>;
                                      })}
                                    </ul>
                                  </div>
                                )}
                                <label className="control-label mb-10">
                                  {" "}
                                  Prenom <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="prenom"
                                  className="form-control"
                                  value={prenom}
                                  onChange={(e) => {
                                    setPrenom(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="control-label mb-10">
                                  {" "}
                                  Nom <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="nom"
                                  className="form-control"
                                  value={nom}
                                  onChange={(e) => {
                                    setNom(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="control-label mb-10">
                                  Telephone{" "}
                                </label>
                                <input
                                  type="text"
                                  name="telephone"
                                  className="form-control"
                                  value={telephone}
                                  onChange={(e) => {
                                    setTelephone(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="control-label mb-10">
                                  Lien / Parente{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="relationship"
                                  className="form-control"
                                  value={lien}
                                  onChange={(e) => {
                                    setLien(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="control-label mb-10">
                                  Genre <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-control"
                                  name="gender"
                                  value={genre}
                                  onChange={(e) => {
                                    setGenre(e.target.value);
                                  }}
                                >
                                  <option>Select</option>
                                  <option defaultValue="Homme">Homme</option>
                                  <option defaultValue="Femme">Femme</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label className="control-label mb-10">
                                  Date de naissance{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dob"
                                  id="dob"
                                  defaultValue={dob}
                                  min="1900-01-01"
                                  onChange={(e) => {
                                    setDOB(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="control-label mb-10">
                                  Photo{" "}
                                </label>
                                <input
                                  type="file"
                                  name="profile_image"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="modal-footer text-center">
                              <button
                                className="btn btn-primary submit-btn"
                                onClick={() => {
                                  if (erreur) {
                                    setAfficherErreur(true);
                                    return;
                                  }

                                  // ouvrir le popup pour un nouvel ajout
                                  if (show) {
                                    dependents.push({
                                      id: uuid(),
                                      firstName: prenom,
                                      lastName: nom,
                                      phoneNumber: telephone,
                                      gender: genre,
                                      dayOfBirth: new Date(dob),
                                      dependentRelationship: lien,
                                      appointments: [],
                                      patientAddress:
                                        currentPatient?.patientAddress,
                                    });
                                  } else {
                                    // ouvrir le popup pour l'edition d'un dependent
                                    const dep: IPatient = dependents.find(
                                      (p) => p.id == modifiedDependent.id
                                    );
                                    dep.firstName = prenom;
                                    dep.lastName = nom;
                                    dep.dayOfBirth = new Date(dob);
                                    dep.gender = genre;
                                    dep.phoneNumber = telephone;
                                    dep.dependentRelationship = lien;
                                  }

                                  currentPatient.dependents = dependents;

                                  resetStates();
                                }}
                              >
                                Enregistrer
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      <Modal
                        show={validation}
                        className="modal"
                        style={{ marginTop: "80px" }}
                      >
                        <Modal.Header>
                          <Modal.Title>
                            <h5 className="modal-title">Avertissement</h5>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="modal-body">
                            Voulez vous supprimer la personne a charge ?
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-secondary submit-btn"
                            onClick={() => {
                              setValidation(false);
                            }}
                          >
                            Annuler
                          </button>
                          <button
                            className="btn btn-primary submit-btn"
                            onClick={() => {
                              setDependents(
                                dependents.filter(
                                  (d) => d.id != dependentToDelete
                                )
                              );

                              currentPatient.dependents = dependents;
                              setValidation(false);
                            }}
                          >
                            Enregistrer
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                  <div className="login-bottom-copyright">
                    <span>© 2024 Allo-Doc. Tous droits réservés.</span>
                  </div>
                </div>
              </div>
            </div>
            {/* /Register Content */}
          </div>
        </div>
        {/* /Page Content */}
      </>
    </>
  );
};

export default Patientregisterstepthree;
