import axios, { AxiosRequestConfig } from "axios";

class HttpClient {
  private _baseUrl: string;
  private _token: string;

  /**
   *Class that help querying the api
   */
  constructor(baseUrl: string, token: string) {
    this._baseUrl = baseUrl;
    this._token = token;
  }

  get = async <T>(endpoint: string, params?: any): Promise<T> => {
    let options = {
      method: "GET",
      url: `${this._baseUrl}${endpoint}`,
      params: params,
    } as AxiosRequestConfig;

    var result = await this.executeRequest<T>(options);

    return result;
  };

  post = async <InputType, OutputType>(
    endpoint: string,
    params?: any,
    data?: InputType
  ): Promise<OutputType> => {
    let options = {
      method: "POST",
      url: `${this._baseUrl}${endpoint}`,
      params: params,
      data: data,
    } as AxiosRequestConfig;

    var result = await this.executeRequest<OutputType>(options);

    return result;
  };

  postMedia = async <InputType, OutputType>(
    endpoint: string,
    params?: any,
    data?: InputType
  ): Promise<OutputType> => {
    try {
      var result = await axios.post(`${this._baseUrl}${endpoint}`, data, {
        params: params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return result.data as OutputType;
    } catch (error) {
      console.log("Error uploading file", error);
    }
  };

  put = async <InputType, OutputType>(
    endpoint: string,
    params?: any,
    data?: InputType
  ): Promise<OutputType> => {
    let options = {
      method: "PUT",
      url: `${this._baseUrl}${endpoint}`,
      params: params,
      data: data,
    } as AxiosRequestConfig;

    var result = await this.executeRequest<OutputType>(options);

    return result;
  };

  remove = async <InputType, OutputType>(
    endpoint: string,
    params?: any,
    data?: InputType
  ): Promise<OutputType> => {
    let options = {
      method: "DELETE",
      url: `${this._baseUrl}${endpoint}`,
      params: params,
      data: data,
    } as AxiosRequestConfig;

    var result = await this.executeRequest<OutputType>(options);

    return result;
  };

  private executeRequest = async <T>(
    options: AxiosRequestConfig
  ): Promise<T> => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this._token}`,
    };

    options.headers = headers;

    var result = await axios.request(options);

    return result.data as T;
  };
}

export default HttpClient;
