import { FixMeLater } from "../tsmigration";

export interface IAddress {
  id?: string | undefined;
  addressLine1?: string | undefined;
  addressLine2?: string | undefined;
  city?: string | undefined;
  country?: string | undefined;
  postalCode?: string | undefined;
  region?: string | undefined;
  state?: string | undefined;
}

// Doctor Model + Appointments
export interface IDoctor {
  id?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phoneNumber?: string | undefined;
  idNumber?: string | undefined;
  // email: string;
  logoPath?: string | undefined;
  gender?: string | undefined;
  isAuthorized?: boolean;
  profileCompleted?: boolean;
  appointmentDuration: number;
  availabilityRecurrenceEnd?: Date;
  doctorClinic: IClinic;
  consultationFee?: number | undefined;
  joinedOn?: Date;
  leaveOn?: Date;
  note?: number;
  doctorAddress?: IAddress;
  specialities?: ISpeciality[] | undefined;
  patients?: IPatient[] | undefined;
  authorizedRecords?: IDossierMedical[] | undefined;
  availabilities?: IDayAvailability[];
  // add appointment
  appointments?: IAppointment[];
}

export interface IMedicalMetrics {
  id?: string | undefined;
  height?: number;
  weight?: number;
  bloodPresureSup?: number;
  bloodPresureInf?: number;
  heartRate?: number;
}

export interface IMedicalRecord {
  id?: string | undefined;
  issuer?: IDoctor;
  recordNotes?: IRecordNote[] | undefined;
  // rendezVous?: IAppointment[] | undefined;
}

export interface IPatient {
  id?: string | undefined;
  firstName?: string | undefined;
  // email?: string | undefined;
  lastName?: string | undefined;
  phoneNumber?: string | undefined;
  logoPath?: string | undefined;
  gender?: string | undefined;
  patientAddress?: IAddress;
  nbNonHonoredRv?: number;
  accountBloqued?: boolean;
  profileCompleted?: boolean;
  dayOfBirth?: Date;
  dependentRelationship?: string;
  joinedOn?: Date;
  currentMetrics?: IMedicalMetrics;
  historicalMetrics?: IMedicalMetrics[] | undefined;
  dependents?: IPatient[] | undefined;
  medicalRecords?: IDossierMedical[] | undefined;
  doctors?: IDoctor[] | undefined;
  //show appointment
  appointments: IAppointment[] | undefined;
}

export interface IUserProfile {
  id?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phoneNumber?: string | undefined;
  address?: IAddress;
  logoPath?: string;
  email?: string;
}

export interface IRecordNote {
  id?: string | undefined;
  note: string;
  noteDate?: Date;
  issuer?: IDoctor;
}

export interface IAppointment {
  id: string | undefined;
  date: Date;
  isHonored?: boolean;
  patient: IPatient;
  doctor: IDoctor;
  dependentId?: string | undefined;
  doctorNote?: string | undefined;
  patientNote?: string | undefined;
  appointmentState?: string | undefined;
  notified?: boolean;
  missedAppointmentReason?: string | undefined;
  // medicalRecord?: IMedicalRecord;
  // medicalMetrics?: IMedicalMetrics;
}

export interface ISpeciality {
  id?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  doctors?: IDoctor[] | undefined;
}

export interface AppointmentTimeLine {
  past: IAppointment[];
  today: IAppointment[];
  upcoming: IAppointment[];
}

export interface IClinic {
  id?: string;
  name?: string;
  clinicAddress?: IAddress;
}

export enum ROLE {
  DOCTOR = "doctor",
  PATIENT = "patient",
  ADMIN = "docteur",
}

export interface IDossierMedical {
  id?: string;
  title?: string;
  files?: IMedicalRecordFile[];
  recordNotes?: IRecordNote[];
  patient?: IPatient;
  appointment?: IAppointment;
  issuer?: IDoctor;
  creationDate?: Date;
}

export interface IMedicalRecordFile {
  id: string;
  path: string;
  fileName: string;
  creationDate: Date;
}

export interface IDayAvailability {
  day: number;
  startTime: string;
  endTime: string;
}

export interface IContactUsModel {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export interface IMedicalRecordModel {
  id: string;
  doctorId: string;
  patientId: string;
  title: string;
  clinic: string;
  recordNote: string;
  rendezVousId: string;
  filePath: string;
  fileName: string;
  creationDate: Date;
  lastUpdate: Date;
}
export interface ICalendarEvent {
  apptDate: FixMeLater;
  startTime: string;
  endTime: string;
  title: string;
  id: string;
}

export interface ISearchFilter {
  homme?: boolean;
  femme?: boolean;
  availableToday?: boolean;
  availableTomorrow?: boolean;
  availableIn7Day?: boolean;
  availableIn30Day?: boolean;
  specialities?: string[];
  name?: string;
}

//#region API Bodies

export interface IPatientBody
  extends Pick<IPatient, "id" | "firstName" | "lastName"> {}

export interface IDoctorBody
  extends Pick<IDoctor, "id" | "firstName" | "lastName"> {}

export interface IRecordType {
  id?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
}

export interface IRelatedPatients {
  id: string;
  name: string;
  isDependent: boolean;
}

export enum APPOINTMENT_STATUS {
  UPCOMING = "A venir",
  CANCELLED = "Annulé",
  TO_UPDATE = "En attente",
  COMPLETED = "Complété",
  NOT_HONORED = "Non honoré",
}
