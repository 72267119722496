import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import Header from "../common/header";
import Footer from "../common/home/footer";
import DashboardSidebar from "./sidebar";
import { PatientContext } from "./PatientContext";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { IPatient } from "../models/backend";
import { PatientService } from "../services/patientService";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { useNotification } from "../common/NotificationContext";
import { FixMeLater } from "../tsmigration";
import countryList from "react-select-country-list";
import { ManagementService } from "../services/managementService";
import { PulseLoader } from "react-spinners";

const PatientProfileSettings = () => {
  const options = countryList().getData();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const { patient, getPatientData, setPatientData } =
    useContext(PatientContext);
  const [currentPatient, setCurrentPatient] = useState<IPatient>(
    {} as IPatient
  );
  const { showNotification } = useNotification();
  const [logoProfile, setLogo] = useState<string>();
  const [pictureSaving, setPictureSaving] = useState(false);

  const patientService = new PatientService();
  const managementService = new ManagementService();

  useEffect(() => {
    const fetchPatientData = async () => {
      setIsLoading(true);

      const id = auth.user?.profile["email"];
      const patientData = await getPatientData(id);
      setCurrentPatient(patientData);
      setIsLoading(false);
    };
    fetchPatientData();
  }, [getPatientData]);

  const saveProfileSettings = async () => {
    console.log("Save patient", currentPatient);
    try {
      const patientUpdated = await patientService.savePatientAsync(
        currentPatient
      );
      await setPatientData(currentPatient);
      if (patientUpdated) {
        showNotification(
          "Informations du patient mises a jour avec succes",
          NOTIFICATION_TYPE.INFO
        );
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "Erreur survenue. Merci de reessayer",
        NOTIFICATION_TYPE.ERROR
      );
    }
  };

  const handleChange = (e: FixMeLater) => {
    const { name, value } = e.target;
    setCurrentPatient({
      ...currentPatient,
      [name]: value,
    });
  };

  const handleChangeAdressField = (e: FixMeLater) => {
    const { name, value } = e.target;
    setCurrentPatient({
      ...currentPatient,
      patientAddress: {
        ...currentPatient.patientAddress,
        [name]: value,
      },
    });
  };

  return (
    <>
      <Header profileCompleted={patient?.profileCompleted} />
      {/* <!-- Breadcrumb --> */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Informations du profil</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Informations du profil
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Breadcrumb -->     */}
      {isLoading ? (
        "Loading..."
      ) : (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DashboardSidebar />
                </StickyBox>
              </div>{" "}
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <h4>Photo de profil</h4>
                    <div className="profile-pic-col">
                      <div className="profile-pic-upload">
                        <div className="cam-col">
                          <img
                            src={
                              logoProfile
                                ? logoProfile
                                : currentPatient.logoPath
                            }
                            id="prof-avatar"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        {pictureSaving ? (
                          <PulseLoader color="#0e82fd" />
                        ) : (
                          <span>
                            Cliquez pour mettre a jour la photo de profil
                          </span>
                        )}

                        <input
                          type="file"
                          id="profile_image"
                          name="profile_image"
                          onChange={(e) => {
                            let file = e.target.files[0] || null;
                            let formFile = new FormData();
                            formFile.append(file?.name, file);
                            setPictureSaving(true);
                            managementService
                              .saveProfileLogoAsync(
                                auth?.user?.profile["email"],
                                auth?.user?.profile["role"] as string,
                                formFile
                              )
                              .then((result) => {
                                // update the logo on the page
                                setLogo(result);
                              })
                              .finally(() => {
                                setPictureSaving(false);
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Informations personnelles</h4>
                    <div className="row form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={currentPatient.id}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Prénom <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={currentPatient.firstName}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Nom <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={currentPatient.lastName}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Téléphone</label>
                          <input
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            value={currentPatient.phoneNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Adresse</h4>
                    <div className="row form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Adresse domicile (Ligne 1)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="addressLine1"
                            value={currentPatient.patientAddress?.addressLine1}
                            onChange={handleChangeAdressField}
                          />
                        </div>
                        <div className="form-group">
                          <label>Adresse domicile (Ligne 2)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="addressLine2"
                            value={currentPatient.patientAddress?.addressLine2}
                            onChange={handleChangeAdressField}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Ville</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            value={currentPatient.patientAddress?.city}
                            onChange={handleChangeAdressField}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Pays <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select form-control"
                            id="pays"
                            name="country"
                            tabIndex={-1}
                            aria-hidden="true"
                            value={currentPatient.patientAddress?.country}
                            onChange={(e) => {
                              handleChangeAdressField(e.target.value);
                            }}
                          >
                            {options.map((opt) => {
                              return (
                                <option value={opt?.value} key={opt?.value}>
                                  {opt?.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-section submit-btn-bottom">
                  <button
                    type="submit"
                    className="btn btn-info submit-btn"
                    onClick={async () => await saveProfileSettings()}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default PatientProfileSettings;
