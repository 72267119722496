const YearDifference = (props: { initialDate: Date }) => {
  const currentDate = new Date();
  const birthDay = new Date(props.initialDate);
  const yearDiff = currentDate.valueOf() - birthDay.valueOf();

  const differenceYears = yearDiff / (1000 * 3600 * 24 * 365.25);
  return (
    <>
      <span> {Math.floor(differenceYears)}</span> ans
    </>
  );
};

export default YearDifference;
