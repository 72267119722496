import { IContactUsModel, IRecordType, ISpeciality } from "../models/backend";
import { BACKEND_URL } from "../utils/constants";
import HttpClient from "../utils/httpClient";
import { TokenHelpers } from "../utils/tokenHelpers";

interface IManagementRepository {
  getAllSpecialitiesAsync(): Promise<ISpeciality[]>;
  saveSpecialityAsync(speciality: ISpeciality): Promise<boolean>;
  deleteSpecialityAsync(specialityId: string): Promise<boolean>;
  sendContactMessageAsync(model: IContactUsModel): Promise<boolean>;
  saveProfileLogoAsync(
    userId: string,
    role: string,
    file: FormData
  ): Promise<string>;
  getAllRecordTypesAsync(): Promise<IRecordType[]>;
  saveRecordTypeAsync(speciality: IRecordType): Promise<boolean>;
  deleteRecordTypeAsync(specialityId: string): Promise<boolean>;
}

class ManagementRepository implements IManagementRepository {
  private _httpClient: HttpClient;
  private _tokenHelpers: TokenHelpers;

  /**
   *Repository that will handle the request for the management items
   */
  constructor() {
    this._tokenHelpers = new TokenHelpers();
    this._httpClient = new HttpClient(
      BACKEND_URL,
      this._tokenHelpers.getToken()
    );
  }

  async getAllRecordTypesAsync(): Promise<IRecordType[]> {
    return await this._httpClient.get<IRecordType[]>("/api/management/getall");
  }

  async saveRecordTypeAsync(recordType: IRecordType): Promise<boolean> {
    return await this._httpClient.post<IRecordType, boolean>(
      "/api/management/add",
      {},
      recordType
    );
  }

  async deleteRecordTypeAsync(recordTypeId: string): Promise<boolean> {
    return await this._httpClient.remove<unknown, boolean>("/api/management", {
      id: recordTypeId,
    });
  }

  async sendContactMessageAsync(model: IContactUsModel): Promise<boolean> {
    return await this._httpClient.post<IContactUsModel, boolean>(
      "/api/management/sendMessage",
      {},
      model
    );
  }

  async saveProfileLogoAsync(userId: string, role: string, file: FormData) {
    return await this._httpClient.postMedia<FormData, string>(
      "/api/profile/uploadLogo",
      {
        id: userId,
        role: role,
      },
      file
    );
  }

  async deleteSpecialityAsync(specialityId: string): Promise<boolean> {
    return await this._httpClient.remove<unknown, boolean>("/api/speciality", {
      id: specialityId,
    });
  }

  async getAllSpecialitiesAsync(): Promise<any> {
    return await this._httpClient.get<ISpeciality[]>("/api/speciality/getall");
  }
  async saveSpecialityAsync(speciality: ISpeciality): Promise<boolean> {
    return await this._httpClient.post<ISpeciality, boolean>(
      "/api/speciality/add",
      {},
      speciality
    );
  }
}

export { ManagementRepository, IManagementRepository };
