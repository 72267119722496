import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";

const SidebarNav = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [isSideMenu, setSideMenu] = useState("");
  const [isSideMenuNew, setSideMenuNew] = useState("");
  const [isSideMenuNew2, setSideMenuNew2] = useState("");

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };

  const toggleSidebarNew = (value) => {
    setSideMenuNew(value);
  };

  const toggleSidebarNew2 = (value) => {
    setSideMenuNew2(value);
  };

  // eslint-disable-next-line no-unused-vars
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };

  return (
    <>
      {/* <!-- Sidebar --> */}
      <div
        className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
        id="sidebar"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li className="menu-title">
                  <span></span>
                </li>
                <li className={pathname === "/admin" ? "active" : ""}>
                  <Link to="/admin">
                    <i className="fe fe-home"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname?.includes("/admin-specialities") ? "active" : ""
                  }
                >
                  <Link to="/admin-specialities">
                    <i className="fe fe-document"></i> <span>Specialities</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname?.includes("/admin-doctors") ? "active" : ""
                  }
                >
                  <Link to="/admin-doctors">
                    <i className="fe fe-user-plus"></i>
                    <span>Doctors</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname?.includes("/admin-patients") ? "active" : ""
                  }
                >
                  <Link to="/admin-patients">
                    <i className="fe fe-user"></i> <span>Patients</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname?.includes("/admin-medical") ? "active" : ""
                  }
                >
                  <Link to="/admin-medical">
                    <i className="fe fe-layout"></i>{" "}
                    <span>Dossier medical</span>
                  </Link>
                </li>
                <li
                  className={
                    pathname?.includes("/admin-finances") ? "active" : ""
                  }
                >
                  <Link to="/admin-finances">
                    <i className="fe fe-activity"></i> <span>Finances</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* <!-- /Sidebar --> */}
    </>
  );
};

export default SidebarNav;
