import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { APPOINTMENT_STATUS, IAppointment, IPatient } from "../models/backend";
import {
  displayDateInFrenchFormat,
  isAppointmentTimeInThePast,
  isObjectEmptyOrUndefined,
} from "../utils/objUtils";
import { FixMeLater } from "../tsmigration";
import DisplayAppointmentStatusBadge from "../common/shared-components/DisplayAppointmentStatusBadge";
import { AppointmentService } from "../services/appointmentService";
import { useNotification } from "../common/NotificationContext";
import { NOTIFICATION_TYPE } from "../models/notifications";
import { DoctorContext } from "./DoctorContext";
import { useNavigate } from "react-router-dom";

export const DoctorAppointmentDetails = ({
  show,
  handleClose,
  appointmentDetails,
}: {
  show: boolean;
  handleClose: () => void;
  appointmentDetails: IAppointment;
}) => {
  const [showReason, setShowReason] = useState(!appointmentDetails.isHonored);
  const appointmentService = new AppointmentService();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { doctor, getDoctorData, setDoctorData } = useContext(DoctorContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const goToEditMode = () => {
    setIsEditMode(true);
  };
  const leaveEditMode = () => {
    setIsEditMode(false);
  };

  const [appointmentData, setAppointmentData] = useState<IAppointment>({
    ...appointmentDetails,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.type === "checkbox") {
      setAppointmentData({
        ...appointmentData,
        [name]: e.target.checked,
      });
    } else {
      setAppointmentData({
        ...appointmentData,
        [name]: value,
      });
    }
  };

  const handleToggle = (value) => {
    setAppointmentData({
      ...appointmentData,
      isHonored: value,
    });
    setShowReason(!value);
  };

  const handleEditAppointmentDialogSubmit = async (event: FixMeLater) => {
    event.preventDefault();
    try {
      if (appointmentData.isHonored) {
        appointmentData.appointmentState = APPOINTMENT_STATUS.COMPLETED;
      } else if (
        isAppointmentTimeInThePast(appointmentData.date) &&
        appointmentData.missedAppointmentReason
      ) {
        appointmentData.appointmentState = APPOINTMENT_STATUS.NOT_HONORED;
      }
      const appointmentUpdated = await appointmentService.editAppointment(
        appointmentData
      );
      const newAppointmentList = [];

      const updatedDoctor = {
        ...appointmentDetails.doctor,
        appointments: [...newAppointmentList],
      };
      localStorage.removeItem("doctorData");
      await setDoctorData(updatedDoctor);
      if (appointmentUpdated) {
        showNotification(
          "Rendez-vous mis a jour avec succès",
          NOTIFICATION_TYPE.INFO
        );
        leaveEditMode();
        handleClose();
        navigate("/dashboard-docteur");
      }
    } catch (error) {
      console.error(error);
      showNotification(
        "Erreur survenue pendant la mise à jour du Rendez-vous",
        NOTIFICATION_TYPE.ERROR
      );
    }
  };

  useEffect(() => {
    setAppointmentData({ ...appointmentDetails });
  }, [appointmentDetails]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          leaveEditMode();
        }}
        backdrop="static"
        className="modal"
        style={{ marginTop: "80px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">
              {" "}
              {isEditMode ? "Modifier Rendez vous" : "Détails du Rendez vous"}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {/* <form onSubmit={handleSubmit}> */}
            <form>
              <div className="modal-body">
                {isEditMode ? (
                  <>
                    {!appointmentDetails.isHonored && (
                      <>
                        {isAppointmentTimeInThePast(appointmentDetails.date) ? (
                          <>
                            <ul className="info-details">
                              <li>
                                <div className="details-header">
                                  <div className="row">
                                    {appointmentDetails.date && (
                                      <div className="col-md-8">
                                        <span className="title">
                                          {
                                            appointmentDetails?.patient
                                              ?.firstName
                                          }
                                          {
                                            appointmentDetails?.patient
                                              ?.lastName
                                          }
                                        </span>
                                        <span className="text">
                                          {displayDateInFrenchFormat(
                                            new Date(appointmentDetails.date)
                                          )}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label
                                    htmlFor="title"
                                    className=" title col-sm-12 col-form-label"
                                  >
                                    Notes et Observations:
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="doctorNote"
                                    value={appointmentData.doctorNote}
                                    onChange={handleChange}
                                    required
                                    placeholder="Notes et observations"
                                    rows={5}
                                    style={{ overflowY: "hidden" }}
                                    onInput={(e: FixMeLater) => {
                                      e.target.style.height = "auto";
                                      e.target.style.height = `${e.target.scrollHeight}px`;
                                    }}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="form-group">
                                  <label
                                    htmlFor="title"
                                    className=" title col-sm-12 col-form-label"
                                  >
                                    Rendez vous effectué?:
                                  </label>
                                  <ButtonGroup>
                                    <Button
                                      variant={
                                        appointmentData.isHonored
                                          ? "info"
                                          : "outline-info"
                                      }
                                      onClick={() => handleToggle(true)}
                                    >
                                      Oui
                                    </Button>
                                    <Button
                                      variant={
                                        !appointmentData.isHonored
                                          ? "info"
                                          : "outline-info"
                                      }
                                      onClick={() => handleToggle(false)}
                                    >
                                      Non
                                    </Button>
                                  </ButtonGroup>
                                  {!appointmentData.isHonored && (
                                    <div className="form-group">
                                      <label
                                        htmlFor="title"
                                        className=" title col-sm-12 col-form-label"
                                      >
                                        Raisons pour lesquelles le RV n'a pas eu
                                        lieu:
                                      </label>
                                      <textarea
                                        className="form-control"
                                        name="missedAppointmentReason"
                                        value={
                                          appointmentData.missedAppointmentReason
                                        }
                                        onChange={handleChange}
                                        required
                                        placeholder="Explications:"
                                        rows={5}
                                        style={{ overflowY: "hidden" }}
                                        onInput={(e: FixMeLater) => {
                                          e.target.style.height = "auto";
                                          e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <div className="form-group">
                            <label
                              htmlFor="title"
                              className=" title col-sm-12 col-form-label"
                            >
                              Notes et observations:
                            </label>
                            <textarea
                              className="form-control"
                              name="doctorNote"
                              value={appointmentData.doctorNote}
                              onChange={handleChange}
                              required
                              placeholder="Notes et observations"
                              rows={5}
                              style={{ overflowY: "hidden" }}
                              onInput={(e: FixMeLater) => {
                                e.target.style.height = "auto";
                                e.target.style.height = `${e.target.scrollHeight}px`;
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <ul className="info-details">
                      <li>
                        <div className="details-header">
                          <div className="row">
                            {/* <div className="col-md-6">
                          <span className="title">#APT0001</span>
                          <span className="text">21 Oct 2019 10:00 AM</span>
                        </div> */}
                            {appointmentDetails.date && (
                              <div className="col-md-9">
                                <span className="title">Date</span>
                                <span className="text">
                                  {displayDateInFrenchFormat(
                                    new Date(appointmentDetails.date)
                                  )}
                                </span>
                              </div>
                            )}
                            <div className="col-md-3">
                              <div className="text-end">
                                <DisplayAppointmentStatusBadge
                                  appointment={appointmentDetails}
                                ></DisplayAppointmentStatusBadge>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <span className="title">Patient:</span>
                        <span className="text">
                          {appointmentDetails?.patient?.firstName}{" "}
                          {appointmentDetails?.patient?.lastName}
                        </span>
                      </li>
                      <li>
                        <span className="title">Notes Docteur</span>
                        <span className="text">
                          {appointmentDetails.doctorNote || "N/A"}
                        </span>
                      </li>
                      <li>
                        <span className="title">Notes Patient</span>
                        <span className="text">
                          {appointmentDetails.patientNote || "N/A"}
                        </span>
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div className="modal-footer text-center">
                {isEditMode ? (
                  <>
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleEditAppointmentDialogSubmit}
                    >
                      Enregistrer
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={leaveEditMode}
                    >
                      Annuler
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={goToEditMode}
                      hidden={
                        appointmentDetails.isHonored ||
                        appointmentDetails.appointmentState ===
                          APPOINTMENT_STATUS.NOT_HONORED
                      }
                    >
                      Modifier
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleClose}
                    >
                      Fermer
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
