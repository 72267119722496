import { useEffect, useState } from "react";
import { Input } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import SidebarNav from "./sidebar";
import { Link } from "react-router-dom";
import Header from "./header";
import { DoctorService } from "../../services/doctorService";
import { IDoctor, IUserProfile } from "../../models/backend";
import { DateTime } from "luxon";
import { Modal } from "react-bootstrap";
import Profile from "./profile";
import { SyncLoader } from "react-spinners";
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [profile, setProfile] = useState<IUserProfile>();
  const doctorService = new DoctorService();
  const [searchDoctors, setSearchDoctors] = useState<IDoctor[]>([]);
  const [doctorIsLoading, setDoctorIsLoading] = useState<string[]>([]);

  const { Search } = Input;

  useEffect(() => {
    doctorService.getAllDoctorAsync().then((docs) => {
      setDoctors(docs ? docs : []);
      setSearchDoctors(docs ? docs : []);
    });
  }, []);

  return (
    <>
      <Header onMenuClick={() => {}} />
      <SidebarNav />
      <Modal
        className="modal"
        show={showEdit}
        style={{ marginTop: "80px" }}
        size="lg"
        onHide={() => {
          setShowEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title">Profil docteur</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Profile profile={profile} />
        </Modal.Body>
      </Modal>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Liste des docteurs</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Liste des docteurs</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-4">
              <Search
                placeholder="Search doctor by name"
                allowClear
                enterButton="Search"
                size="large"
                style={{ marginBottom: "25px" }}
                onSearch={(value) => {
                  if (value) {
                    setSearchDoctors(
                      doctors.filter(
                        (d: IDoctor) =>
                          d.firstName
                            .toLowerCase()
                            .includes(value.toLowerCase()) ||
                          d.lastName.toLowerCase().includes(value.toLowerCase())
                      )
                    );
                  } else {
                    setSearchDoctors(doctors);
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nom docteur</TableCell>
                            <TableCell align="right">Specialites</TableCell>
                            <TableCell align="right">Membre depuis</TableCell>
                            <TableCell align="right">
                              Profile complete
                            </TableCell>
                            <TableCell align="right">Est autorise</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {searchDoctors.map((doc) => {
                            return (
                              <TableRow key={doc.id}>
                                <TableCell component="th" scope="row">
                                  <a
                                    onClick={() => {
                                      setProfile({
                                        id: doc.id,
                                        firstName: doc.firstName,
                                        lastName: doc.lastName,
                                        phoneNumber: doc.phoneNumber,
                                        address: doc.doctorAddress,
                                        logoPath: doc.logoPath,
                                      });
                                      setShowEdit(true);
                                    }}
                                  >
                                    {`${doc.firstName} ${doc.lastName}`}
                                  </a>
                                </TableCell>
                                <TableCell align="right">
                                  {doc?.specialities
                                    ?.map((s) => s.title)
                                    .join(",")}
                                </TableCell>
                                <TableCell align="right">
                                  <span className="user-name">
                                    {DateTime.fromJSDate(
                                      new Date(doc.joinedOn)
                                    ).toLocaleString()}
                                  </span>
                                  <br />
                                  <span>
                                    {DateTime.fromJSDate(
                                      new Date(doc.joinedOn)
                                    ).toLocaleString(DateTime.TIME_SIMPLE)}
                                  </span>
                                </TableCell>
                                <TableCell align="right">
                                  <label>
                                    {doc?.profileCompleted ? "Oui" : "Non"}
                                  </label>
                                </TableCell>
                                <TableCell align="right">
                                  {doctorIsLoading?.includes(doc.id) ? (
                                    <SyncLoader color="#1b5a90" size={9} />
                                  ) : (
                                    <Switch
                                      checked={doc.isAuthorized}
                                      color="primary"
                                      onClick={() => {
                                        doctorIsLoading.push(doc.id);
                                        setDoctorIsLoading(doctorIsLoading);
                                        doctorService
                                          .lockDoctorAccountAsync(
                                            doc.id,
                                            !doc.isAuthorized
                                          )
                                          .then(() => {
                                            searchDoctors.find(
                                              (d) => d.id == doc.id
                                            ).isAuthorized = !doc.isAuthorized;

                                            setSearchDoctors(searchDoctors);

                                            // reload all the doctor
                                            setDoctorIsLoading(
                                              doctorIsLoading.filter((id) => {
                                                id !== doc.id;
                                              })
                                            );
                                          });
                                      }}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Doctors;
