import { APPOINTMENT_STATUS, IAppointment } from "../../models/backend";
import { isAppointmentTimeInThePast } from "../../utils/objUtils";

const DisplayAppointmentStatusBadge = (props: {
  appointment: IAppointment;
}) => {
  return (
    <>
      {props.appointment?.isHonored ? (
        <button
          type="button"
          className="btn bg-success-light btn-sm"
          id="topup_status"
        >
          Complété
        </button>
      ) : (
        <>
          {isAppointmentTimeInThePast(props.appointment.date) ? (
            <>
              {props.appointment.appointmentState ===
              APPOINTMENT_STATUS.NOT_HONORED ? (
                <button
                  type="button"
                  className="btn bg-danger-light btn-sm"
                  id="topup_status"
                >
                  {APPOINTMENT_STATUS.NOT_HONORED}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn bg-info-light btn-sm"
                  id="topup_status"
                >
                  {APPOINTMENT_STATUS.TO_UPDATE}
                </button>
              )}
            </>
          ) : (
            <button
              type="button"
              className="btn bg-warning-light btn-sm"
              id="topup_status"
            >
              {props.appointment.appointmentState ||
                APPOINTMENT_STATUS.UPCOMING}
            </button>
          )}
        </>
      )}
    </>
  );
};

export default DisplayAppointmentStatusBadge;
