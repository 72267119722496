import {
  AppointmentTimeLine,
  IAddress,
  IAppointment,
  IDoctor,
  IPatient,
} from "../models/backend";
import { FixMeLater } from "../tsmigration";

export function isObjectEmptyOrUndefined(obj): boolean {
  return obj === null || obj === undefined || Object.keys(obj)?.length === 0;
}

export const getAppontmentTimeLine = (
  appointmentList: IAppointment[]
): AppointmentTimeLine => {
  const begin_current_date: Date = new Date();
  const end_current_date: Date = new Date();
  begin_current_date.setHours(0, 0, 0, 0);
  end_current_date.setHours(23, 59, 59, 59);

  if (appointmentList && appointmentList.length > 0) {
    const appointments_before_today = appointmentList.filter(
      (appointment) => appointment.date < begin_current_date
    );
    const appointments_today = appointmentList.filter(
      (appointment) =>
        appointment.date.toDateString() === begin_current_date.toDateString()
    );

    const appointments_after_today = appointmentList.filter(
      (appointment) => appointment.date > end_current_date
    );

    const timeline: AppointmentTimeLine = {
      upcoming: appointments_after_today,
      today: appointments_today,
      past: appointments_before_today,
    };
    return timeline;
  }
};

export const getDoctorPatients = (
  appointmentList: IAppointment[]
): IPatient[] => {
  let patientList: IPatient[] = [];
  const patientsArray = appointmentList.map(
    (appointment) => appointment.patient
  );

  patientList = Array.from(
    new Map(patientsArray.map((patient) => [patient.id, patient])).values()
  );

  return patientList;
};

// erreut de dates dans local storage (fix Temporaire)
export const convertAppointmentDates = (doctorData: IDoctor) => {
  if (doctorData && doctorData.appointments) {
    return {
      ...doctorData,
      appointments: doctorData.appointments.map((appointment) => ({
        ...appointment,
        date: appointment.date ? new Date(appointment.date) : undefined,
      })),
    };
  }
  return doctorData;
};

const capitalizeFirstLetterOfEachPart = (formattedDate: string): string => {
  return formattedDate.replace(/\b(\w)(\S*)/g, (match, firstLetter, rest) => {
    return firstLetter.toUpperCase() + rest;
  });
};

export const displayDateInFrenchFormat = (date: Date) => {
  const formattedDate = date.toLocaleString("fr-FR", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
  });

  return capitalizeFirstLetterOfEachPart(formattedDate);
};

export const displayDay = (date: Date) => {
  return new Date(date).toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

function convertStringToArray(input) {
  if (typeof input === "string") {
    try {
      const result = JSON.parse(input);
      if (Array.isArray(result)) {
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error parsing string:", error);
      return null;
    }
  } else {
    return input;
  }
}

export const displayDoctorSpecialities = (doctor: IDoctor): string => {
  let specialities = doctor?.specialities as FixMeLater;

  if (!specialities || specialities.length === 0) {
    return "";
  } else {
    // TODO: Fix rapide. SPecialites affiche parfois un array dans un string
    if (typeof specialities === "string") {
      specialities = convertStringToArray(specialities);
    }

    // const displayedSpecialities = specialities.map((s) => s.title).join("-");
    const displayedSpecialities = specialities
      .map((s) => s.title || s.Title || "") // Handle both `title` and `Title` keys
      .filter((title) => title)
      .join(" - ");
    return displayedSpecialities;
  }
};

export const checkIfDoctorHasSpeciality = (
  doctor: IDoctor,
  specialityName: string
): boolean => {
  let specialities = doctor?.specialities as FixMeLater[];

  if (!specialities || specialities.length === 0) {
    return false;
  } else {
    // TODO: Fix rapide. SPecialites affiche parfois un array dans un string
    if (typeof specialities === "string") {
      specialities = convertStringToArray(specialities);
    }

    const allSpecialitiesTitle: string[] = specialities.map(
      (s) => s.title || s.Title || ""
    );

    const matchedTitles: boolean = allSpecialitiesTitle.some((speciality) =>
      speciality?.includes(specialityName)
    );

    return matchedTitles;
  }
};

export const getCurrentUTCTime = () => {
  const now = new Date();
  return new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )
  );
};

export function isAppointmentInMoreThan48hours(appointmentDate: Date): boolean {
  const currentTime = new Date();
  // Convert both dates to UTC for security
  const utcNow = Date.UTC(
    currentTime.getUTCFullYear(),
    currentTime.getUTCMonth(),
    currentTime.getUTCDate(),
    currentTime.getUTCHours(),
    currentTime.getUTCMinutes(),
    currentTime.getUTCSeconds()
  );
  const utcAppointment = Date.UTC(
    appointmentDate.getUTCFullYear(),
    appointmentDate.getUTCMonth(),
    appointmentDate.getUTCDate(),
    appointmentDate.getUTCHours(),
    appointmentDate.getUTCMinutes(),
    appointmentDate.getUTCSeconds()
  );

  // Calculate the difference in milliseconds
  const diffMs = Math.abs(utcAppointment - utcNow);

  // Convert milliseconds to hours
  const diffHours = diffMs / (1000 * 60 * 60);

  // Check if the difference is greater than 48 hours
  return diffHours > 48;
}

export const getAppointmentUtcTime = (aptTime: Date) => {
  const appointmentDate = new Date(aptTime);
  const utcAppointment = Date.UTC(
    appointmentDate.getUTCFullYear(),
    appointmentDate.getUTCMonth(),
    appointmentDate.getUTCDate(),
    appointmentDate.getUTCHours(),
    appointmentDate.getUTCMinutes(),
    appointmentDate.getUTCSeconds()
  );
  return utcAppointment;
};

export function isAppointmentTimeInThePast(appointmentDate: Date): boolean {
  const currentTime = new Date();
  const aptDate = new Date(appointmentDate);
  return aptDate < currentTime;
}

export function getAllPatientsInTheAccount(mainPatient: IPatient): IPatient[] {
  const allPatients: IPatient[] = [mainPatient];
  if (mainPatient.dependents && mainPatient.dependents.length > 0) {
    allPatients.push(...mainPatient.dependents);
  }
  return allPatients;
}

export function checkIfPatientIsDependant(patient: IPatient): boolean {
  // TODO Logic should be improved later
  // A dependant has a relationship
  if (patient.dependentRelationship != null) {
    return true;
  }
  // A dependant has no dependants
  if (patient.dependents && patient.dependents.length > 0) {
    return false;
  }
  return false;
}

export function formatAddress(address: IAddress): string {
  let addString = address?.addressLine1;
  addString += address?.addressLine2 ? ` ${address?.addressLine2}` : "";
  addString += ` ${address?.city}`;
  addString += ` ${address?.country}`;

  return addString;
}
